<template lang="pug">
  div
    sprite.opa-sprite
    nuxt
</template>
<script>
import Sprite from '@/static/images/icons/sprite.svg';

export default {
  components: {
    Sprite,
  },
};
</script>
