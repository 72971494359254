export default (store, handler) => ({
  initialize({ activationKey, activationKeys }) {
    if (activationKey) {
      checkKey(activationKey);
      store.setProp('activationKeys', [activationKey]);
    } //
    else if (activationKeys) {
      checkKeys(activationKeys);
      store.setProp('activationKeys', activationKeys);
    }

    handler.initialize();

    function checkKey(key) {
      if (typeof key !== 'string') {
        throw new Error(
          'keycuts module: Received an invalid activationKey, not a string!'
        );
      }
    }
    function checkKeys(keys) {
      if (!Array.isArray(keys)) {
        throw new Error(
          'keycuts module: Received an invalid array os activationKeyz, not an array!'
        );
      }

      keys.forEach(checkKey);
    }
  },
  add(trigger, fun) {
    checkTrigger(trigger);
    checkFun(fun);
    store.addShortcut(trigger, fun);

    function checkFun(fun) {
      if (typeof fun !== 'function') {
        throw new Error(
          'keycuts module: Received an invalid bind function, not a function!'
        );
      }
    }
    function checkTrigger(trigger) {
      if (typeof trigger !== 'string') {
        throw new Error(
          'keycuts module: Received an invalid trigger, not a string!'
        );
      }
    }
  },
  remove(trigger) {
    store.removeShortcut(trigger);
  },
});
