<template lang="pug">
  .document-tab
    TabHeader(title="Documentos")
    .document-tab__body
      DocumentList(:admission="admission")
</template>

<style lang="sass" scoped>
.document-tab__body
  padding: $space-m
</style>

<script>
import TabHeader from '@/components/common/TabHeader';
import DocumentList from '@/opa-modules/admissions/components/DocumentList';

export default {
  components: {
    DocumentList,
    TabHeader,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    admission() {
      return this.params.admission;
    },
  },
};
</script>
