import { trim } from 'lodash';

export default function(form, getData, { onInput, onChange }) {
  form.addEventListener('input', eventHandler);
  form.addEventListener('blur', eventHandler, true);
  form.addEventListener('keydown', eventHandler, true);

  function eventHandler({ type, target, key }) {
    const isInputEl = target.nodeName === 'INPUT';
    if (!isInputEl) return;

    const id = target.id;
    const index = target.dataset.index;

    const handler = getHandler(type, id, { onChange, onInput });
    if (handler === undefined) return;

    let fieldData = getField(getData(), id, index);

    if (type === 'input') {
      requestAnimationFrame(() => {
        if (hasAlreadyChecked(fieldData)) return;
        onInput[id](fieldData);
      });
    }

    if ((type === 'keydown' && key === 'Enter') || type === 'blur') {
      requestAnimationFrame(() => {
        onChange[id](fieldData);
      });
    }
  }
}

function hasAlreadyChecked(fieldData) {
  const answer = fieldData.lastCheckedValue === fieldData.value;
  fieldData.lastCheckedValue = fieldData.value;
  return answer;
}

function getHandler(type, id, { onChange, onInput }) {
  const handlerObj = type === 'input' ? onInput : onChange;
  const handler = handlerObj[id];

  return typeof handler !== 'function' ? undefined : handler;
}

export const getField = function(object, id, index) {
  let fieldData = object[id];

  const isArray = Array.isArray(fieldData);

  fieldData = isArray && index ? fieldData[index] : fieldData;

  return fieldData;
};

export function isOk(arg, { required = true } = {}) {
  if (Array.isArray(arg)) {
    const hasNotOkUnrequiredFields =
      arg.filter(x => !isOk(x, { required: false })).length > 0;
    const hasOkFields = arg.filter(x => isOk(x, { required })).length > 0;
    return !hasNotOkUnrequiredFields && hasOkFields;
  } else {
    const hasValue = trim(arg.value) !== '';
    const isOk = arg.isOk !== false;
    if (required) {
      return hasValue && isOk;
    } else {
      return !hasValue || isOk;
    }
  }
}

export function areFieldsOk(formData, fields) {
  const checkedFields = fields.map(arg => {
    if (typeof arg == 'string') {
      return isOk(formData[arg]);
    } else {
      return isOk(formData[arg.key], { required: arg.required });
    }
  });

  const allFieldsOk = !checkedFields.includes(false);
  return allFieldsOk;
}

export function cleanData(data) {
  const dataKeys = Object.keys(data);

  const dataCleaned = dataKeys.reduce((acc, key) => {
    const field = getField(data, key);
    const isArray = Array.isArray(field);
    if (isArray) {
      acc[key] = field.filter(isOk).map(({ value }) => value);
    } else {
      acc[key] = isOk(field) ? field.value : null;
    }
    return acc;
  }, {});

  return dataCleaned;
}

export function isValidPhone(phone) {
  const re = /^\(([14689][1-9]|[23][12478]|[357][1345]|77|79)\) (9\d{4}|[2-9]\d{3})-?\d{4}$/;
  return re.test(phone);
}

export function phoneMaskOptions() {
  const options = {
    blocks: [0, 2, 0, 5, 4],
    delimiters: ['(', ')', ' ', '-'],
    numericOnly: true,
  };

  return options;
}
