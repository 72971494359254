<template lang="pug">
  label.checkbox
    input.checkbox__input(
      :data-label="label"
      type="checkbox"
      :checked="value"
      @change="change($event)"
      @click="$emit('click')"
    )
    CheckMark(:value="value")
    slot(:label="label") {{ label }}
</template>

<script>
import CheckMark from '@/components/common/CheckMark';

export default {
  components: {
    CheckMark,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    change(event) {
      if (!this.enabled) return;

      this.$emit('input', event.target.checked);
    },
  },
};
</script>

<style lang="sass">
.checkbox
  line-height: normal
  margin-left: $space-xxs
  margin-bottom: $space-m
  cursor: pointer
  user-select: none
  display: flex
  align-items: center
  color: $silver-gray

.checkbox__input
  position: absolute
  opacity: 0
  width: 0
  height: 0

.checkbox:focus-within
  outline: solid $blue-qb3 1px
</style>
