<template lang="pug">
  .refund-request
    TabHeader(:title="offerNames.course || '.'" :subTitle="offerNames.university || '.'")

    .refund-request__scroller
      template(v-if="isLoading")
        content-loader.refund-request__body(
          :width="400"
          :height="160"
          :speed="2"
          primary-color="#49555B"
          secondary-color="#272D30"
        )
          rect(x="4" y="3" rx="0" ry="0" width="343" height="22")
          rect(x="4" y="43" rx="0" ry="0" width="343" height="15")
          rect(x="4" y="66" rx="0" ry="0" width="172" height="15")

      template(v-else)
        template(v-if="refundRequestOutOfTime && !hasAuthorization")
          form.refund-request__body(@submit.prevent="submitAuthorization")
            .opa__card-body
              .refund-request__title Essa ação necessita da autorização do time de reembolso.
              .refund-request__text Essa bolsa se encontra fora do prazo de 7 dias para abertura de reembolso, a partir da data da compra. Essa ação foi liberada pelo time responsável?

              .refund-request__radio
                Radio.refund-request__radio-option(
                  v-model="refundAuthorizationOption"
                  label="Sim"
                  name="refund_authorization"
                  choice="yes"
                )

                Radio.refund-request__radio-option(
                  v-model="refundAuthorizationOption"
                  label="Não"
                  name="refund_authorization"
                  choice="no"
                )

              Alert.refund-request__manager-alert(
                  v-if="refundAuthorizationOption === 'no'"
                  :dismissable="false"
                  type="error"
                  message="A aprovação do time de reembolso é necessária"
                )

              Button.refund-request__button(
                    type="positive"
                    :disabled="refundAuthorizationOption !== 'yes'"
                    :text="'Prosseguir'"
                    :submit="true"
              )

        template(v-else)
          form.refund-request__body
            Card.opa__card
              template(slot="header")
                .opa__card-header Solicitar voucher ou reembolso

              .opa__card-body
                .refund-request__label Motivo *
                Select.refund-request__select(
                  placeholder="Escolha um motivo de reembolso"
                  :list="reasons || []"
                  v-model="selectedReason"
                  label-property="name"
                )

                .refund-request__label Contato *
                Select.refund-request__select(
                  placeholder="Selecione um atendimento"
                  :list="contacts || []"
                  v-model="selectedContact"
                  label-property="name"
                )

                .refund-request__label Observações *
                textarea.refund-request__textarea(
                  v-model="observations"
                  placeholder="Escreva suas observações aqui..."
                )

                .refund-request__label Qual o tipo de Reembolso? *
                .refund-request__radio
                  Radio.refund-request__radio-option(
                    v-model="refundType"
                    label="Voucher"
                    name="refund_type"
                    choice="voucher"
                  )

                  Radio.refund-request__radio-option(
                    v-model="refundType"
                    label="Dinheiro"
                    name="refund_type"
                    choice="default"
                  )

                .refund-request__label É um atendimento Reclame Aqui? *
                .refund-request__radio
                  Radio.refund-request__radio-option(
                    v-model="reclameAqui"
                    label="Sim"
                    name="reclame_aqui"
                    choice="true"
                  )

                  Radio.refund-request__radio-option(
                    v-model="reclameAqui"
                    label="Não"
                    name="reclame_aqui"
                    choice="false"
                  )

                Button.refund-request__button(
                  v-if="!revoucher && !hasUsedCodeVoucher && !hasSubscription"
                  type="positive"
                  class="js-generate-voucher"
                  :disabled="hasErrors"
                  :text="submitting ? 'Aguarde...' : 'Gerar Voucher'"
                  :loading="submitting"
                  @click="createRefundRequest(true)"
                )

                Button.refund-request__button(
                  type="positive"
                  class="js-refund-request"
                  :disabled="hasErrors"
                  :text="submitting ? 'Aguarde...' : 'Solicitar Análise'"
                  :loading="submitting"
                  @click="createRefundRequest(false)"
                )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import Alert from '@/components/common/Alert';
import Card from '@/components/common/Card';
import Radio from '@/components/common/Radio';
import Rollbar from '@/common_modules/tools/Rollbar';
import { ContentLoader } from 'vue-content-loader';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Alert,
    Button,
    Card,
    ContentLoader,
    Radio,
    Select,
    TabHeader,
  },
  data: () => ({
    isLoading: true,
    refundAuthorizationOption: '',
    refundType: '',
    reclameAqui: '',
    refundRequestOutOfTime: null,
    selectedReason: null,
    selectedContact: null,
    hasAuthorization: false,
    contacts: null,
    reasons: null,
    observations: '',
    alert: null,
    submitting: false,
  }),
  computed: {
    ...mapGetters('Order', ['order', 'offerNames']),
    orderData() {
      return this.order.result;
    },
    hasErrors() {
      return (
        !this.selectedContact ||
        !this.observations ||
        !this.selectedReason ||
        this.refundType === '' ||
        this.reclameAqui === ''
      );
    },
    hasUsedCodeVoucher() {
      return this.orderData.hasUsedCodeVoucher;
    },
    hasSubscription() {
      return this.orderData.subscriptionTest;
    },
    revoucher() {
      return this.$attrs.params.revoucher;
    },
    orderId() {
      return this.$attrs.params.orderId;
    },
    cancelVoucherCode() {
      return this.$attrs.params.cancelVoucherCode;
    },
  },
  async created() {
    this.isLoading = true;

    try {
      if (this.orderId) {
        await this.fetchOrder({ orderId: this.orderId });
      }

      const {
        data: { refund_data: refundData },
      } = await this.requestRefundInfo();

      this.contacts = Object.keys(refundData.contacts).map(key => ({
        name: refundData.contacts[key],
        value: key,
      }));

      this.reasons = Object.keys(refundData.reasons).map(key => ({
        name: refundData.reasons[key],
        value: key,
      }));

      this.refundRequestOutOfTime = refundData.refundRequestOutOfTime;
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.notifyError('Erro ao buscar dados. Tente novamente.');
      this.tabGoBack();
    }
  },
  methods: {
    ...mapActions('Order', [
      'requestRefundInfo',
      'submitRefundRequestClicked',
      'fetchOrder',
    ]),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),
    async createRefundRequest(generateVoucher) {
      this.submitting = true;

      try {
        await this.submitRefundRequestClicked({
          contact: this.selectedContact.value,
          observations: this.observations,
          reason: this.selectedReason.value,
          refundType: this.refundType,
          reclameAqui: this.reclameAqui === 'true',
          cancelVoucherCode: this.cancelVoucherCode,
          generateVoucher,
        });

        if (generateVoucher) {
          this.notifySuccess('Voucher criado com sucesso!');
        } else {
          this.notifySuccess(
            'A solicitação de reembolso foi criada com sucesso!'
          );
        }
      } catch (error) {
        Rollbar.error('RefundRequest | error to submit', { error });

        this.notifyError(
          'A solicitação de reembolso falhou. Por favor, contate o suporte.'
        );
      } finally {
        this.submitting = false;
      }
    },
    submitAuthorization() {
      this.hasAuthorization = true;
    },
  },
};
</script>

<style lang="sass">
.refund-request
  display: flex
  flex-direction: column
  height: 100%
  background-color: $space-gray

.refund-request__scroller
  flex-grow: 1
  overflow: auto

.refund-request__body
  padding: $space-st

.refund-request__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.refund-request__text
  padding: $space-s 0

.refund-request__textarea
  @extend %opa-input
  width: 100%
  margin-bottom: $space-st
  height: 89px
  resize: none

.refund-request__label
  margin-bottom: $space-s
  font-weight: bold

.refund-request__text-gray
  padding: $space-s 0
  color: $chrome-gray

.refund-request__radio
  display: flex

.refund-request__radio-option
  margin-right: $space-st

.refund-request__select
  margin-bottom: $space-m

.refund-request__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
