import WhatsappService from '@/assets/javascripts/services/WhatsappService';
import * as WhatsappUsername from '@/assets/javascripts/data/whatsapp/whatsappUsername';
import TicketAPI from '@/api/ticketAPI';

export default {
  async transferToHost(
    { dispatch, rootState },
    {
      customerContactId,
      whatsappUsername,
      whatsappHost,
      allContacts,
      customerId,
    }
  ) {
    const whatsappPhone = WhatsappUsername.getWhatsappPhone(whatsappUsername);
    const whatsappUsernameToTransfer = WhatsappUsername.create(
      whatsappHost,
      whatsappPhone
    );
    const messageToRedirect = getMessageToRedirect(
      rootState.Whatsapp.chatSelected.username
    );
    const hsmTransferData = getHsmTransferData(whatsappHost);

    const { whatsapp_chat_id } = await dispatch('sendTransferMessages', {
      messageToRedirect,
      whatsappUsername,
      hsmKey: hsmTransferData.key,
      withSubstitutions: hsmTransferData.withSubstitutions,
      whatsappUsernameToTransfer,
    });

    const transferOptions = await dispatch(
      'Whatsapp/getTransferOptions',
      whatsappHost,
      { root: true }
    );
    const transferQueue = transferOptions.queues.find(queue =>
      queue.name.includes('Transferência')
    );

    await dispatch(
      'Tickets/registerContactsSubmited',
      {
        allContacts: allContacts,
        contactReasonId: 733,
        contactTypes: ['whatsapp'],
        customerContactIds: [customerContactId],
        customerId: customerId,
        observations: 'Encaminhado para outro número de atendimento',
        whatsappUsername: whatsappUsername,
        attachments: [],
      },
      { root: true }
    );

    await TicketAPI.transferTicket(
      customerContactId,
      'queue',
      transferQueue,
      whatsapp_chat_id
    );

    dispatch(
      'Tickets/contactClosed',
      {
        allContacts: false,
        contactTypes: ['whatsapp'],
        customerId,
        whatsappUsername: whatsappUsernameToTransfer,
        emitter: 'transfer',
      },
      { root: true }
    );
  },

  async sendTransferMessages(
    { dispatch },
    {
      messageToRedirect,
      whatsappUsername,
      hsmKey,
      withSubstitutions,
      whatsappUsernameToTransfer,
    }
  ) {
    await WhatsappService.sendMessage(
      messageToRedirect,
      whatsappUsername
    ).catch(error => {
      dispatch('StoreManager/notify', { message: error }, { root: true });
    });

    return WhatsappService.sendActiveMessage(
      hsmKey,
      whatsappUsernameToTransfer,
      withSubstitutions
    ).catch(error => {
      dispatch('StoreManager/notify', { message: error }, { root: true });
    });
  },
};

function getHsmTransferData(host) {
  return {
    key: `${host}_transfer_message`,
    withSubstitutions: true,
  };
}

function getMessageToRedirect(username) {
  username = usernameParser(username);
  const firstName = username.split(' ')[0];

  return `${firstName}, seu atendimento será realizado por outro time de especialistas, pois tratamos assuntos distintos neste canal.\nPara facilitar, acabei de te enviar uma mensagem através do número correto, para que possamos continuar o atendimento por lá.\nAssim que possível, o time responsável irá te atender.\n\nVamos encerrar essa conversa por aqui, mas prosseguimos pelo outro número, combinado?\nAté mais!`;
}

function usernameParser(username) {
  const regex = /^[_a-z]+[|0-9]+$/;
  const isNumber = regex.test(username);

  username = isNumber ? 'Querobolsista' : username;
  return username;
}
