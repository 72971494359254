import ConversationInfoModel from '@/models/Tickets/ConversationInfoModel';

export const actions = {
  pageEnter({ dispatch }) {
    dispatch('StoreManager/resetRightPanel', null, { root: true });
  },

  deselectedTicket({ dispatch }) {
    dispatch('RightPanel/reset', null, { root: true });
    dispatch('Omni/ticketClosed', null, {
      root: true,
    });
  },

  selectedTicket(
    { dispatch, rootGetters },
    { activeTicket, draftTicket, baseUserId, conversationInfo, tenantId }
  ) {
    if (rootGetters['UserSearch/visible']) return;
    const canChangeBaseUser = draftTicket && !activeTicket;

    dispatch(
      'Profile/showProfileHome',
      { baseUserId, canChangeBaseUser },
      { root: true }
    );
    dispatch('OfferSearch/onChatSelectedChange', null, { root: true });

    dispatch('Omni/ticketSelected', null, {
      root: true,
    });

    dispatch('Omni/clearLastContactClicked', null, {
      root: true,
    });

    dispatch('Admin/updateCurrentTenantById', tenantId, { root: true });

    if (!conversationInfo) return;

    ConversationInfoModel.formatPendingConversations(conversationInfo).forEach(
      contact =>
        dispatch('Omni/clickedContact', contact, {
          root: true,
        })
    );
  },

  ticketUpdate({ dispatch, rootGetters }, { customer_id, conversation_info }) {
    const ticketSelected = rootGetters['Tickets/ticketSelected'];
    const isTicketSelected = customer_id == ticketSelected?.customer_id;

    if (!isTicketSelected) return;

    const omniContacts = ConversationInfoModel.formatPendingConversations(
      conversation_info
    );

    dispatch('Omni/contactListUpdate', omniContacts, {
      root: true,
    });
  },

  async contactClosed(
    { dispatch, rootGetters },
    { allContacts, contactTypes, whatsappUsername, emitter, ticket }
  ) {
    if (rootGetters['UserSearch/visible']) {
      dispatch('UserSearch/closeSearchClicked', null, { root: true });
    }

    if (!allContacts) {
      if (contactTypes.includes('whatsapp')) {
        const currentWhatsappUsername =
          rootGetters['Whatsapp/currentWhatsappUsername'];
        const closedSelectedWhatsappChat =
          currentWhatsappUsername === whatsappUsername;

        if (emitter === 'register') {
          const allContacts = await dispatch(
            'Omni/getContactsByType',
            'whatsapp',
            { root: true }
          );
          const removeContacts = allContacts.filter(
            contact => contact.key === whatsappUsername
          );
          const clickedContact = allContacts.find(
            contact => contact.key !== whatsappUsername
          );

          dispatch('Omni/removeContacts', removeContacts, { root: true });

          if (closedSelectedWhatsappChat && clickedContact) {
            dispatch('Omni/clickedContact', clickedContact, { root: true });
          }
        } else if (emitter === 'transfer') {
          dispatch('ticketUpdate', ticket);

          if (closedSelectedWhatsappChat) {
            dispatch(
              'Whatsapp/selectChat',
              { whatsappUsername, messagesLimit: 20 },
              { root: true }
            );
          }
        }

        dispatch(
          'RightPanel/tabChange',
          { key: 'home', clearStack: true },
          { root: true }
        );
      }
    }

    let feedbackMessage;
    if (emitter === 'register') {
      feedbackMessage = 'Atendimento registrado.';
    } else if (emitter === 'transfer') {
      feedbackMessage = 'Atendimento transferido.';
    }

    dispatch('Notifications/notifySuccess', feedbackMessage, {
      root: true,
    });
  },
};
