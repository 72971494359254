const PENDING_TICKETS_LIMIT = 100;

function canFetchNewTicket(tickets) {
  const unresolvedTickets = tickets.filter(hasUnresolvedConversation);

  return unresolvedTickets.length < PENDING_TICKETS_LIMIT;
}

function hasUnresolvedConversation(ticket) {
  return ticket.conversation_info.some(info => !info.resolved);
}

export { canFetchNewTicket };
