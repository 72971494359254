<template lang="pug">
  .order-list
    TabHeader.order-list__header(title="Matrículas")
    .order-list__list--empty(v-if="emptyOrdersList") Nenhuma matrícula encontrada
    .order-list__list(v-else)
      Loading.order-list__loading(v-if="ordersLoading")
      template(v-else v-for="order in orders")
        OrderTip(:order="order" :key="order.id" @click="orderClicked(order)")
</template>

<script>
import { mapActions, mapState } from 'vuex';

import TabHeader from '@/components/common/TabHeader';
import Loading from '@/components/common/Loading';
import OrderTip from '@/components/Profile/Scholarship/OrderTip';

export default {
  components: {
    OrderTip,
    TabHeader,
    Loading,
  },
  computed: {
    ...mapState('Order', ['orders']),
    ordersLoading() {
      return !this.orders;
    },
    emptyOrdersList() {
      return !this.ordersLoading && !this.orders.length;
    },
  },
  created() {
    if (this.emptyOrdersList) return;

    this.fetchOrders();
  },
  methods: {
    ...mapActions('Order', ['orderClicked', 'fetchOrders']),
  },
};
</script>
