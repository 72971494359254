<template lang="pug">
  .badge(:class="badgeClasses")
    slot
    button.badge__dismiss(
      v-if="dismissable"
      @click="$emit('dismiss')"
    )
      Icon.badge__dismiss-icon(
        svg-name="i-close"
        svg-alt="Excluir"
      )
</template>

<script>
import Icon from '@/components/common/Icon';

const VALID_TYPES = [
  'success',
  'warning',
  'error',
  'abort',
  'info',
  'primary',
  'green',
  'yellow',
  'blue',
  'gray',
  'gray-selected',
];

export default {
  components: {
    Icon,
  },
  props: {
    circular: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'info',
      validator: value => VALID_TYPES.includes(value),
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    badgeClasses() {
      const type = `badge--${this.type}`;

      if (this.circular) {
        return [type, 'badge--circular'];
      }

      return [type];
    },
  },
};
</script>

<style lang="sass">
.badge
  background: $dark-gray
  border-radius: $border-radius
  color: $white
  display: inline-block
  font-size: rem(12)
  font-weight: bold
  line-height: rem(16)
  padding: $space-xxs $space-s
  text-transform: uppercase

.badge--success,
.badge--green
  background: $primary-success

.badge--warning
  background: $primary-warning
  color: $black-gray

.badge--error
  background: $primary-error

.badge--abort
  background: $primary-abort

.badge--info
  background-color: $dark-grey-two
  font-size: rem(10)
  color: $oslo-gray

.badge--primary
  background-color: $primary-dark
  font-size: rem(10)

.badge--yellow
  background-color: $yellow-warning

.badge--blue
  background-color: $curious-blue

.badge--gray
  background-color: $gray-qb3

.badge--gray--selected
  background-color: $another-grey

.badge--orange
  background-color: $dusty-orange

.badge--red
  background-color: $cerise-red

.badge--circular
  border-radius: $border-radius-circular
  font-size: 10px
  font-weight: unset
  text-transform: none

.badge__dismiss
  +square(14px)
  cursor: pointer
  overflow: hidden
  vertical-align: text-bottom
  color: inherit
  margin-left: $space-s
  &:hover .badge__dismiss-icon
    background-color: $black-gray-50

.badge__dismiss-icon
  +circle(14px)
</style>
