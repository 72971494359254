<template lang="pug">
  .billing-details
    Card.queropago-card
      template(slot="header")
        .queropago-card__header
          span Cobrança
          Badge.queropago-card__header-badge(:type="statusBadgeType(currentBilling)")
            | {{currentBilling.translatedStatus}}
      .queropago-card__body
        .queropago-card__item
          strong Mensalidade com desconto:
          .queropago-card__item-content {{ BRLCurrency(currentBilling.installmentValueWithDiscount) }}
        .queropago-card__item
          strong Mensalidade sem desconto:
          .queropago-card__item-content {{  BRLCurrency(currentBilling.installmentValueWithoutDiscount) }}
        .queropago-card__item
          strong Porcentagem de desconto:
          .queropago-card__item-content {{currentBilling.discountPercentage}}%
        .queropago-card__item
          strong Parcelas:
          .queropago-card__item-content {{currentBilling.billsCount}}
        .queropago-card__item
          strong Dia de vencimento:
          .queropago-card__item-content {{currentBilling.dueDay}}
        .queropago-card__item
          strong Semestre de ingresso:
          .queropago-card__item-content {{currentBilling.enrollmentSemester}}
        .queropago-card__item
          strong Origem:
          .queropago-card__item-content {{currentBilling.translatedOrigin}}
        .queropago-card__item
          strong Observações:
          .queropago-card__item-content {{currentBilling.observations}}
        .queropago-card__item
          strong Meio de pagamento:
          .queropago-card__item-content {{currentBilling.translatedRecurrentPaymentMethod}}
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import QueropagoHelper from '@/helpers/QueropagoHelper';
import BRLCurrency from '@/assets/javascripts/formatters/BRLCurrency.js';

export default {
  components: {
    Card,
    Badge,
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
  },
  methods: {
    BRLCurrency,
    statusBadgeType(billing) {
      return QueropagoHelper.billingStatusBadgeType(billing);
    },
  },
};
</script>
