const store = {};

/**
 * Para usar em momentos em que serão disparados vários requests um seguido do outro.
 * Não temos garantia de que os requests serão resolvidos em ordem.
 * O carry serve para anular os requests mais "velhos".
 * Você passa o request `autocomplete` pelo carry `carry(autocomplete('unip'), 'autocomplete')`
 * e ele encapsula a resposta em um objeto `{ result, blocked }`
 * e se aquela resposta não for a do último request, blocked será `true`.
 * Para elucidar melhor, imagine o autocomplete, vocês digita `s` e depois `sa`.
 * Foram feitos dois requests muito próximos um do outro. Imagina se o request `autocomplete('s')` se resolve depois do `autocomplete('sa')`.
 * Se não usar o carry, o usuário vai acabar vendo os resultados para `s` sendo que ele digitou `sa`.
 * @param promise - a função a ser executada, que retorna uma promise
 * @param key - identificador para um mesmo grupo de requests
 */
export default function carry(promise, key) {
  if (store[key]) {
    store[key].block();
  }

  if (!promise) return;

  let resolve;
  const thePromise = new Promise(doResolve => {
    resolve = doResolve;
  });

  promise.then(result => resolve({ result, blocked: false }));
  thePromise.block = () => resolve({ blocked: true });
  store[key] = thePromise;

  promise.finally(() => {
    if (store[key] === thePromise) {
      store[key] = undefined;
    }
  });
  return thePromise;
}

export async function carryDebounce(fn, key, time) {
  const result = await carry(time ? delay(time) : fn(), key);
  if (time && !result.blocked) {
    return await carryDebounce(fn, key);
  } else {
    return result;
  }
}

function delay(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}
