import apolloCache from '@/plugins/apollo/querobolsa/cache';

export default function() {
  return {
    getAuth: () =>
      `Basic ${btoa(
        process.env.QB_OPA_CLIENT_USER + ':' + process.env.QB_OPA_CLIENT_PASSWD
      )}`,
    httpEndpoint: `${process.env.QUERO_BOLSA_HOST}/graphql`,
    cache: apolloCache,
  };
}
