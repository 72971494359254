<template lang="pug">
  div(style="position: relative;")
    Loading(onTop)
    template(v-for="x in 3")
      Card.order-info__card
        .skeleton__card-body
</template>

<script>
import Card from '@/components/common/Card';
import Loading from '@/components/common/Loading';

export default {
  components: {
    Card,
    Loading,
  },
};
</script>
