<template lang="pug">
  .nav-list
    template(v-for="item in list")
      button.nav-list__item(
        :title="item.titleAttribute"
        @click="clicked(item)"
        v-show="!item.disabled"
      )
        .nav-list__item-container
          a.nav-list__title {{ item.title }}
          Badge.nav-list__badge(
            v-if="item.badgeText"
            :type="item.badgeType"
          ) {{ item.badgeText }}
          Icon(svg-name="i-chevron-right" svg-class="nav-list__icon")
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/common/Icon';
import Badge from '@/components/common/Badge';

export default {
  components: {
    Icon,
    Badge,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    clicked(item) {
      if (item.tab) {
        this.tabChange({ key: item.tab });
      } else {
        this.$emit('navclick', item);
      }
    },
  },
};

//pending: Show badges with useful data.
</script>
