<template lang="pug">
.reactivate-voucher
  TabHeader.reactivate-voucher__header(title="Vouchers do aluno")
  form.reactivate-voucher__body(@submit.prevent="submit")
    .reactivate-voucher__title Definir prazo do voucher:
    .reactivate-voucher__radio
      Radio(
        class="reactivate-voucher__radio-option"
        v-model="daysToExpire"
        label="7 dias"
        name="new_voucher_expiration"
        choice="7"
      )

      Radio(
        class="reactivate-voucher__radio-option"
        v-model="daysToExpire"
        label="6 meses"
        name="new_voucher_expiration"
        choice="180"
      )

    .reactivate-voucher__text Ao reativar o voucher a nova data de vencimento será até <b>{{ newExpireDate }}</b>

    .reactivate-voucher__text Deseja continuar com a reativação do voucher?

    Button(
      class="reactivate-voucher__submit"
      type="positive"
      :text="submitting ? 'Aguarde...' : 'Prosseguir'"
      :submit="true"
      :loading="submitting"
    )
</template>

<script>
import Button from '@/components/common/Button';
import TabHeader from '@/components/common/TabHeader';
import Radio from '@/components/common/Radio';
import Rollbar from '@/common_modules/tools/Rollbar';
import { mapActions } from 'vuex';
import moment from 'moment';
import VoucherAPI from '@/api/voucherAPI';

export default {
  components: {
    Button,
    TabHeader,
    Radio,
  },
  data: () => ({
    isLoading: true,
    daysToExpire: '7',
    submitting: false,
  }),
  computed: {
    newExpireDate() {
      const newDate = moment().add(this.daysToExpire, 'days');

      return newDate.format('L');
    },
    voucherId() {
      const { params } = this.$attrs;
      const { voucher } = params;
      const { id } = voucher;

      return id;
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabGoBack']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    async submit() {
      try {
        this.submitting = true;

        await VoucherAPI.reactivateVoucher({
          voucherId: this.voucherId,
          daysToExpire: this.daysToExpire,
        });

        this.notifySuccess('Voucher reativado com sucesso');

        this.tabGoBack();
      } catch (error) {
        Rollbar.error('ReactivateVoucher | error to submit', { error });

        this.notifyError(
          'A reativação do voucher falhou. Por favor, contate o suporte.'
        );
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.reactivate-voucher
  height: 100%
  display: flex
  flex-direction: column
  background: $space-gray

.reactivate-voucher__header
  flex-shrink: 0

.reactivate-voucher__body
  position: relative
  overflow-y: scroll
  overflow-y: overlay
  flex-grow: 1
  padding: $space-st

  > div
    margin-bottom: $space-m

.reactivate-voucher__title
  font-weight: bold

.reactivate-voucher__submit
  width: 100%

.reactivate-voucher__radio
  display: flex

  > .radio
    margin-bottom: 0

.reactivate-voucher__radio-option
  margin-right: $space-st
</style>
