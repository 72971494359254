import amplitude from 'amplitude-js';

const enabledAmplitudeConfig = {
  key: process.env.AMPLITUDE_API_KEY,
  enabled: true,
};

const amplitudeConfigs = {
  production: enabledAmplitudeConfig,
  staging: enabledAmplitudeConfig,
  development: {
    enabled: false,
  },
};

const amplitudeConfig = amplitudeConfigs[process.env.NUXT_ENV_BUILD] || {};

const initAmplitude = () => {
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(amplitudeConfig.key);
  amplitudeInstance.setOptOut(false);

  return {
    identify(id, props) {
      amplitudeInstance.setUserId(id);
      const userProps = {
        ...props,
        opa_client: true,
      };
      amplitudeInstance.setUserProperties(userProps);
    },
    logEvent(evName, evProps = {}) {
      amplitudeInstance.logEvent(evName, {
        ...evProps,
        system: 'opa-client',
      });
    },
  };
};

const emptyAmplitudeApi = {
  identify() {},
  logEvent(evName, evProps) {
    if (process.env.NODE_ENV === 'test') return;
    console.info(`[AMPLITUDE] ${evName}`, evProps);
  },
};

export default amplitudeConfig.enabled ? initAmplitude() : emptyAmplitudeApi;
