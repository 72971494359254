<template lang="pug">
  .register-user
    TabHeader(title="Cadastro do aluno")
    form.register-user__form(ref="form")
      RegisterUserBody(
        v-model="registerUserBody"
        :registerType="params.registerType"
      )
      Alert.register-user__feedback(
        v-if="feedback.type"
        :type="feedback.type"
        :message="feedback.message"
        :dismissable="false"
      )
      Button.register-user__submit(
        text="Salvar os Dados"
        type="positive"
        :disabled="registerUserBody.hasErrors || feedbackSucess"
        @click="submit"
      )
</template>
<script>
import RegisterUserBody from '@/components/Profile/RegisterUserBody';
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';
import ProfileService from '@/assets/javascripts/services/ProfileService.js';
import { get } from 'lodash';

import { cleanData } from '@/common_modules/formLib';
import { mapActions, mapState } from 'vuex';
import Rollbar from '@/common_modules/tools/Rollbar';

export default {
  components: {
    RegisterUserBody,
    TabHeader,
    Button,
    Alert,
  },

  props: {
    params: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      feedback: {},
      registerUserBody: {},
    };
  },

  computed: {
    ...mapState('Admin', ['currentTenant']),
    feedbackSucess() {
      return this.feedback.type === 'success';
    },
  },

  created() {
    const { registerType } = this.params;
    this.registerUserOpened({ registerType });
  },

  methods: {
    ...mapActions('Profile', ['saveProfile', 'registerUserOpened']),
    ...mapActions('RightPanel', ['tabChange']),
    ...mapActions('StoreManager/Profile', ['userCreated']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),

    async submit() {
      const { shouldContinue } = handleDuplicationWarning(
        this.registerUserBody
      );
      if (!shouldContinue) return;

      const { registerType } = this.params;
      const cleanFormData = cleanData(this.registerUserBody.formData);

      if (registerType == 'create') {
        this.createUser(cleanFormData);
      } else {
        this.updateUser(cleanFormData);
      }
    },

    async updateUser(cleanFormData) {
      const scroller = this.$refs.form;
      const saveScrollPosition = scroller.scrollHeight;

      try {
        await this.saveProfile(cleanFormData);

        this.feedback = {
          type: 'success',
          message: 'Cadastro salvo com sucesso!',
        };

        setTimeout(() => {
          this.feedback = {};
        }, 3000);
      } catch (errors) {
        const errorMessage = get(errors, 'response.data.message[0]', '');

        if (errorMessage) {
          const { field, message } = errorMessage;
          this.feedback = {
            type: 'error',
            message: `Problema no campo "${translate(field)}". |
            ${translate(message)}`,
          };
        } else {
          this.feedback = {
            type: 'error',
            message: 'Ocorreu uma falha',
          };
        }

        Rollbar.error('RegisterUser | error to submit', { error: errors });
      }

      const userScrolled = scroller.scrollHeight !== saveScrollPosition;

      if (!userScrolled) {
        this.scrollToBottom();
      }
    },

    async createUser(cleanFormDataWithEmailLogin) {
      let baseUserId;
      const { emailLogin, ...cleanFormData } = cleanFormDataWithEmailLogin;

      const createUserParams = {
        name: cleanFormData.name,
        email: emailLogin,
        tenantIdentifier: this.currentTenant.code,
      };

      try {
        const { base_user_id } = await ProfileService.createUser(
          createUserParams
        );
        baseUserId = base_user_id;
      } catch (error) {
        return this.notifyError('Problema ao criar usuário');
      }

      this.notifySuccess('Usuário criado com sucesso');

      const saveProfileParams = {
        ...cleanFormData,
        id: baseUserId,
        emails: [emailLogin],
      };

      try {
        await ProfileService.saveProfile(saveProfileParams);
      } catch (errors) {
        this.notifyError('Problema ao salvar dados do usuário');
      }

      this.userCreated({ baseUserId });
    },

    scrollToBottom() {
      const scroller = this.$refs.form;
      requestAnimationFrame(() => {
        scroller.scrollTop = scroller.scrollHeight;
        requestAnimationFrame(() => {
          //Sometimes it doesn't works the first time.
          scroller.scrollTop = scroller.scrollHeight;
        });
      });
    },
  },
};

const handleDuplicationWarning = registerUserBody => {
  let fieldDuplicatedName;

  if (registerUserBody.hasDuplicatedCPF) {
    fieldDuplicatedName = 'CPF';
  } else if (registerUserBody.hasDuplicatedEmail) {
    fieldDuplicatedName = 'e-mail';
  }

  if (!fieldDuplicatedName) return { shouldContinue: true };

  const confirmed = confirm(`Já existe uma conta com esse mesmo ${fieldDuplicatedName} no QueroBolsa.

Criando uma conta nova, quando o aluno realiza uma compra a comissão pode não ser contabilizada e o aluno pode não conseguir visualizar a bolsa garantida no site.
O correto é fazer o atendimento pela conta que o aluno utiliza para se logar no QueroBolsa.

Tem certeza que deseja continuar?`);

  return { shouldContinue: confirmed };
};

function translate(text) {
  const translationMap = {
    address_number: 'Número',
    state: 'Estado',
    city: 'Cidade',
    address: 'Rua',
    name: 'Nome',
    "can't be blank": 'não pode ficar vazio',
  };
  return translationMap[text] ? translationMap[text] : text;
}
</script>

<style lang="sass">
.register-user
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100%

.register-user__form
  padding: $space-m $space-st
  overflow-y: auto
  flex-grow: 1

.register-user__submit
  width: 100%
  margin-top: $space-st

.register-user__feedback
  margin-top: $space-st
</style>
