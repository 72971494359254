<template lang="pug">
  button.opa-icon__container(
    @click="$emit('click')"
    type="button"
    :disabled="disabled"
  )
    svg.opa-icon(
      :class="svgClass"
      :alt="svgAlt"
    )
      title {{ svgAlt }}
      use(:href="`#${svgName}`")
</template>

<script>
export default {
  props: {
    svgClass: {
      type: String,
      required: false,
      default: '',
    },
    svgName: {
      type: String,
      required: true,
    },
    svgAlt: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
