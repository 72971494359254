import Amplitude from '@/common_modules/tools/Amplitude';

const OfferSearchMetrics = {
  onEnter(ctx) {
    this.entered = true;
    this.offerClicks = 0;
    this.enterTime = Date.now();
    this.context = ctx;
  },
  onClickOffer() {
    this.offerClicks++;
    if (this.offerClicks == 1) {
      this.onFirstOfferClick();
    }
  },
  onLeave() {
    if (!this.entered) return;
    this.entered = false;

    const totalTime = (Date.now() - this.enterTime) / 1000;
    const params = {
      time: totalTime,
      offerClicks: this.offerClicks,
      context: this.context,
    };

    this.commitEvent('[OfferSearchMetrics] searchLeave', params);
  },
  onFirstOfferClick() {
    if (!this.entered) return;
    const totalTime = (Date.now() - this.enterTime) / 1000;

    this.commitEvent('[OfferSearchMetrics] firstOfferClick', {
      time: totalTime,
      context: this.context,
    });
  },
  commitEvent(name, params) {
    Amplitude.logEvent(name, params);
  },
};

window.addEventListener('beforeunload', function() {
  OfferSearchMetrics.onLeave();
});

export default OfferSearchMetrics;
