<template lang="pug">
  Card
    template(slot="header")
      div
        .admission-title.admission-title--small
          | Matrícula
        .admission-text.admission-text--xs
          | ID {{ admission.id }}

    .admission-card__content-wrapper
      .admission-card__body-item
        span Situação:
        Badge.admission-card__status-badge(:type="admission | followUpStepColor")
          | {{ admission | followUpStepTranslate }}
      .admission-card__body-item
        Icon.admission-card__icon(svg-name="i-schedule")
        span {{ admission.statusDate | momentformat('L - LT') }}
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import Icon from '@/components/common/Icon';

import {
  followUpStepColor,
  followUpStepTranslate,
} from '@/assets/javascripts/data/admissions/followUpStep';

export default {
  components: {
    Card,
    Badge,
    Icon,
  },

  filters: {
    followUpStepColor,
    followUpStepTranslate,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },
};
</script>
