<template lang="pug">
  Card
    template(slot="header")
      .admission-title.admission-title--small
        | Informações do processo

    .admission-card__content-wrapper
      .spacer-bottom-st(v-if="admission.documents.length")
        .admission-title.admission-title--small.admission-title--gray.spacer-bottom-s
          | Documentos para admissão digital

        p(v-for="document in admission.documents").admission-text.admission-text--xs.admission-text--gray.spacer-bottom-s
          | - {{ document | docKindTranslate }}

      .spacer-bottom-st(v-if="admission.admissionExams.length")
        .admission-title.admission-title--small.admission-title--gray.spacer-bottom-s
          | Formas de Ingresso

        p.admission-text.admission-text--xs.admission-text--gray.spacer-bottom-s(v-for="admissionExam in admission.admissionExams")
          strong {{ admissionExam | admissionExamTitle }}
          |
          | {{ admissionExam | admissionExamDescription }}

      .spacer-bottom-st(v-if="admission.enrollmentFee")
        .admission-title.admission-title--small.admission-title--gray.spacer-bottom-s
          | Taxa de matrícula

        .admission-text.admission-text--xs.admission-text--gray
          | Valor da taxa {{ admission.enrollmentFee.value | brlcurrency }}

      div
        .admission-title.admission-title--small.admission-title--gray.spacer-bottom-s
          | Instruções pós matrícula

        .admission-text.admission-text--xs.admission-text--gray
          template(v-if="admission.enrollmentInstructions")
            | {{ admission.enrollmentInstructions }}
          template(v-else)
            | Sem instruções pós matrícula
</template>

<script>
import Card from '@/components/common/Card';

import {
  docKindTranslate,
  docStatusTranslate,
} from '@/assets/javascripts/data/admissions/document';

import {
  admissionExamTitle,
  admissionExamDescription,
} from '@/assets/javascripts/data/admissions/admissionExam';

export default {
  components: {
    Card,
  },

  filters: {
    docKindTranslate,
    docStatusTranslate,
    admissionExamTitle,
    admissionExamDescription,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },
};
</script>
