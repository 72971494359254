<template lang="pug">
  .register-bo
    TabHeader(:title="offerNames.course || '.'" :subTitle="offerNames.university || '.'")
    .register-bo__body
      Card.opa__card
        template(slot="header")
          .opa__card-header Registrar B.O.

        .opa__card-body
          .register-bo__type-container(v-if="options.types")
            .register-bo__label Tipo
            Select.register-bo__select(
              placeholder="Selecione um tipo"
              :list="options.types"
              v-model="fields.type"
              label-property="name"
            )
          .register-bo__label Motivo
          Select.register-bo__select(
            placeholder="Selecione um tipo"
            :list="options.reasons || []"
            v-model="fields.reason"
            label-property="name"
          )

          .register-bo__label Contato
          Select.register-bo__select(
            :disabled="!hasContactEvents"
            :placeholder="hasContactEvents ? 'Selecione um contato' : 'Nenhum contato para selecionar'"
            :list="options.contacts || []"
            v-model="fields.contact"
            label-property="name"
          )

          Checkbox(
            v-model="fields.reclameAqui"
            label="Reclame Aqui"
          )

          .register-bo__label Observações
          textarea.register-bo__textarea(
            v-model="fields.observations"
            placeholder="Acrescente alguma informação relativa ao caso, se necessário."
          )

          Alert.register-bo__alert(
            v-if="failed"
            :message="alertMessage"
            :type="alertType"
            :dismissable="false"
          )

          Button.register-bo__button(
            type="positive"
            :disabled="hasFilledFields === false"
            :text="submitting ? 'Aguarde...' : 'Registrar B.O.'"
            @click="submit"
            :loading="submitting"
          )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import Alert from '@/components/common/Alert';
import Card from '@/components/common/Card';
import Checkbox from '@/components/common/Checkbox';
import Rollbar from '@/common_modules/tools/Rollbar';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
    Select,
    Alert,
    Card,
    Checkbox,
  },
  data() {
    return {
      fields: {
        type: null,
        reason: null,
        reclameAqui: false,
        observations: null,
      },
      options: {},
      submitting: false,
      failed: false,
      alertMessage: null,
      alertType: 'error',
    };
  },
  computed: {
    ...mapGetters('Order', ['offerNames']),
    hasFilledFields() {
      const { type, reason, observations, contact } = this.fields;
      const isStudentBo = this.options && this.options.types == null;

      if (isStudentBo) return Boolean(reason && observations && contact);

      return Boolean(type && reason && observations && contact);
    },
    hasContactEvents() {
      return this.options.contacts && this.options.contacts.length > 0;
    },
  },
  async created() {
    const options = await this.getRegisterBOOptions();
    this.options = options || {};
  },
  methods: {
    ...mapActions('Order', ['submitRegisterBOCliked', 'getRegisterBOOptions']),
    async submit() {
      this.failed = false;
      this.submitting = true;

      try {
        await this.submitRegisterBOCliked(this.fields);
      } catch (errors) {
        if (
          errors.response.data &&
          errors.response.data.message == 'bo_already_created'
        ) {
          this.alertType = 'warning';
          this.alertMessage =
            'Não é possível abrir essa solicitação para este motivo.' +
            ' Um B.O existente já foi cadastrado, e o tempo de atendimento ainda não foi expirado.';
        } else {
          this.alertMessage = 'Ocorreu um erro ao registrar B.O.';
        }

        this.failed = true;
        Rollbar.error('RegisterBO | error to submit', { error: errors });
      }

      this.submitting = false;
    },
  },
};
</script>
