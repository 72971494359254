<template lang="pug">
  .financial-data
    TabHeader.order-list__header(title="Financeiro")

    .financial-data__scroller
      .financial-data__body
        CourseDetails
        BillingDetails
        CardDetails(v-if="shouldShowCardData")
        ContractDetails
        AgreementDetails
</template>

<script>
import { mapState } from 'vuex';
import CardDetails from '@/components/Profile/Queropago/CardDetails';
import CourseDetails from '@/components/Profile/Queropago/CourseDetails';
import BillingDetails from '@/components/Profile/Queropago/BillingDetails';
import ContractDetails from '@/components/Profile/Queropago/ContractDetails';
import AgreementDetails from '@/components/Profile/Queropago/AgreementDetails';
import TabHeader from '@/components/common/TabHeader';
import Card from '@/components/common/Card';

export default {
  components: {
    CardDetails,
    CourseDetails,
    BillingDetails,
    ContractDetails,
    TabHeader,
    Card,
    AgreementDetails,
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
    shouldShowCardData() {
      return ['Cartão de Crédito', 'Cartão de Débito'].includes(
        this.currentBilling.translatedRecurrentPaymentMethod
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.financial-data
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.financial-data__scroller
  background-color: $space-gray
  flex-grow: 1
  overflow: auto

.financial-data__body
  padding: $space-st
</style>
