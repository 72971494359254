const contactTypeMap = {
  active: 'Ligação Ativa',
  active_call_center: 'Ligação ativa (Call Center)',
  chat_active: 'Chat Ativo',
  chat_receptive: 'Chat Receptivo',
  email: 'Email',
  facebook: 'Facebook',
  follow_up: 'Ativo de Acompanhamento',
  freshdesk: 'FreshDesk',
  midias: 'Mídias',
  olark: 'Olark',
  omni_messenger: 'Messenger',
  receiving: 'Receptivo',
  required_call: 'Ativo solicitado',
  whatsapp: 'WhatsApp',
  zendesk_chat: 'Zendesk Chat',
};

const contactTypeIconMap = {
  active: 'i-phone',
  active_call_center: 'i-phone',
  omni_messenger: 'i-messenger',
  receiving: 'i-phone',
  required_call: 'i-phone',
  whatsapp: 'i-whatsapp',
  email: 'i-email',
  chat_active: 'i-inbox',
  chat_receptive: 'i-inbox',
  facebook: 'i-messenger',
  midias: 'i-messenger',
};

const possibleContactTypes = [
  {
    value: 'required_call',
    label: 'Ativo Solicitado',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'chat_receptive',
    label: 'Chat Receptivo',
  },
  {
    value: 'chat_active',
    label: 'Chat Ativo',
  },
  {
    value: 'midias',
    label: 'Mídias',
  },
];

const contactTypeTranslate = type => contactTypeMap[type];
const contactTypeIcon = type => contactTypeIconMap[type];

const getDisplayDate = ({ registeredAt, createdAt }) => {
  return registeredAt || createdAt;
};

export {
  contactTypeTranslate,
  contactTypeIcon,
  possibleContactTypes,
  getDisplayDate,
};
