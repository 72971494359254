export default (store, inputHandler) => {
  const self = {
    initialize() {
      window.addEventListener('keydown', self.onKeydown);
      window.addEventListener('keyup', self.onKeyup);
      window.addEventListener('blur', self.onPageBlur);
    },
    onKeydown(ev) {
      if (store.shortcuting) {
        if (ev.key === 'Shift') {
          store.resetShortcuting();
          return;
        }

        if (!store.isActivationKey(ev) && !store.doesTriggerContains(ev.key)) {
          store.addToTrigger(ev.key);
        }

        ev.preventDefault();
        return;
      }

      if (store.isActivationKey(ev)) {
        inputHandler.onStartShortcuting(ev);
        store.startShortcuting();
        ev.preventDefault();
      }
    },
    onKeyup(ev) {
      if (!store.shortcuting) return;

      if (store.isActivationKey(ev)) {
        inputHandler.onStopShortcuting(ev);
        store.resetShortcuting();
        return;
      }

      const bindFunction = store.shootTrigger();
      if (bindFunction) {
        bindFunction();
        store.setProp('shortcutTriggered', true);
      }

      store.removeFromTrigger(ev.key);
    },
    onPageBlur() {
      store.resetShortcuting();
    },
  };

  return self;
};
