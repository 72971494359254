import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  async updateStep({ followUpId, step, observations }) {
    const params = {
      follow_up_id: followUpId,
      step,
      observations,
    };

    return HttpService.post('/api/follow_up/update_step', params);
  },
  async resendDigitalContract({ followUpId, email }) {
    const params = {
      follow_up_id: followUpId,
      email,
    };

    return HttpService.post('/api/follow_up/resend_digital_contract', params);
  },
  async updateAgreementState({ followUpId }) {
    const params = {
      follow_up_id: followUpId,
    };

    return HttpService.post('/api/follow_up/update_agreement_state', params);
  },
  async activateDigitalAdmission({ followUpId }) {
    const params = {
      follow_up_id: followUpId,
    };

    return HttpService.post(
      '/api/follow_up/activate_digital_admission',
      params
    );
  },
};
