export const courseData = [
  {
    svgName: 'i-hour-glass',
    value: 'enrollmentSemester',
  },
  {
    svgName: 'i-star',
    value: 'level',
  },
  {
    svgName: 'i-import-contacts',
    value: 'kind',
  },
  {
    svgName: 'i-schedule',
    value: 'shift',
  },
  {
    svgName: 'i-timeline',
    value: 'period',
  },
  {
    svgName: 'i-campus',
    value: 'campusName',
  },
  {
    svgName: 'i-room',
    value: 'campusAddress',
  },
  {
    svgName: 'i-phone',
    value: 'contactPhone',
  },
];
