export default () => ({
  chatSelected: null,
  chatSelectedLoading: false,
  chatSelectedLoadingAll: false,
  queueOptions: [],
  queueOptionSelected: null,
  shortcuts: [],
  attendants: [],
  setedListeners: false,
});
