<template lang="pug">
  Card(v-if="admission.documents.length" showFooterTemplate=true)
    template(v-slot:header)
      .admission-title.admission-title--small
        | Lista

    template(v-slot:default)
      .admission-card__content-wrapper.admission-card__content-wrapper--spaced-s
        template(v-for="(document, index) in admission.documents")
          hr.divider.spacer-bottom-s(v-if="index > 0")
          .document-list-item
            div
              .admission-text.admission-text--xs.admission-text--bold
                | {{ document | docKindTranslate }}
              .admission-text.admission-text--xs.admission-text--gray.spacer-top-s(v-if="document.statusDate")
                | {{ document.statusDate | momentformat('L') }}
              .admission-text.admission-text--xs.admission-text--gray.spacer-top-s(v-if="document.status == 'rejected'")
                | Motivo: {{ document.rejectionReason }}

            Badge.admission-card__status-badge(:type="document | docStatusColor")
              | {{ document | docStatusTranslate }}

    template(v-slot:footer)
      button.opa-card__btn.opa-card__btn--right(type="button" @click="openQueroAlunos")
        span.opa-card__btn-text
          | Quero Alunos
        Icon(svg-name="i-chevron-right", svg-class="opa-card__btn-icon")
</template>

<style lang="sass" scoped>
.document-list-item
  align-items: center
  display: flex
  justify-content: space-between

  &:not(:last-child)
    margin-bottom: $space-s
</style>

<script>
import Badge from '@/components/common/Badge';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';

import {
  docKindTranslate,
  docStatusColor,
  docStatusTranslate,
} from '@/assets/javascripts/data/admissions/document';

export default {
  components: {
    Badge,
    Card,
    Icon,
  },

  filters: {
    docKindTranslate,
    docStatusColor,
    docStatusTranslate,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },

  methods: {
    openQueroAlunos() {
      let admission_id = this.admission.id;
      window.open(
        `${process.env.QUERO_ALUNOS_HOST}/busca-pre-matriculas/${admission_id}`,
        '_blank'
      );
    },
  },
};
</script>
