<template lang="pug">
  Popover(
    :mirrorEl="()=>$refs.input"
    v-model="isPopoverOpened"
    :toggleableFocus="false"
  )
    .opa-select__header(
      :class="headerClass"
      @mousedown="headerMouseDown"
    )
      .opa-select__selected
        input.opa-select__textinput(
          :class="{ 'opa-select__textinput--hide': hideInput }"
          :disabled="disabled"
          ref="input"
          :placeholder="placeholder"
          v-model="textInput"
          @focus="inputEvent"
        )
        template(v-if="hideInput")
          slot(:item="actualValue")
            .opa-select__selected-item(:title="getItemLabel(actualValue)")
              .opa-select__selected-item-text {{ getItemLabel(actualValue) }}
      Icon.opa-select__icon(
        svg-name="i-chevron-right"
        :svg-class="iconStatusClass"
      )
    template(slot="popover")
      Selector(
        :loading="loading"
        :list="list"
        :openSelected="0"
        :searchStr="autocompleteFilter ? textInput : null"
        :labelProperty="labelProperty"
        @selected="onSelected"
        @close="blurInput"
      )
        template(slot-scope="{ item }")
          slot(:item="item")
</template>

<script>
import Icon from '@/components/common/Icon.vue';
import Popover from '@/components/common/Popover.vue';
import Selector from '@/components/common/Selector.vue';

export default {
  components: {
    Popover,
    Icon,
    Selector,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    // for v-model
    value: {
      type: Object,
      required: false,
      default: null,
    },
    forceValue: {
      type: Object,
      required: false,
      default: null,
    },
    labelProperty: {
      type: String,
      required: false,
      default: null,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    smallVersion: {
      type: Boolean,
      required: false,
      default: false,
    },
    // autoCompleteFilter: if true, list filtering is enabled
    autocompleteFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isPopoverOpened: false,
    textInput: '',
  }),
  computed: {
    iconStatusClass() {
      return (
        'opa-select__choose-svg' +
        (this.isPopoverOpened ? ' opa-select__choose-svg--open' : '')
      );
    },
    hideInput() {
      return !this.isPopoverOpened && this.actualValue;
    },
    actualValue() {
      return this.forceValue || this.value;
    },
    headerClass() {
      return {
        'opa-select__header--disabled': this.disabled,
        'opa-select__header--small': this.smallVersion,
      };
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    headerMouseDown(e) {
      if (e.target.nodeaName === 'INPUT') return;
      if (!this.isPopoverOpened) {
        this.$refs.input.focus();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    inputEvent({ type }) {
      if (type == 'focus') {
        this.textInput = '';
      }

      this.$emit('showList');
    },
    onSelected(item) {
      this.setValueTo(item);
      this.blurInput();
    },
    blurInput() {
      this.$refs.input.blur();
    },
    setValueTo(val) {
      this.$emit('input', val);
    },
    getItemLabel(item) {
      if (!item) {
        return '';
      }
      if (this.labelProperty) {
        return item[this.labelProperty];
      }

      return JSON.stringify(item);
    },
  },
};
</script>
