import Rollbar from '@/common_modules/tools/Rollbar';
import formatFeedbackMessage from '@/assets/javascripts/formatters/FeedbackMessageFormatter.js';

export const state = () => ({
  notifications: [],
});

export const getters = {
  currentPage: (x, y, z, rootGetters) =>
    rootGetters['Sidebar/currentPath'].split('/')[1],
  adminUserId: (x, y, z, rootGetters) => rootGetters['Admin/getId'],
};

export const actions = {
  resetRightPanel({ dispatch }) {
    dispatch('RightPanel/reset', null, { root: true });
    dispatch('RightPanel/showPanel', null, { root: true });
  },
  notify({ dispatch }, { message, errors, type = 'error' }) {
    message = formatFeedbackMessage(message);

    if (errors) {
      Rollbar.error(errors.errorTitle, { error: errors.errorDetails });
    }

    dispatch('Notifications/notify', { message, type }, { root: true });
  },
  tabGoBack({ dispatch }) {
    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
};
