// This middleware is responsible for
// redirect user to "home" if already
// authenticated.

import TokenService from '@/assets/javascripts/services/TokenService';

export default () => {
  if (!TokenService.isEmpty()) {
    window.location.href = '/';
    return;
  }
};
