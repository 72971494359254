<template lang="pug">
  .opa-alert(:class="alertClass" role="alert" v-show="isVisible")
    .opa-alert__icon-wrapper(:class="alertIconWrapperClass")
      icon(
        :svg-name="alertIcon"
        :svg-class="`opa-alert__icon ${alertIconClass}`"
      )

    .opa-alert__body
      .opa-alert__message-wrapper(v-if="message")
        p.opa-alert__message(:class="alertMessageClass") {{ message }}

      button.opa-alert__dismiss(v-if="dismissable && !hasSlots" type="button" @click="dismiss")
        icon(
          svg-name="i-close"
          :svg-class="`opa-alert__dismiss__icon ${alertDismissIconClass}`"
        )

      slot
</template>

<script>
import Icon from '@/components/common/Icon';

const VALID_TYPES = ['success', 'warning', 'error'];

export default {
  components: {
    Icon,
  },

  props: {
    type: {
      type: String,
      required: true,
      validator: value => VALID_TYPES.includes(value),
    },
    message: {
      type: String,
      required: false,
      default: null,
    },
    dismissable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      visible: true,
    };
  },

  computed: {
    isVisible() {
      return this.visible && this.message;
    },
    alertClass() {
      return `opa-alert--${this.type}`;
    },
    alertIconWrapperClass() {
      return `opa-alert__icon-wrapper--${this.type}`;
    },
    alertIcon() {
      return `i-${this.type}`;
    },
    alertIconClass() {
      return `opa-alert__icon--${this.type}`;
    },
    alertMessageClass() {
      return `opa-alert__message--${this.type}`;
    },
    alertDismissIconClass() {
      return `opa-alert__dismiss__icon--${this.type}`;
    },
    hasSlots() {
      return !!this.$slots.default;
    },
  },

  watch: {
    message() {
      this.visible = true;
    },
  },

  methods: {
    dismiss() {
      this.visible = false;
    },
  },
};
</script>
