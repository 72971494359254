<template lang="pug">
  .loading(:class="{ 'loading--ontop' : onTop }")
    Icon.loading__icon(svg-name="i-engine")
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    onTop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
