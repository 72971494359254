import { getField, isValidPhone } from '@/common_modules/formLib';

import _ from 'lodash';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';

export default function(_this) {
  const onInput = {
    async cpf(data) {
      const cpf = _.trim(data.value);
      resetData(data, _this);

      const tstOk = cpf.length === 14;
      if (!tstOk) {
        return;
      }

      const alreadySetted = hasAlreadySetted('cpf', cpf, _this);
      if (alreadySetted) {
        _this.$set(data, 'isOk', true);
        return;
      }

      const validCpf = isValidCPF(cpf);
      if (!validCpf) {
        _this.$set(data, 'alert', {
          message: 'CPF inválido',
          type: 'error',
        });
        return;
      }

      duplicationAnalyzer('cpf', data, _this);
      _this.$set(data, 'isOk', true);
    },
    async postalCode(data) {
      const postalCode = _.trim(data.value);

      const tstOk = postalCode.length === 8;
      _this.$set(data, 'isOk', tstOk);

      if (!tstOk) {
        return;
      }

      _this.loadingAddress = true;
      let address = await _this.getAddress(data.value);
      _this.loadingAddress = false;

      Object.keys(address).forEach(key => {
        _this.formData[key].value = address[key];
      });
    },
    emails(data) {
      resetData(data, _this);
    },
    emailLogin(data) {
      resetData(data, _this);
    },
    phones(data) {
      resetData(data, _this);
    },
    birthday(data) {
      const tstOk = data.value.length === 10;
      _this.$set(data, 'isOk', tstOk);
    },
  };

  const onChange = {
    cpf(data) {
      if (_.trim(data.value).length === 0) return;
      if (data.isOk === false && data.alert === undefined) {
        _this.$set(data, 'alert', {
          message: 'CPF inválido',
          type: 'error',
        });
      }
    },
    async emails(data) {
      const email = _.trim(data.value);
      _this.$set(data, 'value', email);
      resetData(data, _this);

      if (email.length === 0) return;

      const emailsArray = _this.formData.emails;
      const hasMutipleEntries = multipleEntriesAnalyzer(
        data,
        emailsArray,
        _this
      );
      if (hasMutipleEntries) return;

      const alreadySetted = hasAlreadySetted('emails', data.value, _this);
      if (alreadySetted) {
        _this.$set(data, 'isOk', true);
        return;
      }

      try {
        await _this.validateEmail({ email });
      } catch (error) {
        _this.$set(data, 'alert', {
          message: 'Email inválido',
          type: 'error',
        });
        return;
      }

      duplicationAnalyzer('email', data, _this);
      _this.$set(data, 'isOk', true);
    },
    async emailLogin(data) {
      const emailLogin = _.trim(data.value);
      _this.$set(data, 'value', emailLogin);
      resetData(data, _this);

      if (emailLogin.length === 0) return;

      const alreadySetted = hasAlreadySetted('emailLogin', data.value, _this);
      if (alreadySetted) {
        _this.$set(data, 'isOk', true);
        return;
      }

      try {
        await _this.validateEmail({ emailLogin });
      } catch (error) {
        _this.$set(data, 'alert', {
          message: 'Email inválido',
          type: 'error',
        });
        return;
      }
      const duplicated = await duplicationAnalyzer('email_login', data, _this);

      if (duplicated) return;

      _this.$set(data, 'isOk', true);
    },
    async phones(data) {
      const phone = _.trim(data.value);
      resetData(data, _this);

      if (!isValidPhone(phone)) {
        _this.$set(data, 'alert', {
          message: 'Celular inválido',
          type: 'error',
        });
        return;
      }

      const phonesArray = _this.formData.phones;
      const hasMutipleEntries = multipleEntriesAnalyzer(
        data,
        phonesArray,
        _this
      );
      if (hasMutipleEntries) return;

      const alreadySetted = hasAlreadySetted('phones', data.value, _this);
      if (alreadySetted) {
        _this.$set(data, 'isOk', true);
        return;
      }

      duplicationAnalyzer('phone', data, _this);
      _this.$set(data, 'isOk', true);
    },
    name(data) {
      const tstOk = /^[a-zA-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇ]+ [a-zA-Z]/g.test(
        data.value
      );
      _this.$set(data, 'isOk', tstOk);
      if (!tstOk) {
        _this.$set(data, 'alert', {
          message: 'Precisa de nome e sobrenome',
          type: 'error',
        });
      } else {
        _this.$set(data, 'alert', undefined);
      }
    },
  };

  return { onChange, onInput };
}

async function duplicationAnalyzer(id, data, _this) {
  const saveValue = data.value;
  const params = { searchBy: id, value: data.value };
  const duplicates = await _this.searchUser(params);

  const valueChanged = data.value != saveValue;
  if (valueChanged) return;

  const duplicated = duplicates.length !== 0;
  if (!duplicated) return;

  const amount = duplicates.length;
  const pluralization = amount === 1 ? '' : 's';

  _this.$set(data, 'alert', {
    message: `${amount} conta${pluralization} encontrada${pluralization}`,
    type: 'warning',
    action: () => {
      _this.tabChange({
        key: 'choose-user',
        params: { searchBy: id, value: saveValue },
      });
    },
    actionMessage: `Ver conta${pluralization}`,
  });
  _this.$set(data, 'duplicated', true);
  return true;
}

function multipleEntriesAnalyzer(data, array, _this) {
  const entries = array.filter(item => item.value === data.value);
  const hasMutipleEntries = entries.length > 1;

  if (hasMutipleEntries) {
    _this.$set(data, 'alert', {
      message: 'Esse dado já foi inserido',
      type: 'warning',
    });
  }

  return hasMutipleEntries;
}

function resetData(data, _this) {
  _this.$set(data, 'isOk', false);
  _this.$set(data, 'alert', undefined);
  _this.$set(data, 'duplicated', false);
}

function hasAlreadySetted(key, value, _this) {
  const field = getField(_this.profile, key);
  const isArray = Array.isArray(field);
  if (isArray) {
    return field.filter(item => item === value).length !== 0;
  } else {
    return value === field;
  }
}
