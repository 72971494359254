<template lang="pug">
  .manual-ticket-authorization
    TabHeader(:title="offerNames.course || '.'" :subTitle="offerNames.university || '.'")
    form.manual-ticket-authorization__body(@submit.prevent="submit")
      .opa__card-body
        .manual-ticket-authorization__title Solicitação de autorização manual

        .manual-ticket-authorization__item
          .manual-ticket-authorization__label
            | Comprovante de pagamento*
          button.manual-ticket-authorization__attachment-button(
            type="button"
            @click="attachmentButtonClicked"
          )
            icon.manual-ticket-authorization__attachment-icon(
              svg-name="i-attach"
              svg-alt="Anexar arquivo"
            )
            .manual-ticket-authorization__attachment-text
              .opa-bold Anexar arquivo
              | {{ attachmentMessage }}

          FileSelector(ref="fileSelector" @filesSelected="onFilesSelected")

        .manual-ticket-authorization__label Observações*
        textarea.manual-ticket-authorization__observations(
          v-model="observations"
          placeholder="Escreva suas observações aqui..."
        )

        Button.manual-ticket-authorization__button(
          type="positive"
          :disabled="hasErrors"
          :text="submitting ? 'Aguarde...' : 'Solicitar'"
          :submit="true"
          :loading="submitting"
        )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import FileSelector from '@/components/common/FileSelector';
import Icon from '@/components/common/Icon';
import Rollbar from '@/common_modules/tools/Rollbar';
import orderAPI from '@/api/orderAPI';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    FileSelector,
    Icon,
    TabHeader,
  },
  data: () => ({
    observations: '',
    submitting: false,
    attachment: null,
  }),
  computed: {
    ...mapGetters('Order', ['order', 'offerNames']),
    ...mapGetters('StoreManager/Order', ['customerId']),
    attachmentMessage() {
      if (this.attachment) return 'arquivo anexado';
      return 'nenhum arquivo anexado';
    },
    hasErrors() {
      if (this.observations === '' || !this.attachment) return true;
      return false;
    },
  },
  methods: {
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),
    async submit() {
      this.submitting = true;

      try {
        await orderAPI.authorizeBoletoManually(
          this.attachment,
          this.observations,
          this.order.result.id,
          this.customerId
        );

        this.tabGoBack();
        this.notifySuccess('Solicitação enviada');
      } catch (error) {
        Rollbar.error('ManualTicketAuthorization | error to submit', { error });

        this.notifyError('A solicitação falhou. Por favor, contate o suporte.');
      } finally {
        this.submitting = false;
      }
    },
    attachmentButtonClicked() {
      this.$refs.fileSelector.openFileSelector();
    },
    onFilesSelected(files) {
      this.attachment = files[0];
    },
  },
};
</script>

<style lang="sass">
.manual-ticket-authorization
  height: 100%
  background-color: $space-gray

.manual-ticket-authorization__body
  padding: $space-st

.manual-ticket-authorization__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.manual-ticket-authorization__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st

.manual-ticket-authorization__label
  margin-top: $space-m

.manual-ticket-authorization__observations
  @extend %opa-input
  margin-top: $space-s
  width: 100%
  height: 89px
  resize: none

.manual-ticket-authorization__item
  margin-bottom: $space-m

.manual-ticket-authorization__attachment-button
  align-items: center
  color: $silver-gray
  display: flex

.manual-ticket-authorization__attachment-icon
  margin-right: $space-xs

.manual-ticket-authorization__attachment-text
  align-items: flex-start
  display: flex
  flex-direction: column
</style>
