<template lang="pug">
.opa-icon__container(tabindex="0" role="button" ref="voucherActions")
    Popover.voucher-actions-popover(openOn="focus"
      :mirrorEl="()=> $refs.voucherActions"
      :toggleableFocus="true"
    )
      Icon.opa-icon.voucher-actions-icon(svgName="i-more-vertical" ref="more" v-if="showActionsIcon")
      template(slot="popover")
        Tooltip(vposition="down" hposition="right")
          TooltipList(:list="actionList")
            template(slot-scope="{ item }")
              a.voucher-actions__menu-link(
                :href="item.link"
                @click="actionClicked(item.key)"
                target="_blank"
              )
                Icon.voucher-actions__menu-icon(:svgName="item.icon")
                | {{ item.text }}
</template>

<script>
import { mapActions } from 'vuex';
import Badge from '@/components/common/Badge';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';
import TooltipList from '@/components/common/TooltipList';

export default {
  components: {
    Badge,
    Card,
    Icon,
    Popover,
    Tooltip,
    TooltipList,
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actionList() {
      return [
        {
          icon: 'i-money',
          text: 'Reembolsar voucher',
          key: 'refund',
          enabled: this.canRefundVoucher,
        },
        {
          icon: 'i-power-off-solid',
          text: 'Reativar voucher',
          key: 'reactivate',
          enabled: this.canReactivateVoucher,
        },
      ].filter(x => x.enabled);
    },
    voucherState() {
      const { state } = this.voucher;

      return state;
    },
    canRefundVoucher() {
      return this.voucherState !== 'used' && this.voucherState !== 'canceled';
    },
    canReactivateVoucher() {
      return this.voucherState === 'expired';
    },
    showActionsIcon() {
      return this.canRefundVoucher || this.canReactivateVoucher;
    },
    orderId() {
      return this.voucher.order && this.voucher.order.id;
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    actionClicked(key) {
      switch (key) {
        case 'refund':
          return this.tabChange({
            key: 'refund-request',
            params: {
              revoucher: true,
              orderId: this.orderId,
              cancelVoucherCode: this.voucher.code,
            },
          });

        case 'reactivate':
          return this.tabChange({
            key: 'reactivate-voucher',
            params: {
              voucher: this.voucher,
            },
          });

        default:
          return null;
      }
    },
  },
};
</script>

<style lang="sass">
.voucher-actions
  display: flex
  padding: 0 $space-m
  justify-content: space-between

.voucher-actions__btn
  margin-bottom: $space-xxs

.voucher-actions__btn-inner
  display: flex
  align-items: center

  & > span
    margin-left: $space-xs

.voucher-actions__icon
  +square(15px)

.voucher-actions-more-popover
  display: flex

.voucher-actions__menu-link
  color: $silver-gray !important
  white-space: nowrap
  display: flex
  align-items: center
  padding: $space-s $space-st
  margin-right: $space-st
  text-decoration: none

.voucher-actions__menu-icon
  margin-right: $space-m
</style>
