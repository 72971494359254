<template lang="pug">
  div
    .badges(
        v-for="(badge, index) in badges"
        v-if="userInfo[badge.prop]"
        v-bind:style="{backgroundColor: color}"
    )
      Icon.badges__icon(
        :svg-name="badge.svgName"
        :svg-alt="badge.svgAlt"
      )
</template>
<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => ({
        hasBo: false,
        hasExchange: false,
        hasRefund: false,
        isBillingUser: false,
        hasComplainHereContact: false,
        hasDivergentOfferContact: false,
        isMonthlyFeeAbtest: false,
        hasVoucher: false,
      }),
    },
    color: {
      type: String,
      default: '#7F858D',
    },
  },
  computed: {
    badges: () => {
      return [
        {
          svgName: 'i-queropago-colorless',
          svgAlt: 'Quero Pago',
          prop: 'isBillingUser',
        },
        {
          svgName: 'i-reclame-aqui-colorless',
          svgAlt: 'Reclame Aqui',
          prop: 'hasComplainHereContact',
        },
        {
          svgName: 'i-file-exclamation',
          svgAlt: 'Oferta Divergente',
          prop: 'hasDivergentOfferContact',
        },
        {
          svgName: 'i-file',
          svgAlt: 'BO',
          prop: 'hasBo',
        },
        {
          svgName: 'i-exchange',
          svgAlt: 'Troca',
          prop: 'hasExchange',
        },
        {
          svgName: 'i-wallet',
          svgAlt: 'Reembolso',
          prop: 'hasRefund',
        },
        {
          svgName: 'i-calendar',
          svgAlt: 'Assinatura',
          prop: 'isMonthlyFeeAbtest',
        },
        {
          svgName: 'i-voucher',
          svgAlt: 'Voucher',
          prop: 'hasVoucher',
        },
      ];
    },
  },
};
</script>
<style lang="sass">
.badges
  border-radius: 50%
  background-color: $oslo-gray
  cursor: pointer
  display: flex
  margin: 0 $space-xs

.badges__icon
  +square(17px)
  margin: $space-xs
  color: $light-black
</style>
