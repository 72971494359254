// prettier-ignore
const followUpStepMap = {
  initiated:                { text: 'Processo Seletivo Pendente',    color: 'warning' },
  failed:                   { text: 'Vestibular reprovado',          color: 'error'   },
  approved:                 { text: 'Vestibular aprovado',           color: 'warning' },
  pending_docs:             { text: 'Documentação pendente',         color: 'warning' },
  partially_submitted_docs: { text: 'Documentação de bolso enviada', color: 'warning' },
  submitted_docs:           { text: 'Documentação enviada',          color: 'warning' },
  rejected_docs:            { text: 'Documentação rejeitada',        color: 'error'   },
  rejected_enrollment:      { text: 'Matrícula rejeitada',           color: 'abort'   },
  awaiting_enrollment:      { text: 'Aguardando Matrícula',          color: 'success' },
  enrolled:                 { text: 'Matriculado',                   color: 'success' },
  dropped_out:              { text: 'Desistente',                    color: 'abort'   },
};

const followUpStepColor = ({ status }) => followUpStepMap[status].color;
const followUpStepTranslate = ({ status }) => followUpStepMap[status].text;
const followUpStepList = Object.keys(followUpStepMap).map(key => {
  return { label: key, name: followUpStepMap[key].text };
});

export { followUpStepColor, followUpStepTranslate, followUpStepList };
