export default {
  setToken(value) {
    localStorage.setItem('token', JSON.stringify(value));
  },
  getToken() {
    return JSON.parse(localStorage.getItem('token'));
  },
  isEmpty() {
    return this.getToken() === null;
  },
};
