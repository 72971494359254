import HttpService from '@/assets/javascripts/services/HttpService.js';

const AdminService = {
  async getPermissions() {
    const { data } = await HttpService.get('api/admin_user/permissions', {});
    return data;
  },
};

export default AdminService;
