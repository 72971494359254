import createStore from './packages/store.js';
import createHandler from './packages/handler.js';
import createInputHandler from './packages/inputHandler.js';
import createAPI from './packages/api.js';

export const createInstance = () => {
  const store = createStore();
  const inputHandler = createInputHandler(store);
  const handler = createHandler(store, inputHandler);
  return createAPI(store, handler);
};

export default createInstance();
