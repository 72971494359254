<template lang="pug">
  div
    template(v-for="(flow, index) in admission.flow")
      Card.flow__item(v-if="showCard(flow)" :showFooterTemplate="showFooter(flow)")
        template(v-slot:header)
          .admission-title.admission-title--small
            | {{ flow | flowKindTranslate }}
        template(v-slot:default)
          .admission-card__content-wrapper
            .admission-card__body-item
              span.admission-text
                | Situação:
              Badge.admission-card__status-badge(:type="flow | flowStatusColor")
                | {{ flow | flowStatusTranslate }}
            .admission-card__body-item.admission-text.admission-text--xs.admission-text--gray(v-if="!!flow.statusDate")
              Icon.admission-card__icon(svg-name="i-schedule")
              span {{ flow.statusDate | momentformat('L - LT') }}
        template(v-slot:footer)
          template(v-if="showFooter(flow)")
            button.opa-card__btn.opa-card__btn--right(type="button" @click="detailsClick(flow)")
              span.opa-card__btn-text
                | Detalhes
              Icon(svg-name="i-chevron-right", svg-class="opa-card__btn-icon")
    FailedApplicationModal(v-if="isApllicationFailed"
      :tips="admission.applications[0].tips"
      ref="modal"
    )
</template>

<style lang="sass" scoped>
.flow__item:not(:last-child)
  margin-bottom: $space-st
</style>

<script>
import { mapActions } from 'vuex';

import Badge from '@/components/common/Badge';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import FailedApplicationModal from '@/opa-modules/admissions/components/FailedApplicationModal';

import {
  flowKindTranslate,
  flowStatusColor,
  flowStatusTranslate,
} from '@/assets/javascripts/data/admissions/flow';

export default {
  components: {
    Badge,
    Card,
    Icon,
    FailedApplicationModal,
  },

  filters: {
    flowKindTranslate,
    flowStatusColor,
    flowStatusTranslate,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isApllicationFailed() {
      if (this.haveApplication) {
        return this.admission.applications[0].status === 'failed';
      }
      return false;
    },
    isEssayApplication() {
      if (this.haveApplication) {
        return this.admission.applications[0].kind === 'ESSAY';
      }
      return false;
    },
    haveApplication() {
      return this.admission.applications.length > 0;
    },
  },

  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    showDocumentList() {
      this.tabChange({
        key: 'admission-documents',
        params: { admission: this.admission },
      });
    },
    showFooter(flow) {
      return (
        (this.isApplicationFlow(flow) &&
          this.isApllicationFailed &&
          this.isEssayApplication) ||
        this.isDocumentationFlow(flow)
      );
    },
    showCard(flow) {
      return flow.status != 'unavailable';
    },
    isApplicationFlow(flow) {
      return flow.kind === 'APPLICATION';
    },
    isDocumentationFlow(flow) {
      return flow.kind === 'DOCUMENTATION';
    },
    detailsClick(flow) {
      if (this.isApplicationFlow(flow)) {
        this.openModal();
      } else if (this.isDocumentationFlow(flow)) {
        this.showDocumentList();
      }
    },
    openModal() {
      this.$refs.modal.show();
    },
  },
};
</script>
