<template lang="pug">
  Card.extra-info-card
    template(slot="header")
      .extra-info-card__header
        span Fique atento!
        CopyIcon(:clipboard-text="clipboardExtraInfoText")
    .extra-info-card__body
      template(v-if="extraInfo.benefits")
        .extra-info-card__details-title Benefícios adicionais
        .extra-info-card__details-body(v-html="extraInfo.benefits")
      template(v-if="extraInfo.warnings")
        .extra-info-card__details-title Avisos importantes
        .extra-info-card__details-body(v-html="extraInfo.warnings")
</template>

<script>
import Card from '@/components/common/Card';
import WhatsappFormatter from '@/assets/javascripts/formatters/WhatsappFormatter';
import CopyIcon from '@/components/Profile/Scholarship/CopyIcon';

export default {
  components: {
    Card,
    CopyIcon,
  },
  props: {
    extraInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    clipboardExtraInfoText() {
      const benefits = WhatsappFormatter.htmlToWhats(this.extraInfo.benefits);
      const warnings = WhatsappFormatter.htmlToWhats(this.extraInfo.warnings);

      const extraInfoText = [];
      if (benefits) {
        extraInfoText.push('*Benefícios adicionais*');
        extraInfoText.push(benefits);
        extraInfoText.push('\n\n');
      }

      if (warnings) {
        extraInfoText.push('*Avisos importantes*');
        extraInfoText.push(warnings);
      }

      return extraInfoText.join('');
    },
  },
};
</script>

<style lang="sass" scoped>
.extra-info-card
  margin-bottom: $space-st
  color: $silver

.extra-info-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.extra-info-card__body
  color: $silver-gray
  padding: $space-s 0

.extra-info-card__details-title
  font-size: rem(18)
  font-weight: bold
  margin: $space-s 0

.extra-info-card__details-body
  margin-bottom: $space-st

  &::v-deep
    ul
      list-style: none
    a
      word-break: break-word
</style>
