import OfferSearch from '@/models/OfferSearch';
import OfferSearchAmplitude from '@/store/OfferSearch/OfferSearchAmplitude';
import carry from '@/assets/javascripts/modules/carry';
import OfferSearchMetrics from '@/common_modules/OfferSearchMetrics';
import OfferSearchConverter from '@/assets/javascripts/services/OfferSearchConverter';

const normalizeOfferList = list =>
  list.reduce(
    (acc, item) => {
      const { offer_id: id } = item;
      acc.offers[id] = item;
      acc.offerList.push(id);
      return acc;
    },
    { offerList: [], offers: {} }
  );

export const state = () => ({
  filterOptions: { value: null, promise: new promisePlus() },
  orderByOptions: null,
  currentSelectedParams: null,
  offers: {},
  offerList: [],
  baseUserInfo: {
    id: null,
    location: {},
    hasLogin: false,
  },
  ui: {
    searchVisible: false,
  },
});

export const mutations = {
  setSearchVisible(state, value) {
    state.ui.searchVisible = value;
  },
  setFilterOptions(state, { value, loading }) {
    if (loading) {
      state.filterOptions.loading = true;
    }
    if (value) {
      state.filterOptions.loading = false;
      state.filterOptions.promise.resolve(value);
      state.filterOptions.value = value;
    }
  },
  setOrderByOptions(state, value) {
    state.orderByOptions = value;
  },
  setCurrentSelectedParams(state, value) {
    state.currentSelectedParams = value;
  },
  updateCurrentParams(state, selectedParams) {
    const newParams = {
      ...state.currentSelectedParams,
      ...selectedParams,
    };
    state.currentSelectedParams = newParams;
  },
  setOffers(state, value) {
    state.offers = value;
  },
  setOfferList(state, value) {
    state.offerList = value;
  },
  setBaseUserInfo(state, value) {
    state.baseUserInfo = value;
  },
  clearBaseUserInfo(state) {
    state.baseUserInfo = {};
  },
};

export const actions = {
  showSearchClicked({ dispatch, getters }, baseUserInfo) {
    if (getters.searchVisible) {
      dispatch('hideSearch');
    } else {
      dispatch('showSearch', baseUserInfo);
    }
  },
  hideSearchClicked({ dispatch }) {
    dispatch('hideSearch');
  },
  changedSelectedParams({ dispatch, state }, selectedParams) {
    const oldParams = state.currentSelectedParams;
    const newParams = {
      ...state.currentSelectedParams,
      ...selectedParams,
    };

    OfferSearchAmplitude.paramsUpdated(newParams, oldParams);
    dispatch('doSearch', selectedParams);
  },
  offerSearchHeaderMounted({ dispatch, commit }, selectedParams) {
    commit('updateCurrentParams', selectedParams);
    dispatch('fetchFilterOptions');
  },
  onChatSelectedChange({ dispatch }) {
    dispatch('resetState');
  },
  offerSearchOrderByMounted({ dispatch, commit }, selectedParams) {
    commit('updateCurrentParams', selectedParams);
    dispatch('fetchOrderByOptions');
  },
  offerClicked({ dispatch, state }, { uuid }) {
    const baseUserId = state.baseUserInfo.id;
    const hasLogin = state.baseUserInfo.hasLogin;

    dispatch(
      'RightPanel/tabChange',
      {
        key: 'scholarship',
        params: { uuid, baseUserId, hasLogin },
      },
      { root: true }
    );
    OfferSearchMetrics.onClickOffer();
  },
  pageEnter({ dispatch }) {
    dispatch('resetState');
    OfferSearchMetrics.onEnter('offerSearchPage');
    dispatch('StoreManager/OfferSearch/pageEnter', null, { root: true });
  },
  pageLeave({ dispatch }) {
    dispatch('resetState');
    OfferSearchMetrics.onLeave();
  },

  showSearch({ commit }, baseUserInfo) {
    commit('setBaseUserInfo', baseUserInfo);
    commit('setSearchVisible', true);
    OfferSearchMetrics.onEnter('offerSearchForUserQueroWhats');
  },
  hideSearch({ commit }) {
    commit('clearBaseUserInfo');
    commit('setSearchVisible', false);
    OfferSearchMetrics.onLeave();
  },
  async doSearch({ state, commit, rootGetters }, selectedParams) {
    commit('updateCurrentParams', selectedParams);
    const searchParams = OfferSearchConverter.selectedParamsToSearchParams(
      state.currentSelectedParams
    );
    const currentTenant = rootGetters['Admin/getCurrentTenant'];
    const { result, blocked } = await carry(
      OfferSearch.search({
        ...searchParams,
        tenantCode: currentTenant.code,
        cacheData: state.currentSelectedParams,
        baseUserId: state.baseUserInfo.id,
      }),
      'offerSearch'
    );
    if (blocked) return;

    const { offers, offerList } = normalizeOfferList(result);
    commit('setOffers', offers);
    commit('setOfferList', offerList);
  },
  fetchFilterOptions({ state, commit }) {
    if (state.filterOptions.loading !== undefined) return;

    commit('setFilterOptions', { loading: true });
    OfferSearch.getFilterOptions().then(filters => {
      commit('setFilterOptions', { value: filters });
    });
  },
  fetchOrderByOptions({ state, commit }) {
    if (state.orderByOptions) return;
    OfferSearch.getOrderByOptions().then(options => {
      commit('setOrderByOptions', options);
    });
  },
  resetState({ commit }) {
    commit('setCurrentSelectedParams', {});
    commit('setSearchVisible', false);
    commit('setBaseUserInfo', { id: null, location: {} });
  },
};

export const getters = {
  searchVisible: state => state.ui.searchVisible,
  populatedOfferList: state => state.offerList.map(id => state.offers[id]),
  filterOptions: state => state.filterOptions,
  orderByOptions: state => state.orderByOptions,
  currentSelectedParams: state => state.currentSelectedParams,
  getAutocompleteFor: () => OfferSearch.searchFor,
  suppliedLocation: state => state.baseUserInfo.location,
  baseUserId: state => state.baseUserInfo.id,
  pageMode: (x, y, z, rootGetters) =>
    rootGetters['StoreManager/OfferSearch/pageMode'],
};

function promisePlus() {
  let promise;
  let resolve;
  promise = new Promise(r => (resolve = r));
  promise.resolve = resolve;
  return promise;
}
