const docKindMap = {
  CNH: 'CNH',
  CNH_DO_GUARDIAO: 'CNH do guardião',
  RG: 'RG',
  CPF: 'CPF',
  RG_DO_GUARDIAO: 'RG do guardião',
  CPF_DO_GUARDIAO: 'CPF do guardião',
  COMPROVANTE_DE_RESIDENCIA: 'Comprovante de Residência',
  COMPROVANTE_DE_RESIDENCIA_DO_GUARDIAO:
    'Comprovante de Residência do guardião',
  BOLETIM_DO_ENEM: 'Boletim do Enem',
  CERTIFICADO_DE_RESERVISTA: 'Certificado de Alistamento Militar',
  COMPROVANTE_DE_VOTO: 'Comprovante de Voto',
  DIPLOMA: 'Diploma',
  HISTORICO_ESCOLAR: 'Histórico Escolar',
  TITULO_DE_ELEITOR: 'Título de Eleitor',
  FOTO_3X4: 'Foto 3x4',
  CERTIDAO_DE_NASCIMENTO_OU_CASAMENTO: 'Certidão de Nascimento ou Casamento',
  EXAME_DE_SANGUE: 'Exame de Sangue',
  CERTIFICADO_DE_CONCLUSAO_DO_ENSINO_MEDIO:
    'Certificado de Conclusão do Ensino Médio',
  SELFIE_COM_DOCUMENTO: 'Selfie com seu documento',
};

// prettier-ignore
const docStatusMap = {
  accepted_by_qb:   { text: 'Validado QB', color: 'success' },
  accepted:         { text: 'Aceito',      color: 'success' },
  pending:          { text: 'Pendente',    color: 'warning' },
  rejected:         { text: 'Rejeitado',   color: 'error'   },
  review_requested: { text: 'Enviado',     color: 'warning' },
  sent:             { text: 'Enviado',     color: 'warning' },
  submitted_docs:   { text: 'Em análise',  color: 'warning' },
};

const docKindTranslate = ({ kind }) => docKindMap[kind];
const docStatusColor = ({ status }) => docStatusMap[status].color;
const docStatusTranslate = ({ status }) => docStatusMap[status].text;

export { docKindTranslate, docStatusColor, docStatusTranslate };
