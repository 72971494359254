import WhatsappService from '@/assets/javascripts/services/WhatsappService';

const DEFAULT_WHATSAPP_HOST_KEY = 'querobolsa_wpp';
const DEFAULT_WHATSAPP_HOST_NAME = 'Não identificado';
let WHATSAPP_HOSTS = null;

const loadWhatsappHosts = async () => {
  WHATSAPP_HOSTS = await WhatsappService.getWhatsappHosts();
};

const getWhatsappHost = host => {
  if (!WHATSAPP_HOSTS) {
    console.warn('WHATSAPP_HOSTS não está inicializado');
    return null;
  }
  return WHATSAPP_HOSTS.find(({ key }) => key == host);
};

const translate = host => {
  const whatsappHost = getWhatsappHost(host);

  if (whatsappHost) {
    return whatsappHost.name;
  } else {
    return DEFAULT_WHATSAPP_HOST_NAME;
  }
};

const normalize = host => {
  const whatsappHost = getWhatsappHost(host);

  if (whatsappHost) {
    return host;
  } else {
    return DEFAULT_WHATSAPP_HOST_KEY;
  }
};

export {
  translate,
  normalize,
  loadWhatsappHosts,
  WHATSAPP_HOSTS as whatsappHosts,
};
