import axios from 'axios';
import Rollbar from '@/common_modules/tools/Rollbar';

class HttpService {
  async get(url, params) {
    try {
      return await axios.get(url, { params });
    } catch (error) {
      this._handleError(error, url, params);
    }
  }

  async post(url, params) {
    try {
      return await axios.post(url, params);
    } catch (error) {
      this._handleError(error, url, params);
    }
  }

  async patch(url, params) {
    try {
      return await axios.patch(url, { params });
    } catch (error) {
      this._handleError(error, url, params);
    }
  }

  async put(url, params) {
    try {
      return await axios.put(url, params);
    } catch (error) {
      this._handleError(error, url, params);
    }
  }

  _handleError(error, url, params) {
    if (!error.response) {
      this._reportNoServerReplyToRollbar({ error, url, params });
    } else if (!this._errorIsFromUser(error)) {
      this._reportToRollbar(error);
    }

    throw error;
  }

  _errorIsFromUser({ response: { status } }) {
    return status > 400 && status < 500;
  }

  _reportToRollbar(error) {
    const {
      config: { url, params },
      response: { data, status, statusText },
    } = error;

    const rollbarPayload = {
      request: { url, params },
      response: { data, status, statusText },
    };

    Rollbar.error('Http request failed', rollbarPayload);
  }

  _reportNoServerReplyToRollbar(paramsError) {
    Rollbar.error('Http request failed | no response from server', paramsError);
  }
}

export default new HttpService();
