<template lang="pug">
  .follow-up
    span.follow-up__dot(:style="{ backgroundColor: followUpTranslated.color }")
    span.follow-up__root {{ followUpTranslated.root }}:
    span.follow-up__step {{ followUpTranslated.step }}
</template>

<script>
import { followUpTranslate } from '@/assets/javascripts/data/followUp.js';

export default {
  props: {
    followUp: {
      type: Object,
      required: true,
    },
  },
  computed: {
    followUpTranslated() {
      return followUpTranslate[this.followUp.step];
    },
  },
};
</script>

<style lang="sass">
.follow-up
  margin-top: $space-s
  font-size: rem(13)
  letter-spacing: 0.2px
  +flex-centered

.follow-up__dot
  +circle(7px)
  display: inline-block
  margin-right: $space-xs

.follow-up__root
  margin-right: $space-xs

.follow-up__step
  font-weight: bold
</style>
