<template lang="pug">
  .checkmark(:class="checkMarkClass")
    .checkmark__middle(:class="checkMarkMiddleClass")
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'checkbox',
      validator: value => ['checkbox', 'radio'].includes(value),
    },
  },
  computed: {
    checkMarkClass() {
      return { 'checkmark--radio': this.type === 'radio' };
    },
    checkMarkMiddleClass() {
      return { 'checkmark__middle--checked': this.value };
    },
  },
};
</script>

<style lang="sass" scoped>
.checkmark
  +square($space-m)
  margin-right: $space-s
  border: 1px solid grey
  border-radius: 2px
  padding: 2px

.checkmark--radio
  border-radius: 100%
  & .checkmark__middle
    border-radius: 100%

.checkmark__middle
  background-color: $primary-success
  height: 100%
  border-radius: 2px
  opacity: 0
  transition: 0.2s opacity

.checkmark__middle--checked
  opacity: 1
</style>
