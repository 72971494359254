<template lang="pug">
  .profile-home
    ProfileHead.profile-home__head
    .profile-home__scroll
      .profile-home__body
        Card(v-if="profileLoading")
          .skeleton__card-body
        template(v-else)
          Button.profile-home__associate(
            v-if="hasLogin && canChangeBaseUser && isChoosingProfile"
            :text="associateButtonText"
            :loading="profileSelectLoading"
            @click="profileSelectClick"
          )
          UserInfo
    NavList.profile-home__nav(
      v-if="!profileLoading"
      :list="navMenuList"
      @navclick="navClicked"
      v-keycut:m="()=>navClicked({ key: 'orders' })"
    )
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Card from '@/components/common/Card';
import ProfileHead from '@/components/Profile/ProfileHead';
import NavList from '@/components/Profile/NavList';
import UserInfo from '@/components/Profile/UserInfo';
import Button from '@/components/common/Button';

export default {
  components: {
    ProfileHead,
    Card,
    NavList,
    UserInfo,
    Button,
  },
  data() {
    return {
      profileSelectLoading: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profileLoading', 'profile']),
    ...mapGetters('Profile', [
      'canChangeBaseUser',
      'isChoosingProfile',
      'hasLogin',
    ]),
    ...mapGetters('Permissions', ['isQueroPagoAttendant']),
    navMenuList() {
      return [
        {
          title: 'Cobranças',
          key: 'financialData',
          disabled: !this.profile.isBillingUser || !this.isQueroPagoAttendant,
        },
        {
          title: 'Matrículas',
          key: 'orders',
          titleAttribute: '(\\+M)',
        },
      ];
    },
    associateButtonText() {
      return this.profileSelectLoading
        ? 'Processando...'
        : 'Selecionar usuário';
    },
  },
  methods: {
    ...mapActions('Profile', ['profileSelect', 'ordersClicked']),
    ...mapActions('Queropago', ['billingsTabClicked']),
    ...mapActions('StoreManager', ['notify']),
    navClicked({ key }) {
      if (key === 'orders') {
        this.ordersClicked();
      } else if (key === 'financialData' && this.isQueroPagoAttendant) {
        this.billingsTabClicked();
      }
    },
    async profileSelectClick() {
      this.profileSelectLoading = true;

      await this.profileSelect();

      this.notify({
        message: 'Usuário selecionado com sucesso.',
        type: 'success',
      });

      this.profileSelectLoading = false;
    },
  },
};
</script>
