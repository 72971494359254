<template lang="pug">
  button.show-more(type="button" @click="toggleShowMore")
    .show-more__container(v-if="showMore" key="less")
      span Ver menos dados
      Icon.show-more__icon.show-more__icon--more(svg-name="i-chevron-right")
    .show-more__container(v-else key="more")
      span Ver mais dados
      Icon.show-more__icon.show-more__icon--less(svg-name="i-chevron-right")
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showMore() {
      return this.value;
    },
    moreIconClass() {
      return 'show-more__icon--' + (this.showMore ? 'more' : 'less');
    },
  },
  methods: {
    toggleShowMore() {
      this.$el.blur();
      this.$emit('input', !this.showMore);
    },
  },
};
</script>
