import * as WhatsappUsername from '@/assets/javascripts/data/whatsapp/whatsappUsername';
import Rollbar from '@/common_modules/tools/Rollbar';

export default class ConversationInfoModel {
  static formatPendingConversations(conversations) {
    return conversations.map(formatConversation);
  }

  static hasUnresolvedConversation(conversations) {
    const unresolvedConversations = conversations.filter(
      ({ resolved }) => !resolved
    );

    return unresolvedConversations.length !== 0;
  }

  static updateByMessage(conversationInfo, message) {
    const index = conversationInfo.findIndex(
      ({ key }) => message.whatsapp_username === key
    );

    if (index === -1) return conversationInfo;

    const conversation = conversationInfo[index];
    const newConversation = updateConversationByMessage(conversation, message);
    const newConversationInfo = [...conversationInfo];
    newConversationInfo.splice(index, 1, newConversation);

    return newConversationInfo;
  }
}

function formatConversation(conversation) {
  const title = formatTitle(conversation);
  const omniContact = { ...conversation, title };
  delete omniContact.resolved;

  return omniContact;
}

function formatTitle({ key, type }) {
  switch (type) {
    case 'whatsapp':
      return WhatsappUsername.translate(key);
    default:
      Rollbar.error('ConversationInfoModel | formatTitle', {
        error: `formatTitle type not mapped: ${type}`,
      });
      return '';
  }
}

function updateConversationByMessage(conversation, message) {
  const { base_user_id, created_at } = message;
  const resolved = typeof base_user_id !== 'number';
  const chat = { ...conversation.chat, last_user_message_at: created_at };

  return { ...conversation, resolved, chat };
}
