const rollbarConfigs = {
  production: {
    enabled: true,
  },
  staging: {
    enabled: false,
  },
  development: {
    enabled: false,
  },
  test: {
    enabled: false,
  },
};

const env = process.env.NUXT_ENV_BUILD || process.env.NODE_ENV;
const rollbarConfig = rollbarConfigs[env];
const testEnv = env === 'test';

const initRollbar = () => {
  return {
    configure(id, email) {
      if (!window.Rollbar) return;

      window.Rollbar.configure({
        payload: {
          environment: process.env.NUXT_ENV_BUILD,
          person: {
            id,
            email,
          },
        },
      });
    },
    error(message, params) {
      if (!window.Rollbar) return;

      window.Rollbar.error(message, params);
      console.error(`[ROLLBAR] ${message}`, params);
    },
    info(message, params) {
      if (!window.Rollbar) return;

      window.Rollbar.info(message, params);
      console.error(`[ROLLBAR] ${message}`, params);
    },
  };
};

const emptyRollbarApi = {
  configure() {},
  error(message, params) {
    if (testEnv) return;
    console.error(`[ROLLBAR] ${message}`, params);
  },
  info(message, params) {
    if (testEnv) return;
    console.info(`[ROLLBAR] ${message}`, params);
  },
};

export default rollbarConfig.enabled ? initRollbar() : emptyRollbarApi;
