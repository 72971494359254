<template lang="pug">
  .send-bill-body(ref="form")
    template(v-if="!freePreEnrollment")
      .send-bill-body__title Cobrança

      .send-bill-body__label Qual a forma de pagamento?
      .send-bill-body__checkbox-list
        Checkbox.send-bill-body__checkbox(
          v-if="acceptsBoleto"
          label="Boleto Bancário"
          v-model="paymentMethods.boleto"
        )
        Checkbox.send-bill-body__checkbox(label="Cartão de Crédito" v-model="paymentMethods.creditCard")

      template(v-if="paymentMethods.boleto || paymentMethods.creditCard")
        .send-bill-body__label Enviar cobrança via
        .send-bill-body__checkbox-list
          Checkbox.send-bill-body__checkbox(label="E-mail" v-model="paymentChannels.email")
          Checkbox.send-bill-body__checkbox(label="SMS" v-model="paymentChannels.sms")

        template(v-if="paymentChannels.email")
          .send-bill-body__title Via e-mail
          .send-bill-body__label(for="email") Informe os e-mails
          InputList.send-bill-body__input-list(v-model="formData.email")
            template(slot-scope="{ item }")
              input.send-bill-body__input(
                type="text"
                name="email"
                id="email"
                v-model="item.value"
                placeholder="nome@email.com.br"
                autocomplete="off"
              )
            template(slot="bottom" slot-scope="{ item }")
              Alert.send-bill-body__alert(
                v-if="item.alert"
                :message="item.alert.message"
                :type="item.alert.type"
                :dismissable="false"
              )

        template(v-if="paymentChannels.sms")
          .send-bill-body__title Via SMS
          .send-bill-body__label(for="sms") Informe o(s) número(s)
          InputList.send-bill-body__input-list(v-model="formData.sms")
            template(slot-scope="{ item }")
              Cleave.send-bill-body__input(
                type="text"
                name="sms"
                id="sms"
                v-model="item.value"
                placeholder="(00) 00000-0000"
                autocomplete="off"
                :options="phoneMaskOptions()"
                :raw="false"
              )
            template(slot="bottom" slot-scope="{ item }")
              Alert.send-bill-body__alert(
                v-if="item.alert"
                :message="item.alert.message"
                :type="item.alert.type"
                :dismissable="false"
              )
</template>

<script>
import Alert from '@/components/common/Alert';
import InputList from '@/components/common/InputList';
import formLib, {
  areFieldsOk,
  phoneMaskOptions,
  isValidPhone,
  cleanData,
} from '@/common_modules/formLib';
import Checkbox from '@/components/common/Checkbox';
import { piper } from '@/assets/javascripts/modules/utils';

import Cleave from 'vue-cleave-component';
import { mapActions, mapGetters } from 'vuex';
import { trim, mapKeys, pick } from 'lodash';

export default {
  components: {
    Alert,
    InputList,
    Checkbox,
    Cleave,
  },
  props: {
    acceptsBoleto: {
      type: Boolean,
      required: true,
    },
    freePreEnrollment: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    paymentChannels: {
      email: false,
      sms: false,
    },
    paymentMethods: {
      boleto: false,
      creditCard: false,
    },
    formData: {
      email: [{ value: null }],
      sms: [{ value: null }],
    },
  }),
  computed: {
    ...mapGetters('Order', ['userEmails', 'userPhones']),
    value() {
      const choosedChannelsArray = getArrayWithTruthyKeys(this.paymentChannels);
      const choosedPaymentsMethodsArray = getArrayWithTruthyKeys(
        this.paymentMethods
      );

      const fiedsToCheck = choosedChannelsArray.map(key => ({
        key,
        required: true,
      }));

      const formCorrect = areFieldsOk(this.formData, fiedsToCheck);
      const hasChannel = choosedChannelsArray.length > 0;
      const hasMethod = choosedPaymentsMethodsArray.length > 0;

      return {
        hasErrors: !(formCorrect && hasChannel && hasMethod),
        blank: !hasMethod,
      };
    },
  },
  watch: {
    value: {
      handler() {
        this.updateParent();
      },
      deep: true,
    },
  },
  mounted() {
    formLib(
      this.$el,
      () => this.formData,
      getFormEventsHandler(this.$set, this.validateEmail)
    );
    this.populateForm();
    this.updateParent();
  },
  methods: {
    ...mapActions('Order', ['validateEmail', 'submitSendBillClicked']),
    sendBill() {
      if (this.value.hasErrors) return;

      const payload = formatSendBilPayload(
        this.formData,
        this.paymentChannels,
        this.paymentMethods
      );

      return this.submitSendBillClicked(payload);
    },
    updateParent() {
      this.$emit('input', this.value);
    },
    phoneMaskOptions: phoneMaskOptions,
    populateForm() {
      this.formData.email = [...this.userEmails, null].map(value => ({
        value,
      }));
      this.formData.sms = [...this.userPhones, null].map(value => ({
        value,
      }));
    },
  },
};

function getArrayWithTruthyKeys(obj) {
  return Object.keys(obj).filter(key => obj[key]);
}

function formatSendBilPayload(formData, paymentChannels, paymentMethods) {
  const choosedChannels = getArrayWithTruthyKeys(paymentChannels);
  const choosedPaymentMethods = getArrayWithTruthyKeys(paymentMethods);
  const filteredChannels = piper(
    formData,
    [cleanData],
    [pick, choosedChannels],
    [mapKeys, (x, key) => `${key}s`]
  );

  return {
    paymentMethods: choosedPaymentMethods,
    channels: choosedChannels,
    ...filteredChannels,
  };
}

function getFormEventsHandler(set, validateEmail) {
  function resetData(data) {
    set(data, 'isOk', false);
    set(data, 'alert', undefined);
  }

  function validatePhone(data) {
    const phone = trim(data.value);
    set(data, 'isOk', false);
    set(data, 'alert', undefined);

    if (!phone) return;

    if (!isValidPhone(phone)) {
      set(data, 'alert', {
        message: 'Celular inválido',
        type: 'error',
      });
      return;
    }

    set(data, 'isOk', true);
  }

  return {
    onInput: {
      email: resetData,
      sms: resetData,
    },
    onChange: {
      async email(data) {
        const email = trim(data.value);
        set(data, 'value', email);
        set(data, 'isOk', false);
        set(data, 'alert', undefined);

        if (!email) return;

        try {
          await validateEmail({ email });
        } catch (error) {
          set(data, 'alert', {
            message: 'Email inválido',
            type: 'error',
          });
          return;
        }

        set(data, 'isOk', true);
      },
      sms: validatePhone,
    },
  };
}
</script>

<style lang="sass">
.send-bill-body__title
  font-size: rem(18)
  font-weight: bold
  margin-bottom: $space-s

.send-bill-body__label
  margin-bottom: $space-s

.send-bill-body__input
  @extend %opa-input
  flex-grow: 1
  margin-bottom: $space-s

.send-bill-body__input-list
  margin-bottom: $space-m

.send-bill-body__input:disabled
  cursor: not-allowed
  opacity: 0.3

.send-bill-body__input::placeholder
  color: $chrome-gray

.send-bill-body__alert
  width: 100%
  margin-bottom: $space-s

.send-bill-body__checkbox-list
  display: flex
  flex-wrap: wrap
  margin-bottom: $space-xs

.send-bill-body__checkbox
  margin-right: $space-m
  white-space: nowrap
</style>
