import { isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';

export { userSearchParams, userSearchParamsForBaseUserId };

function userSearchParams(value) {
  const searchBy = getSearchKey(value);
  return { searchBy, value, minimal: false };
}

function userSearchParamsForBaseUserId(baseUserId) {
  return { searchBy: 'base_user_id', value: baseUserId, minimal: false };
}

function getSearchKey(value) {
  if (isEmail(value)) return 'email';
  if (isName(value)) return 'name';
  if (isCPF(value)) return 'cpf';
  return 'phone';
}

function isEmail(value) {
  return isValidEmail(value);
}

function isName(value) {
  return /[a-zA-Z]/.test(value);
}

function isCPF(value) {
  return isValidCPF(value);
}
