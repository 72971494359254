// This middleware is responsible for
// reading any existing token query parameter
// and set it to current token at localStorage
// and redirect to "home"

export default ({ store, route }) => {
  const { token, adminId } = route.query;

  if (token) {
    store.dispatch('Admin/updateToken', { token });
    store.dispatch('Admin/updateId', { adminId });
    window.location.href = '/';
    return;
  }
};
