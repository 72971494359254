const firstSemesterCurrentYearMonths = [0, 1, 2];
const firstSemesterNextYearMonths = [9, 10, 11];
const secondSemesterMonths = [3, 4, 5, 6, 7, 8];

const SemesterFormatter = {
  getFormattedSemester(currentYear, currentMonth, semester) {
    if (currentMonth >= 0 && currentMonth <= 8)
      return `${currentYear}.${semester}`;

    if (currentMonth >= 9) {
      if (semester == 1) return `${currentYear + 1}.${semester}`;
      else return `${currentYear}.${semester}`;
    }
  },
  getCurrentSemester(currentMonth) {
    return secondSemesterMonths.includes(currentMonth) ? '2' : '1';
  },
  getFormattedCurrentSemester(currentMonth, currentYear) {
    if (firstSemesterCurrentYearMonths.includes(currentMonth))
      return `${currentYear}.1`;

    if (secondSemesterMonths.includes(currentMonth)) return `${currentYear}.2`;

    if (firstSemesterNextYearMonths.includes(currentMonth))
      return `${currentYear + 1}.1`;
  },
};

export default SemesterFormatter;
