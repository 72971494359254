<template lang="pug">
Card.payment-card
  template(slot="header")
    .payment-card__card-header
      div
        .payment-card__card-title Pagamento
        | ID {{ payment.id }}
      div
        .payment-card__card-status
          Badge(:type="paymentStatus.variant").voucher-card__status-badge {{ paymentStatus.label }}

  .payment-card__body
    .payment-card__line Tipo:&nbsp;
      .opa-bold {{ payment.type }}
    .payment-card__line Valor:&nbsp;
      .opa-bold {{ payment.value | brlcurrency }}
    .payment-card__line PSP:&nbsp;
      .opa-bold {{ payment.psp }}
    .payment-card__line(v-if="gatewayResponseJson.psp_reference") PSP Reference:&nbsp;
      .opa-bold {{ gatewayResponseJson.psp_reference }}
    .payment-card__line(v-if="gatewayResponseJson.acquirer_name") Adquirente:&nbsp;
      .opa-bold {{ gatewayResponseJson.acquirer_name }}
    .payment-card__line(v-if="gatewayResponseJson.acquirer_id") ID no Adquirente:&nbsp;
      .opa-bold {{ gatewayResponseJson.acquirer_id }}
    .payment-card__line(v-if="gatewayResponseJson.paid_at") Pago em:&nbsp;
      .opa-bold {{ gatewayResponseJson.paid_at | momentformat('L') }}
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';

export default {
  components: {
    Badge,
    Card,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paymentStatus() {
      const status = this.payment.status;

      const stateMapper = {
        awaiting_authorization: {
          label: 'Solicitação Iniciada',
          variant: 'blue',
        },
        authorization_request_received: {
          label: 'Aguardando Pagamento',
          variant: 'blue',
        },
        authorized: {
          label: 'Pago',
          variant: 'success',
        },
        rejected: {
          label: 'Rejeitado',
          variant: 'error',
        },
        sent_for_refund: {
          label: 'Reembolso em Andamento',
          variant: 'warning',
        },
        sent_for_cancel_or_refund: {
          label: 'Reembolso Solicitado',
          variant: 'warning',
        },
        refunded: {
          label: 'Reembolsado',
          variant: 'success',
        },
        captured: {
          label: 'Pago',
          variant: 'success',
        },
        manually_refunded: {
          label: 'Reembolsado Manualmente',
          variant: 'success',
        },
        expired: {
          label: 'Expirado',
          variant: 'error',
        },
        canceled: {
          label: 'Cancelado',
          variant: 'error',
        },
        failed: {
          label: 'Falhou',
          variant: 'error',
        },
      };

      return stateMapper[status] || { label: status, variant: 'abort' };
    },
    gatewayResponseJson() {
      return this.payment.gateway_response_json;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-card
  color: $silver
  margin-bottom: $space-st

.payment-card__card-title
  font-size: rem(18)
  font-weight: bold

.payment-card__card-header
  align-items: center
  color: $white
  display: flex
  justify-content: space-between
  position: relative
  width: 100%

.payment-card__body
  color: $silver-gray
  padding: $space-s 0

.payment-card__line
  align-items: center
  display: flex
  margin: $space-s 0
</style>
