export default store => {
  const self = {
    onStartShortcuting(ev) {
      const isInputableEl = self.isInputableEl(ev.target);
      const isCharKey = self.isCharKey(ev);
      if (isInputableEl && isCharKey) {
        store.setProp('inputData', { el: ev.target });
      }
    },
    onStopShortcuting(ev) {
      if (store.inputData && !store.shortcutTriggered) {
        self.insertChar(store.inputData.el, ev.key);
      }
    },
    isInputableEl: ({ tagName, type }) =>
      (tagName === 'INPUT' && type === 'text') || tagName == 'TEXTAREA',
    isCharKey: ev => ev.key.length === 1,
    insertChar(el, char) {
      const message = el.value;
      const pos = el.selectionStart;
      const newMessage = message.substr(0, pos) + char + message.substr(pos);
      el.value = newMessage;
      const newPos = pos + 1;
      el.setSelectionRange(newPos, newPos);
    },
  };
  return self;
};
