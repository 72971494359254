<template lang="pug">
  div
    TabHeader(title="Unificar Usuários")
    .merge-tab__body
      .merge-tab__title
        span Selecione os usuários para unificar
      .merge-tab__description
        span O usuário atual será utilizado como destino das unificações.
      .merge-tab__target
        span {{ `ID: ${profile.id} - ${profile.name || 'Anônimo'}` }}
      .merge-tab__description
        span Selecione os cadastros que deseja unificar a este usuário:
      .merge-tab__warning(v-if="originUsers.length === 0")
        span Usuário não possui cadastros duplicados
      .merge-tab__users
        template(v-for="user in originUsers")
          .merge-tab__users-checkbox
            Checkbox(
              v-model="user.selected"
              :label="`ID: ${user.id} - ${user.name || 'Anônimo'}`"
            )
      Button.merge-tab__btn(
        @click="mergeClicked"
        :text="submitting ? 'Aguarde...' : 'Unificar usuários'"
        :loading="submitting"
        :disabled="!canSubmit"
        type="positive"
      )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';
import Icon from '@/components/common/Icon';
import ProfileAPI from '@/api/profileAPI';
import { mapState, mapActions, mapGetters } from 'vuex';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    TabHeader,
    Checkbox,
    Button,
    Alert,
    Icon,
  },
  data: () => {
    return {
      originUsers: [],
      selectedOriginUser: null,
      selectedDestinationUser: null,
      submitting: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profile', 'relatedProfiles']),
    ...mapState('Admin', { adminId: 'id' }),
    ...mapGetters('UserSearch', ['inputValue']),

    canSubmit() {
      return this.selectedUsers().some(user => user);
    },
  },
  mounted() {
    this.originUsers = this.relatedProfiles
      .filter(({ base_user_id }) => base_user_id !== this.profile.id)
      .map(({ base_user_id: id, name }) => ({ id, name }));
  },
  methods: {
    ...mapActions('RightPanel', ['hidePanel']),
    ...mapActions('StoreManager', ['notify']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('UserSearch', ['searchInputed']),
    ...mapActions('Omni', ['ticketClosed']),

    mergeClicked() {
      this.submitting = true;

      const originUserIds = this.selectedUsers().join(',');
      const targetUserId = this.profile.id;

      ProfileAPI.mergeUser(originUserIds, targetUserId)
        .then(() => {
          this.notifySuccess('Os usuários foram unificados com sucesso!');
          this.hidePanel();
          this.ticketClosed();
          this.searchInputed(this.inputValue);
        })
        .catch(() => {
          this.notifyError(
            'A unificação falhou, entre em contato com a equipe de suporte graduação.'
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    selectedUsers() {
      return this.originUsers
        .filter(({ selected }) => selected)
        .map(({ id }) => id);
    },
  },
};
</script>

<style lang="sass" scoped>
.merge-tab__body
  overflow-y: auto
  padding: $space-m $space-st 0 $space-st

.merge-tab__title
  font-weight: $fw-bold
  font-size: 18px
  display: flex
  justify-content: space-right
  margin-top: $space-l

.merge-tab__description
  font-size: 14px
  margin-top: $space-m

.merge-tab__target
  display: flex
  align-items: center
  justify-content: center
  font-weight: $fw-bold
  margin-top: $space-st
  margin-bottom: $space-st
  color: $silver-gray

.merge-tab__warning
  display: flex
  align-items: center
  justify-content: center
  padding-top: $space-st
  color: $silver-gray

.merge-tab__users
  padding-top: $space-st
  padding-bottom: $space-m

.merge-tab__users-checkbox
  padding: $space-xs 0

.merge-tab__btn
  width: 100%

.checkbox
  font-weight: bold
  line-height: $space-st
</style>
