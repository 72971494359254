const defaultMessage =
  'Um erro inesperado aconteceu e não foi possível completar a operação.';

export default function formatFeedbackMessage(message) {
  if (message.isAxiosError) {
    const responseMessage = message.response.data.message;

    if (responseMessage === 'Server internal error') {
      return defaultMessage;
    }

    return responseMessage || defaultMessage;
  }

  if (typeof message == 'string') {
    return message;
  }

  for (const key of ['message', 'reason', 'error']) {
    const value = message[key];

    if (value) {
      return formatFeedbackMessage(value);
    }
  }

  if (Array.isArray(message)) {
    return message.map(formatFeedbackMessage).join(';<br>');
  }

  return JSON.stringify(message);
}
