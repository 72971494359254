<template lang="pug">
  .audit-request
    TabHeader(:title="'Solicitação de Auditoria'")
    form.audit-request__form(
      v-if="!isLoading"
      @submit.prevent="submit"
    )
      Card.opa__card(
        v-if="hasComissions"
      )
        template(slot="header")
          .opa__card-header Contatos comissionados

        .audit-request__contacts-group
          template(v-for="(contact, index) in audit.comissioned")
            .audit-request__separator(v-if="hasComissionSeparator(index)")
            .audit-request__contacts-item
              span.opa-bold Guia do aluno:
                span.audit-request__title {{ contact.admin_user | adminPrettyName }}
              span.opa-bold Razão: {{ contact.reason }}
              span.opa-bold Tipo de contato: {{ contact.type }}
                Icon(class="spacer-left-s" type="small" svgName="i-whatsapp")
              span.opa-bold Contato:
                span.audit-request__title {{ contact.id }}
              span.audit-request__date
                Icon(class="spacer-right-s" svgName="i-clock")
                | {{ contact.created_at | momentformat('L - LT') }}

      div(v-else)
        Alert.audit-request__alert(
          message="Não há divisões comissionadas nessa mátricula para serem auditadas."
          type="warning"
          :dismissable="false"
        )

      .audit-request__group
        .audit-request__group-item
          p.audit-request__label Motivo *
          Select(
            v-model="selectedReason"
            label-property="name"
            :list="audit.reasons"
            :placeholder="'Escolha o motivo da auditoria'"
          )

        div
          p.audit-request__label Observações *
          textarea.audit-request__input(
            v-model="auditObservations"
            placeholder="Escreva suas observações aqui..."
          )

      Button.opa-btn--stretch.audit-request__button(:disabled="!isSubmitEnabled" text="Solicitar Auditoria" type="positive" @click="requestAudit")
</template>

<script>
import Alert from '@/components/common/Alert';
import auditAPI from '@/api/auditAPI';
import Button from '@/components/common/Button';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import { mapActions, mapGetters } from 'vuex';
import Rollbar from '@/common_modules/tools/Rollbar';
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';

export default {
  components: {
    Alert,
    Button,
    Card,
    Icon,
    Select,
    TabHeader,
  },
  data() {
    return {
      audit: {
        comissioned: [],
        reasons: [],
      },
      selectedReason: null,
      auditObservations: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('Order', ['order']),
    ...mapGetters('StoreManager/Order', ['customerId']),
    hasComissions() {
      return this.audit.comissioned && this.audit.comissioned.length > 0;
    },
    hasReasons() {
      return this.audit.reasons && this.audit.reasons.length > 0;
    },
    isSubmitEnabled() {
      return this.formIsFilled;
    },
    formIsFilled() {
      return this.auditObservations != '' && this.selectedReason;
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetchAuditData();
  },
  methods: {
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),
    async fetchAuditData() {
      try {
        const {
          data: {
            audit_data: { comissioned },
            audit_reasons: auditReasons,
          },
        } = await auditAPI.fetch(this.customerId, this.order.result.id);

        this.audit.comissioned = comissioned;
        this.audit.reasons = Object.keys(auditReasons).map(key => ({
          name: auditReasons[key],
          value: key,
        }));
      } catch (error) {
        const message =
          'Ocorreu um problema ao carregar os dados de auditoria.';
        this.notifyError(message);
        Rollbar.error('AuditRequest | fetchAuditData', { error });
      }
      this.isLoading = false;
    },
    hasComissionSeparator(index) {
      return index > 0;
    },
    async requestAudit() {
      try {
        await auditAPI.request(
          this.order.result.id,
          this.customerId,
          this.auditObservations,
          this.selectedReason.value
        );
      } catch (error) {
        const message =
          'Ocorreu um problema ao efetuar a solicitação da auditoria.';
        this.notifyError(message);
        Rollbar.error('AuditRequest | request error', { error });
        return;
      }
      const message = 'Solicitação de auditoria iniciada.';
      this.notifySuccess(message);
      this.tabGoBack();
    },
  },
};
</script>

<style lang="sass">
.audit-request__alert
  margin-bottom: $space-m

.audit-request__label
  padding-bottom: $space-s

.audit-request
  height: 100%
  overflow: auto

.audit-request__form
  padding: $space-m

.audit-request__title
  margin-left: $space-xs
  font-weight: $fw-bold

.audit-request__contacts-group
  padding-top: $space-m
  color: $silver-gray
  line-height: 170%

.audit-request__contacts-item
  display: flex
  flex-direction: column
  margin-bottom: $space-m

.audit-request__date
  align-items: center
  display: flex

.audit-request__button
  margin-top: $space-m

.audit-request__group
  padding-bottom: $space-xs

.audit-request__group-item
  padding-bottom: $space-m

.audit-request__input
  @extend %opa-input
  height: 10em
  resize: none
  width: 100%

.spacer-left-s
  margin-left: $space-s

.audit-request__separator
  background-color: $star-gray
  height: 2px
  margin-bottom: $space-m
</style>
