import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  fetch({ orderId }) {
    return HttpService.get(`api/refund/operation/${orderId}`);
  },
  create(params) {
    return HttpService.post('api/refund/operation', {
      contact: params.contact,
      order_id: params.orderId,
      observations: params.observations,
      reason: params.reason,
      refund_type: params.refundType,
      reclame_aqui: params.reclameAqui,
      generate_voucher: params.generateVoucher,
      cancel_voucher_code: params.cancelVoucherCode,
    });
  },
  cancel(params) {
    return HttpService.post('api/refund/operation/cancel', {
      order_id: params.orderId,
      customer_id: params.customerId,
    });
  },
};
