import { translate } from '@/assets/javascripts/data/whatsapp/hosts.js';

export default {
  isChatSelectedOpen({ chatSelected }) {
    if (!chatSelected) return false;
    if (chatSelected.id === null) return false;

    return !chatSelected.finalized;
  },
  filteredQueueOptions: (state, getters, rootState, rootGetters) => {
    const currentTenant = rootGetters['Admin/getCurrentTenant'];

    return state.queueOptions.filter(
      ({ whatsapp_host: { tenant } }) => tenant.slug === currentTenant.slug
    );
  },
  filteredWhatsappHosts(state, getters) {
    return getters.filteredQueueOptions.reduce(
      whatsappHostsFromQueuesReducer,
      []
    );
  },
  currentWhatsappUsername({ chatSelected }) {
    return chatSelected?.whatsapp_username;
  },
};

function whatsappHostsFromQueuesReducer(acc, { whatsapp_host }) {
  const accHasHost = acc.find(({ key }) => key === whatsapp_host.key);

  if (accHasHost) return acc;

  const whatsappHostWithName = {
    ...whatsapp_host,
    name: translate(whatsapp_host.key),
  };

  return [...acc, whatsappHostWithName];
}
