const orderStatusMap = {
  initiated: { title: 'Iniciado', color: 'warning' },
  registered: { title: 'Registrado', color: 'warning' },
  almost_paid_request: { title: 'Solicitada Autorização', color: 'warning' },
  missing_payment: { title: 'Pagamento Ausente', color: 'error' },
  almost_paid: { title: 'Autorizado', color: 'warning' },
  gave_up: { title: 'Desistiu', color: 'abort' },
  commited: { title: 'Código de Pagamento Gerado', color: 'warning' },
  completed: { title: 'Concluído', color: 'success' },
  overdue: { title: 'Vencido', color: 'error' },
  expired: { title: 'Expirado', color: 'error' },
  refunded_but_coupon_kept: {
    title: 'Reembolsado/Cupom habilitado',
    color: 'success',
  },
  refunded: { title: 'Reembolsado', color: 'success' },
  refund_requested: { title: 'Reembolso Aberto', color: 'warning' },
  refund_pending: { title: 'Reembolso Pendente', color: 'warning' },
  refunded_by_voucher: { title: 'Reembolsado/Voucher', color: 'success' },
  exchanged: { title: 'Trocado', color: 'success' },
  membership_canceled: { title: 'Assinatura Cancelada', color: 'warning' },
  problem: { title: 'Problema', color: 'error' },
  exchange_pending: { title: 'Troca Pendente', color: 'warning' },
  refund_rejected: { title: 'Reembolso Rejeitado', color: 'error' },
  paid: { title: 'Pago', color: 'success' },
  partially_paid: { title: 'Parcialmente pago', color: 'warning' },
  in_enrollment_process: { title: 'Em processo de matrícula', color: 'info' },
};

const getOrderStatusFormatting = orderStatus => orderStatusMap[orderStatus];

export default getOrderStatusFormatting;
