import Amplitude from '@/common_modules/tools/Amplitude';

export default {
  paramsUpdated(...paramsArr) {
    if (!checkParams(paramsArr)) return;

    const [newParams, oldParams] = paramsArr.map(parseParams);
    const diffArr = getDiff(newParams, oldParams);

    diffArr.forEach(diff => {
      Amplitude.logEvent('search_filter_selected', diff);
    });
  },
};

function checkParams(params) {
  const hasEmptyParams = params.findIndex(item => !item) !== -1;
  if (hasEmptyParams) return false;

  const paramsDontMatch =
    Object.keys(params[0]).length != Object.keys(params[1]).length;
  if (paramsDontMatch) return false;

  return true;
}

function parseParams(params) {
  // Maps the whole obj transforming all object values into its .title property
  // { a: {title:'A'}, [{title: 'B'}]} => {a: 'A', ['B'] }
  return Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    const isArray = Array.isArray(value);
    const isObj = !isArray && typeof value === 'object' && !!value;

    if (isArray) {
      acc[key] = value.map(item => item.title);
    } //
    else if (isObj) {
      if (value.title) {
        acc[key] = value.title;
      }
    } //
    else {
      acc[key] = value;
    }
    return acc;
  }, {});
}

function getDiff(newObj, oldObj) {
  // Searches for differences between values in both objs.
  // When value is an array it searches for items that aren't in both arrays.
  // {a: 'A', b:['B']}, {a: 'C', b:['B', 'D']} =>
  // => [{param: 'b', type: 'added', value: 'D'}, {param: 'a', type: 'added', value: 'C'}]
  const newObjKeysArr = Object.keys(newObj);
  const addedValues = newObjKeysArr.reduce((acc, key) => {
    const newValue = newObj[key];
    const oldValue = oldObj[key];
    const isArray = Array.isArray(newValue);

    if (isArray) {
      newValue.forEach(item => {
        const oldValueHasItem = oldValue.findIndex(x => x === item) !== -1;
        if (!oldValueHasItem) {
          acc.push({
            param: key,
            type: 'added',
            value: item,
          });
        }
      });
    } //
    else {
      const valueChanged = newValue !== oldValue;
      if (valueChanged) {
        acc.push({
          param: key,
          type: 'added',
          value: newValue,
        });
      }
    }

    return acc;
  }, []);

  const oldObjKeys = Object.keys(oldObj);
  const removedValues = oldObjKeys.reduce((acc, key) => {
    const newValue = newObj[key];
    const oldValue = oldObj[key];
    const isArray = Array.isArray(oldValue);
    if (isArray) {
      oldValue.forEach(item => {
        const newValueHasItem = newValue.findIndex(x => x === item) !== -1;
        if (!newValueHasItem) {
          acc.push({
            param: key,
            type: 'removed',
            value: item,
          });
        }
      });
    }
    return acc;
  }, []);

  return [...addedValues, ...removedValues];
}
