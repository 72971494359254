<template lang="pug">
  .selector(
    :class="{'selector--up': isBiggerThanWindow}"
    ref="dropbody"
    @mouseleave="diselect"
  )
    Loading(v-if="loading")
    template(v-else)
      .selector__item(
        v-if="!filteredListEmpty"
        v-for="(item, index) in filteredList"
        :key="item.id"
        @mouseenter="hover(index)"
        @mousedown="doSelect"
        :class="{'selector__item--selected': selectedIndex === index}"
      )
        slot(:item="item")
          .selector__text(:title="getItemLabel(item)") {{ getItemLabel(item) }}
</template>

<script>
import { invoke } from 'lodash';
import Loading from '@/components/common/Loading.vue';

export default {
  components: {
    Loading,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    openSelected: {
      type: Number,
      required: false,
      default: undefined,
    },
    searchStr: {
      type: String,
      required: false,
      default: '',
    },
    labelProperty: {
      type: String,
      required: false,
      default: null,
    },
    searchProperty: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: this.openSelected,
      isBiggerThanWindow: false,
    };
  },
  computed: {
    filteredList() {
      return this.list.filter(item => {
        const text = this.getItemSearchableString(item);

        const normalizedText = normalizeText(text);
        const normalizedSearchStr = normalizeText(this.searchStr);
        return normalizedText.indexOf(normalizedSearchStr) > -1;
      });
    },
    filteredListEmpty() {
      return this.filteredList.length === 0;
    },
  },
  watch: {
    filteredList() {
      this.selectedIndex = 0;
    },
  },
  mounted() {
    this.selectedIndex = this.openSelected;
    this.keyDownListener = keyDownListener.bind(this);
    addEventListener('keydown', this.keyDownListener);

    if (!this.loading) {
      this.isBiggerThanWindow = this.getIsBiggerThanWindow();
    }
  },
  destroyed() {
    removeEventListener('keydown', this.keyDownListener);
  },
  methods: {
    hover(index) {
      this.selectedIndex = index;
    },
    diselect() {
      this.selectedIndex = undefined;
    },
    doSelect() {
      const selected = this.filteredList[this.selectedIndex];
      if (!selected) return;
      this.$emit('selected', selected);
    },
    getItemLabel(item) {
      if (!item) {
        return '';
      }

      if (this.labelProperty) {
        return item[this.labelProperty];
      }

      return JSON.stringify(item);
    },
    getItemSearchableString(item) {
      if (!item) {
        return '';
      }

      if (this.searchProperty) {
        return item[this.searchProperty];
      }

      if (this.labelProperty) {
        return item[this.labelProperty];
      }

      return this.joinAllStringProperties(item);
    },
    joinAllStringProperties(item) {
      let text = '';

      for (let key in item) {
        const prop = item[key];

        if (typeof prop == 'string') {
          text = text.concat(' ' + prop);
        }
      }

      return text;
    },
    getIsBiggerThanWindow() {
      const boundingClientRect = this.$refs.dropbody.getBoundingClientRect();
      const margin = 8;
      const distanceFromBotton =
        window.innerHeight - boundingClientRect.bottom - margin;

      return distanceFromBotton <= 0;
    },
  },
};

function keyDownListener(e) {
  let newIndex = undefined;
  const isArrowKey = ['ArrowDown', 'ArrowUp'].includes(e.key);
  const currentIndex = this.selectedIndex;

  if (e.key === 'Escape') {
    this.$emit('close');
    return;
  }

  if (e.key === 'Enter') {
    this.doSelect();
    e.preventDefault();
    return;
  }

  if (isArrowKey) {
    if (currentIndex === undefined) {
      newIndex = 0;
    } else if (e.key === 'ArrowDown') {
      if (currentIndex < this.filteredList.length - 1) {
        newIndex = currentIndex + 1;
      } else {
        newIndex = 0;
      }
    } else if (e.key === 'ArrowUp') {
      if (currentIndex > 0) {
        newIndex = currentIndex - 1;
      } else {
        newIndex = this.filteredList.length - 1;
      }
    } else {
      return;
    }

    invoke(this, `$refs.dropbody.children[${newIndex}].scrollIntoView`, {
      block: 'nearest',
      inline: 'nearest',
    });
    e.preventDefault();
    this.hover(newIndex);
  }
}

function normalizeText(text) {
  if (!text) return '';

  return text
    .trim()
    .toLowerCase()
    .normalize('NFD') // separa à em a e `
    .replace(/[\u0300-\u036f]/g, '') // elimina os sinais
    .replace(/\s/g, '');
}
</script>
