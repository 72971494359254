import { carryDebounce } from '@/assets/javascripts/modules/carry';
import { convertToCamelCase } from '@/assets/javascripts/modules/utils';
import ProfileService from '@/assets/javascripts/services/ProfileService';
import {
  userSearchParams,
  userSearchParamsForBaseUserId,
} from '@/opa-modules/UserSearch/business/userSearchParams';

const SearchStatus = {
  WAITING_INPUT: 'waitingInput',
  LOADING: 'loading',
  LOADED: 'loaded',
};

export const state = () => {
  return {
    visible: false,
    results: [],
    baseUserIdSelected: null,
    isBackgroundCoverVisible: true,
    searchParams: null,
    searchStatus: SearchStatus.WAITING_INPUT,
    inputValue: '',
  };
};

export const mutations = {
  setVisible(state, value) {
    state.visible = value;
  },
  setResults(state, results) {
    state.results = results;
  },
  setBaseUserIdSelected(state, baseUserIdSelected) {
    state.baseUserIdSelected = baseUserIdSelected;
  },
  setIsBackgroundCoverVisible(state, isBackgroundCoverVisible) {
    state.isBackgroundCoverVisible = isBackgroundCoverVisible;
  },
  setSearchParams(state, searchParams) {
    state.searchParams = searchParams;
  },
  setSearchStatus(state, status) {
    state.searchStatus = status;
  },
  setInputValue(state, inputValue) {
    state.inputValue = inputValue;
  },
};

export const getters = {
  showBackgroundCover: state => state.visible && state.isBackgroundCoverVisible,
  isResultsLoaded: state => state.searchStatus === SearchStatus.LOADED,
  isResultsLoading: state => state.searchStatus === SearchStatus.LOADING,
  visible: state => state.visible,
  inputValue: state => state.inputValue,
};

export const actions = {
  toggleSearchClicked({ dispatch, state }) {
    const { visible } = state;
    if (visible) {
      dispatch('closeSearchClicked');
    } else {
      dispatch('showSearchClicked');
    }
  },

  showSearchClicked({ commit, dispatch }) {
    commit('setVisible', true);
    dispatch('StoreManager/UserSearch/openedUserSearch', null, { root: true });
  },

  closeSearchClicked({ commit, dispatch }) {
    commit('setVisible', false);
    commit('setBaseUserIdSelected', null);
    commit('setIsBackgroundCoverVisible', true);
    dispatch('resetSearchInput');
    dispatch('StoreManager/UserSearch/closedUserSearch', null, { root: true });
  },

  resetSearchInput({ commit }) {
    commit('setSearchStatus', SearchStatus.WAITING_INPUT);
    commit('setResults', []);
  },

  async searchInputed({ commit, dispatch, rootGetters }, value) {
    const valueIsEmpty = value === '';
    let searchParams;

    const { result, blocked } = await carryDebounce(
      async () => {
        if (valueIsEmpty) return Promise.resolve([]);

        commit('setSearchStatus', SearchStatus.LOADING);
        commit('setResults', []);

        const currentTenant = rootGetters['Admin/getCurrentTenant'];
        searchParams = userSearchParams(value.trim());

        const {
          data: { users },
        } = await ProfileService.searchUser(searchParams, currentTenant.id);
        return convertToCamelCase(users);
      },
      'userSearch',
      500
    );
    if (blocked) return;

    if (valueIsEmpty) {
      dispatch('resetSearchInput');
      return;
    }

    dispatch('loadedResults', { searchParams, result });
  },

  async resultItemClicked({ commit, dispatch }, { baseUserId }) {
    dispatch(
      'StoreManager/UserSearch/selectedUser',
      {
        baseUserId: baseUserId,
      },
      { root: true }
    );
    commit('setBaseUserIdSelected', baseUserId);
    commit('setIsBackgroundCoverVisible', false);
  },

  registerNewUserClicked({ dispatch, commit }) {
    commit('setIsBackgroundCoverVisible', false);
    dispatch('StoreManager/UserSearch/registerNewUserClicked', null, {
      root: true,
    });
  },

  async userCreated({ dispatch }, { baseUserId }) {
    const searchParams = userSearchParamsForBaseUserId(baseUserId);
    let {
      data: { users },
    } = await ProfileService.searchUser(searchParams);
    users = convertToCamelCase(users);

    dispatch('loadedResults', { searchParams, result: users });
    dispatch('resultItemClicked', { baseUserId: baseUserId });
  },

  async loadedResults({ commit }, { searchParams, result }) {
    commit('setSearchParams', searchParams);
    commit('setSearchStatus', SearchStatus.LOADED);
    commit('setResults', result);
  },

  async updateInputValue({ commit }, inputValue) {
    commit('setInputValue', inputValue);
  },
};
