const undergraduateLevels = Object.freeze([
  'Licenciatura (graduação)',
  'Bacharelado (graduação)',
  'Bacharelado + Licenciatura (graduação)',
  'Tecnólogo (graduação)',
  'Segunda Graduação',
  'Graduação',
]);

const postgraduateLevels = Object.freeze([
  'Pós-graduação',
  'Pós-graduação Lato Sensu',
  'Mestrado',
  'Doutorado',
  'Pós-doutorado',
  'Bootcamp',
  'MBA (pós-graduação)',
  'Especialização (pós-graduação)',
]);

const technicalLevels = Object.freeze(['Técnico']);

const otherCoursesLevels = Object.freeze([
  'Cursos livres e outros',
  'Profissionalizante',
  'Curso Livre',
  'Pré-vestibular',
  'Ensino Fundamental',
  'Ensino Infantil',
  'Ensino Médio',
  'Sequencial',
]);

const levelIsLLL = level =>
  [...postgraduateLevels, ...technicalLevels, ...otherCoursesLevels].includes(
    level
  );

const levelIsPostgraduate = level => postgraduateLevels.includes(level);

export {
  levelIsLLL,
  levelIsPostgraduate,
  otherCoursesLevels,
  postgraduateLevels,
  technicalLevels,
  undergraduateLevels,
};
