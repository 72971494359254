const FollowUpModel = {
  /**
   * Verifica se um follow_up é de Matrícula Direta
   * @param {Object} followUp follow_up associado à order.
   */
  isFreePreEnrollment(followUp) {
    if (!followUp) return false;
    if (!followUp.data) return false;
    if (!followUp.data.free_pre_enrollment) return false;

    return followUp.data.free_pre_enrollment === true;
  },
};

export default FollowUpModel;
