import humps from 'lodash-humps';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';

const humpsSnakeCase = createHumps(snakeCase);

/**
 * Function that behaves like elixir's pipe operator.
 *
 * @description Executes functions in sequence, in which the return from the previous function is the first argument of the next function.
 * @param  initialValue {any} input for the first step.
 * @param steps {...Array<any>} List of functions to be executed in the form `[function, ...args]`
 */

export function piper(initialValue, ...steps) {
  return steps.reduce((acc, item) => {
    const fun = item.shift();
    return fun(acc, ...item);
  }, initialValue);
}

export function waitPromise(time) {
  return new Promise(x => setTimeout(x, time));
}

export const convertToCamelCase = humps;
export const convertToSnakeCase = humpsSnakeCase;

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
