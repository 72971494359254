const SALES = 'sales';
const POST_SALES = 'post-sales';

export const state = () => ({
  currentPath: '',
  itemsData: {
    omni: {
      text: 'Atendimento',
      isClient: true,
      icon: 'i-atendimento',
      kind: SALES,
      route: '/',
      basicTool: true,
    },
    offersearch: {
      text: 'Busca de Curso',
      isClient: true,
      icon: 'i-school',
      kind: SALES,
      route: '/offersearch',
      basicTool: false,
    },
    reports: {
      text: 'Relatórios',
      icon: 'i-money',
      kind: SALES,
      route: '/relatorios',
      basicTool: false,
    },
    refunds: {
      text: 'Operações de Reembolso',
      icon: 'i-wallet',
      kind: POST_SALES,
      route: '/reembolsos',
      basicTool: false,
    },
    bos: {
      text: 'Operações de BO',
      icon: 'i-file',
      kind: POST_SALES,
      route: '/bos',
      basicTool: false,
    },
  },
  items: ['omni', 'offersearch', 'reports', 'refunds', 'bos'],
});

export const mutations = {
  setCurrentPath(state, path) {
    state.currentPath = path;
  },
};

export const actions = {
  setCurrentPath({ commit }, path) {
    if (path === '') {
      path = '/';
    }

    commit('setCurrentPath', path);
  },
};

export const getters = {
  currentPath: state => state.currentPath,
  items: state => state.items.map(name => ({ ...state.itemsData[name], name })),
};
