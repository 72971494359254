<template>
  <div>
    <h1>{{ errorTitle }}</h1>
  </div>
</template>

<script>
export default {
  name: 'WithoutPermission',
  data() {
    return {
      errorTitle: 'Você não possui permissão para acessar essa página!',
    };
  },
};
</script>
