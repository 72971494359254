import OfferAPI from '@/api/offerAPI';
import OrderAPI from '@/api/orderAPI';
import RefundAPI from '@/api/refundAPI';
import ProfileService from '@/assets/javascripts/services/ProfileService.js';
import OrderModel from '@/models/Order';
import { get, omit } from 'lodash';

export const state = () => ({
  baseUserId: null,
  orders: null,
  order: null,
  offer: null,
  followUp: null,
  coupon: null,
  orderOrigin: null,
  offerLoading: true,
  orderLoading: true,
  payments: [],
});

export const mutations = {
  setOrders(state, orders) {
    state.orders = orders;
  },
  setOrderLoading(state) {
    state.order = null;
    state.orderLoading = true;
  },
  setOrder(state, order) {
    state.order = order;
    state.orderLoading = false;
  },
  setOrderOrigin(state, orderOrigin) {
    state.orderOrigin = orderOrigin;
  },
  setOfferLoading(state) {
    state.offer = null;
    state.offerLoading = true;
  },
  setOffer(state, offer) {
    state.offer = offer;
    state.offerLoading = false;
  },
  setFollowUp(state, followUp) {
    state.followUp = followUp;
  },
  setBaseUserId(state, value) {
    state.baseUserId = value;
  },
  setCoupon(state, value) {
    state.coupon = value;
  },
  setPayments(state, payments) {
    state.payments = payments;
  },
};

export const getters = {
  offer: ({ offerLoading, offer }) => ({
    loading: offerLoading,
    result: offer,
  }),
  order: ({ orderLoading, order }) => ({
    loading: orderLoading,
    result: order,
  }),
  coupon: ({ coupon }) => coupon,
  orderOrigin: ({ orderOrigin }) => orderOrigin,
  payments: ({ payments }) => payments,
  followUp: ({ followUp }) => followUp,
  offerNames: ({ offer }) => ({
    course: get(offer, 'course.name', ''),
    university: get(offer, 'course.campus.university.name', ''),
  }),
  userEmails: (x, y, z, rootGetters) =>
    rootGetters['StoreManager/Order/userEmails'],
  userPhones: (x, y, z, rootGetters) =>
    rootGetters['StoreManager/Order/userPhones'],
};

export const actions = {
  async ordersClicked({ commit, dispatch, state }, userId) {
    commit('setBaseUserId', userId);
    await dispatch('fetchOrders', userId);
    const ordersEmpty = state.orders.length == 0;
    if (ordersEmpty) {
      dispatch('showOrders');
      return;
    }
    dispatch('showOrder', state.orders[0]);
  },
  orderClicked({ dispatch }, order) {
    dispatch('showOrder', order);
  },
  showAllOrdersClicked({ dispatch }) {
    dispatch('showOrders', true);
  },
  async startOrderClicked({ dispatch }) {
    const order = await dispatch('startOrder');
    if (!order) return;

    dispatch('StoreManager/Order/startedOrder', null, {
      root: true,
    });

    return order;
  },
  async submitUpdateTicket({ dispatch }, params) {
    await OrderAPI.updateTicket({
      ...params,
    });
    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  async submitRegisterBOCliked({ dispatch, state }, params) {
    await OfferAPI.registerBO({
      ...params,
      offerId: state.offer.offer.id,
      orderId: get(state, 'order.id'),
      baseUserId: state.baseUserId,
    });
    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  async submitSendBillClicked({ dispatch }, params) {
    try {
      await dispatch('sendBill', params);
      dispatch('RightPanel/tabGoBack', null, { root: true });
    } catch (error) {
      dispatch(
        'StoreManager/notify',
        {
          message: 'Ocorreu um erro para enviar a fatura',
          errors: {
            errorTitle: 'submitSendBillClicked | sendBill',
            errorDetails: error,
          },
        },
        { root: true }
      );
    }
  },
  async submitPurchaseConfirmationClicked({ dispatch }, params) {
    try {
      await dispatch('purchaseConfirmation', params);
      dispatch('Notifications/notifySuccess', 'Confirmação enviada', {
        root: true,
      });
    } catch (error) {
      dispatch(
        'StoreManager/notify',
        {
          message: 'Erro ao enviar a confirmação',
          errors: {
            errorTitle:
              'submitPurchaseConfirmationClicked | purchaseConfirmation',
            errorDetails: error,
          },
        },
        { root: true }
      );
      return;
    }
    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  async submitExpiresBoletoClicked({ dispatch }, params) {
    try {
      await dispatch('expiresBoleto', params);
    } catch (error) {
      dispatch(
        'StoreManager/notify',
        {
          message: 'Ocorreu um problema ao expirar o boleto.',
          errors: {
            errorTitle: 'submitExpiresBoletoClicked | expiresBoleto',
            errorDetails: error,
          },
        },
        { root: true }
      );
      return;
    }
    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  registerOrderClicked({ dispatch }) {
    dispatch(
      'RightPanel/tabChange',
      {
        key: 'register-order',
      },
      { root: true }
    );
  },
  getRegisterOrderData({ state, dispatch }) {
    return OrderAPI.getRegisterOrderData({
      offerId: state.offer.offer.id,
      baseUserId: state.baseUserId,
    }).catch(error => {
      dispatch(
        'StoreManager/notify',
        {
          message: error,
          errors: {
            errorTitle: 'Order | getRegisterOrderData',
            errorDetails: error,
          },
        },
        { root: true }
      );
      dispatch('RightPanel/tabGoBack', null, { root: true });
      return {};
    });
  },
  requestRefundInfo({ state }) {
    return RefundAPI.fetch({ orderId: state.order.id });
  },
  async submitRefundRequestClicked({ dispatch, state }, params) {
    await RefundAPI.create({
      ...params,
      orderId: state.order.id,
    });
    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  async submitRefundRequestCancelClicked({ dispatch, state, rootGetters }) {
    const { order } = state;
    const customerId = rootGetters['StoreManager/Order/customerId'];

    await RefundAPI.cancel({
      orderId: order.id,
      customerId: customerId,
    });

    dispatch('RightPanel/tabGoBack', null, { root: true });
  },
  async submitRegisterOrderClicked({ state, dispatch, rootGetters }, params) {
    let order = state.order;
    const currentTenant = rootGetters['Admin/getCurrentTenant'];

    if (!order) {
      order = await dispatch('startOrder');
      if (!order) {
        return null;
      }
    }

    try {
      await OrderAPI.registerOrder({
        orderId: order.id,
        tenantId: currentTenant.id,
        ...params,
      });
    } catch (error) {
      dispatch(
        'StoreManager/notify',
        {
          message: 'Ocorreu um erro ao registrar a Ordem.',
          errors: {
            errorTitle: 'submitRegisterOrderClicked | registerOrder',
            errorDetails: error,
          },
        },
        { root: true }
      );
      dispatch(
        'StoreManager/notify',
        {
          message: error,
        },
        { root: true }
      );
    }

    return order;
  },
  async registerOrderFinished({ dispatch }, order) {
    dispatch('StoreManager/Order/registeredOrder', null, { root: true });

    await dispatch('RightPanel/tabGoBack', null, { root: true });
    await dispatch('RightPanel/tabGoBack', null, { root: true });
    dispatch('showOrder', order);
  },
  // internal
  showOrder({ dispatch, state, rootGetters }, { id: orderId }) {
    const hasLogin = rootGetters['Profile/hasLogin'];

    dispatch(
      'RightPanel/tabChange',
      {
        key: 'scholarship',
        params: {
          orderId,
          baseUserId: state.baseUserId,
          showAllOrdersButton: true,
          hasLogin,
        },
      },
      { root: true }
    );
  },
  showOrders({ dispatch }, replace = false) {
    dispatch(
      'RightPanel/tabChange',
      {
        key: 'order',
        replace,
      },
      { root: true }
    );
  },
  async fetchOrders({ commit, state: { baseUserId } }) {
    commit('setOrders', null);
    const {
      data: { orders },
    } = await OrderAPI.fetchOrders(baseUserId);

    commit('setOrders', orders);
  },
  async fetchOrder({ commit, dispatch, rootGetters }, { orderId }) {
    commit('setOrderLoading');
    commit('setOfferLoading');
    const currentTenant = rootGetters['Admin/getCurrentTenant'];

    try {
      const { data: result } = await OrderAPI.fetchOrderDetails(
        orderId,
        currentTenant.slug
      );

      const order = result.order;
      const orderOrigin = result.order_origin;
      const coupon = result.coupon;
      const payments = result.payments;
      const offer = omit(result, [
        'order',
        'follow_up',
        'order_origin',
        'coupon',
        'payments',
      ]);
      const followUp = result.follow_up;
      commit('setOrder', order);
      commit('setOrderOrigin', orderOrigin);
      commit('setOffer', offer);
      commit('setFollowUp', followUp);
      commit('setCoupon', coupon);
      commit('setPayments', payments);
    } catch (error) {
      dispatch('RightPanel/tabGoBack', null, { root: true });
      dispatch(
        'StoreManager/notify',
        {
          message:
            'Não foi possível carregar os detalhes da Ordem, tente novamente.',
          errors: {
            errorTitle: 'fetchOrder | fetchOrderDetails',
            errorDetails: error,
          },
        },
        { root: true }
      );
    }
  },
  async fetchOffer({ commit, rootGetters }, { uuid, baseUserId }) {
    commit('setBaseUserId', baseUserId);

    commit('setOrder', null);
    commit('setOfferLoading');

    const currentTenant = rootGetters['Admin/getCurrentTenant'];
    const { data } = await OfferAPI.getDetails(
      uuid,
      baseUserId,
      currentTenant.slug
    );
    const offer = data;

    commit('setOffer', {
      ...offer,
    });
  },
  async startOrder({ state, dispatch, commit, rootGetters }) {
    const { id: offerId, preEnrollmentFee } = state.offer.offer;
    const { baseUserId } = state;
    const currentTenant = rootGetters['Admin/getCurrentTenant'];

    let order;

    try {
      order = await OrderAPI.initiateOrder(
        baseUserId,
        offerId,
        preEnrollmentFee.id,
        currentTenant.slug
      );
    } catch (error) {
      dispatch(
        'StoreManager/notify',
        {
          message: 'Não foi possível iniciar a Ordem, tente novamente.',
          errors: {
            errorTitle: 'startOrder | initiateOrder',
            errorDetails: error,
          },
        },
        { root: true }
      );
      return;
    }

    commit('setOrder', order);
    return order;
  },
  async sendBill({ state, rootGetters }, params) {
    const { order, baseUserId } = state;
    const customerId = rootGetters['StoreManager/Order/customerId'];

    const sendBoleto = params.paymentMethods.includes('boleto');

    if (sendBoleto && !OrderModel.hasBoletoPayable(order)) {
      return await OrderAPI.generateBoleto({
        orderId: order.id,
        customerId,
      });
    }

    return OrderAPI.sendBill({
      ...params,
      orderId: order.id,
      customerId,
      baseUserId,
    });
  },
  async expiresBoleto({ state }, { reason }) {
    const { order } = state;

    await OrderAPI.expiresBoleto({
      orderId: order.id,
      reason: reason,
    });
  },
  async purchaseConfirmation({ state, rootGetters }, params) {
    const { order } = state;
    const customerId = rootGetters['StoreManager/Order/customerId'];

    await OrderAPI.sendPurchaseConfirmation({
      email: params,
      orderId: order.id,
      customerId: customerId,
    });
  },
  validateEmail(store, email) {
    return ProfileService.validateEmail(email);
  },
  getRegisterBOOptions({ state }) {
    return OfferAPI.getRegisterBOOptions(state.baseUserId, {
      isOrder: !!get(state.order, 'id'),
    });
  },
};
