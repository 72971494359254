<template lang="pug">
  input.file-selector__input(
    @change="inputChanged"
    ref="fileInput"
    type="file"
    :multiple="multiple"
  )
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    // Opens systems File select dialog
    openFileSelector() {
      this.$refs.fileInput.click();
    },

    async inputChanged({ target }) {
      const files = Array.from(target.files);

      if (files.length == 0) return;

      const filesReadPromises = files.map(file => this.readFile(file));

      const filesReaded = await Promise.all(filesReadPromises);

      this.filesSelected(filesReaded);
    },

    readFile(file) {
      const reader = new FileReader();

      const promise = new Promise(resolve => {
        reader.onload = ({ target }) =>
          resolve({ fileName: file.name, data: target.result });
      });

      reader.readAsDataURL(file);

      return promise;
    },

    filesSelected(files) {
      this.$emit('filesSelected', files);
    },
  },
};
</script>

<style lang="sass">
.file-selector__input
  display: none
</style>
