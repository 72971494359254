<template lang="pug">
  .notifications(v-if="!emptyActiveNotifications")
    .notification(
      v-for="(notification, index) in activeNotifications"
      :key="index"
      :class="`notification--${notification.status}`"
    )

      button.notification__close(type="button" @click="hideNotification(notification)")
        Icon.notification__close__icon(svg-name="i-close" svg-alt="Fechar notificação")

      Icon.notification__icon(v-if="notification.icon" :svg-name="notification.icon")

      .notification__content
        strong.notification__title(v-if="notification.title") {{ notification.title }}
        p.notification__message(v-if="notification.message" v-html="notification.message")
</template>

<script>
import Icon from '@/components/common/Icon';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Icon,
  },

  computed: {
    ...mapGetters('Notifications', [
      'emptyActiveNotifications',
      'activeNotifications',
    ]),
  },

  methods: {
    ...mapActions('Notifications', ['hideNotification']),
  },
};
</script>
