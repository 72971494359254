const flowKindMap = {
  CONTRACT: 'Contrato',
  APPLICATION: 'Vestibular',
  DOCUMENTATION: 'Documentação',
  ENROLLMENT_FEE: 'Taxa de matrícula da faculdade',
};

// prettier-ignore
const flowStatusMap = {
  unavailable:      { text: 'Indisponível', color: 'abort'   },
  generating:       { text: 'Processando',  color: 'warning' },
  initiated:        { text: 'Pendente',     color: 'warning' },
  awaiting:         { text: 'Em análise',   color: 'warning' },
  done:             { text: 'Concluído',    color: 'success' },
  action_requested: { text: 'Rejeitado',    color: 'error'   },
};

const flowKindTranslate = ({ kind }) => flowKindMap[kind];
const flowStatusColor = ({ status }) => flowStatusMap[status].color;
const flowStatusTranslate = ({ status }) => flowStatusMap[status].text;

export { flowKindTranslate, flowStatusColor, flowStatusTranslate };
