<template lang="pug">
.update-ticket
  TabHeader(:title="offerNames.course || '.'" :subTitle="offerNames.university || '.'")
  form.update-ticket__body(@submit.prevent="submit")
    .opa__card-body
      .update-ticket__title Atualizar dados da ordem e do comprovante de pré-matrícula
      .update-ticket__text-gray Deseja copiar os dados do perfil do aluno para a ordem e para o comprovante?
      .update-ticket__text-bold Atenção, essa ação é irreversível.

      Button.update-ticket__button(
        type="positive"
        :text="'Atualizar dados'"
        :submit="true"
      )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Rollbar from '@/common_modules/tools/Rollbar';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    TabHeader,
  },
  computed: mapGetters('Order', ['order', 'offerNames']),
  methods: {
    ...mapActions('Order', ['submitUpdateTicket']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    async submit() {
      try {
        await this.submitUpdateTicket(this.order.result);

        this.notifySuccess('Dados atualizados com sucesso!');
      } catch (error) {
        Rollbar.error('UpdateTicket | error to submit', { error });

        this.notifyError(
          error.response.data?.message ||
            'A atualização dos dados falhou. Por favor, contate o suporte.'
        );
      }
    },
  },
};
</script>

<style lang="sass">
.update-ticket
  height: 100%
  background-color: $space-gray

.update-ticket__body
  padding: $space-st

.update-ticket__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.update-ticket__text-gray
  padding: $space-s 0
  color: $chrome-gray

.update-ticket__text-bold
  padding: $space-s 0
  color: $chrome-gray
  font-weight: $fw-bold

.update-ticket__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
