<template lang="pug">
  Card.parcelamento-data-card
    template(slot="header")
      .parcelamento-data-card__header
        span Cadastro do Parcelamento
        CopyIcon(
          label="Copiar link de cadastro no parcelamento"
          :clipboardText="formatLinkToParcelamento"
        )
    .parcelamento-data-card__body
      strong.parcelamento-hyperlink-text(@click="copyParcelamentoLink()") Clique aqui
      span  para copiar o link necessário para continuar o cadastro no parcelamento
</template>

<script>
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import CopyIcon from '@/components/Profile/Scholarship/CopyIcon';

export default {
  components: {
    Card,
    Icon,
    CopyIcon,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatLinkToParcelamento() {
      const offer = this.offer;
      const universityName = this.course.campus.university.name;
      const formatedUniversityName = universityName
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[^a-zA-Z0-9 -]/, '')
        .replace(/\s/g, '-');

      const text = `https://parcelamento.queropago.com.br/${formatedUniversityName}/cadastro?origin=quero_bolsa&offerId=${offer.id}`;

      return text;
    },
  },
  methods: {
    copyParcelamentoLink() {
      this.$copyText(this.formatLinkToParcelamento);
    },
  },
};
</script>

<style lang="sass" scoped>
.parcelamento-data-card
  margin-bottom: $space-st
  color: $silver

.parcelamento-data-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.parcelamento-data-card__body
  margin: $space-m 0

.parcelamento-hyperlink-text
  text-decoration: underline

.parcelamento-hyperlink-text:hover
  cursor: pointer
</style>
