<template lang="pug">
  .opa-card
    .opa-card__header
      slot(name="header")
    .opa-card__content
      .opa-card__body
        slot
    .opa-card__footer(v-if="thereIsFooter")
      template(v-if="showPrevious")
        button.opa-card__btn.opa-card__btn--left(type="button" @click="$emit('clicked', 'previous')")
          Icon(svg-name="i-chevron-left", svg-class="opa-card__btn-icon")
          span.opa-card__btn-text Anterior

      template(v-if="showNext")
        button.opa-card__btn.opa-card__btn--right(type="button" @click="$emit('clicked', 'next')")
          span.opa-card__btn-text Próximo
          Icon(svg-name="i-chevron-right", svg-class="opa-card__btn-icon")

      template(v-if="showMore")
        button.opa-card__btn.opa-card__btn--right(type="button" @click="$emit('clicked', 'more')")
          span.opa-card__btn-text Ver mais detalhes
          Icon(svg-name="i-chevron-right", svg-class="opa-card__btn-icon")

      slot(v-if="showFooterTemplate" name="footer")
    .opa-card__info-footer(v-if="showFooterDetails")
      slot(name="infoFooter")
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    showPrevious: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNext: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFooterTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFooterDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    thereIsFooter() {
      return (
        this.showFooterTemplate ||
        this.showPrevious ||
        this.showNext ||
        this.showMore
      );
    },
  },
};
</script>
