import OfferModel from '@/models/Offer';

const { QUERO_BOLSA_SHARE_HOST } = process.env;

const OrderModel = {
  isPaidOrAlmostPaid(order) {
    if (!order) return true;

    const notPaidOrAlmostPaidStatuses = [
      'initiated',
      'registered',
      'gave_up',
      'overdue',
      'expired',
    ];

    return !notPaidOrAlmostPaidStatuses.includes(order.status);
  },
  isOutdated(order) {
    if (!order) return false;
    return order.outdated;
  },
  canRegister(order, offer) {
    return (
      order.status === 'initiated' &&
      !OrderModel.isOutdated(order) &&
      !OfferModel.isOutdated(offer)
    );
  },
  canPay(order, offer) {
    return (
      order.status === 'commited' ||
      (['registered', 'expired'].includes(order.status) &&
        OfferModel.hasSeats(offer) &&
        !OrderModel.isOutdated(order) &&
        !OfferModel.isOutdated(offer) &&
        !OfferModel.isFreePreEnrollment(offer))
    );
  },
  canExpirePayment(order) {
    return order.status === 'overdue' || order.status === 'commited';
  },
  hasValidPayment(order) {
    return ['paid', 'refund_rejected'].includes(order.status);
  },
  canManualTicketAuthorization(order) {
    return order.status === 'commited' || order.status === 'overdue';
  },
  canUpdateTicket(order) {
    return order.status !== 'initiated';
  },
  acceptsBoletoPayment(order, offer) {
    return (
      OrderModel.canPay(order, offer) && OfferModel.acceptsBoletoPayment(offer)
    );
  },
  hasBoleto(order) {
    return order.status === 'commited';
  },
  hasBoletoPayable(order) {
    return order.hasBoletoPayable;
  },
  hasValidCoupon(order) {
    return [
      'paid',
      'almost_paid',
      'refund_requested',
      'refund_pending',
      'refund_rejected',
      'refunded_but_coupon_kept',
    ].includes(order.status);
  },
  hasPendingRefund(order) {
    return order.status === 'refund_pending';
  },
  adminUrl(order) {
    return `${QUERO_BOLSA_SHARE_HOST}/admin/order/${order.id}`;
  },
  hasStatusProblem(order) {
    return order.status === 'problem';
  },
};

export default OrderModel;
