<template lang="pug">
  .register-order
    TabHeader(:title="offerNames.course || '.'" :subTitle="offerNames.university || '.'")
    Loading.register-order__loading(v-if="loading")
    .register-order__scroller(v-else)
      .register-order__body
        .register-order__title Registrar ordem
        .register-order__subtitle Dados do aluno

        .enrolled-question--title O aluno já se matriculou/está matriculado na {{ offerNames.university }}? *
        .register-order__enrolled-checkbox-list
          Checkbox.register-order__enrolled-checkbox(
            v-model="notEnrolled"
            label="Não"
            @click="notEnrolledClick"
          )
          Checkbox.register-order__enrolled-checkbox(
            v-model="enrolled"
            label="Sim"
            @click="enrolledClick"
          )

        template(v-if="enrolled")
          .enrolled-question--title Vaga destinada apenas a alunos não matriculados
        template(v-if="notEnrolled")
          RegisterOrderRG.register-order__register-order-rg(
            v-if="registerOrderData.needsRG"
            v-model="registerOrderRG"
          )
          RegisterUserBody.register-order__register-user-body(
            v-model="registerUserBody"
            :fullRequired="true"
            :uniques="true"
            registerType="update"
          )
          RegisterOrderBody.register-order__register-order-body(
            :options="registerOrderData.options"
            v-model="registerOrderBody"
          )
          SendBillBody.register-order__send-bill-body(
            ref="sendBillBody"
            v-model="sendBillBody"
            :acceptsBoleto="acceptsBoletoPayment"
            :freePreEnrollment="hasFreePreEnrollment"
          )
          Button.register-order__btn(
            @click="registerOrder"
            :disabled="hasErrors"
            :text="submitting ? 'Aguarde...' : 'Registrar ordem'"
            :loading="submitting"
          )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Loading from '@/components/common/Loading';
import Checkbox from '@/components/common/Checkbox';
import RegisterUserBody from '@/components/Profile/RegisterUserBody';
import RegisterOrderBody from '@/components/Scholarship/RegisterOrderBody';
import RegisterOrderRG from '@/components/Scholarship/RegisterOrderRG';
import SendBillBody from '@/components/Scholarship/SendBillBody';
import { cleanData } from '@/common_modules/formLib';
import OfferModel from '@/models/Offer';
import { mapGetters, mapActions } from 'vuex';
import { merge, unset, pick } from 'lodash';

export default {
  components: {
    TabHeader,
    Button,
    Checkbox,
    RegisterUserBody,
    RegisterOrderBody,
    RegisterOrderRG,
    Loading,
    SendBillBody,
  },
  data: () => ({
    registerOrderData: {
      options: {
        incomeRange: [],
        howYouFound: [],
        enemYear: [],
      },
    },
    registerUserBody: {},
    registerOrderBody: {},
    sendBillBody: {},
    registerOrderRG: {},
    submitting: false,
    loading: true,
    notEnrolled: false,
    enrolled: false,
  }),
  computed: {
    ...mapGetters('Order', ['offerNames', 'offer']),
    hasErrors() {
      // registerOrderBody can't be wrong, for now
      return !!(
        this.registerUserBody.hasErrors ||
        this.registerOrderBody.hasErrors ||
        this.registerOrderRG.hasErrors ||
        this.hasBillBodyErrors
      );
    },
    acceptsBoletoPayment() {
      return OfferModel.acceptsBoletoPayment(this.offer.result.offer);
    },
    hasFreePreEnrollment() {
      return this.offer.result.offer.freePreEnrollment;
    },
    hasBillBodyErrors() {
      return this.sendBillBody.hasErrors && !this.sendBillBody.blank;
    },
  },
  async created() {
    this.registerOrderData = await this.getRegisterOrderData();
    this.loading = false;

    this.registerOrderRG = pick(
      this.registerOrderData.filledData,
      'document',
      'documentExpeditor'
    );

    this.registerOrderBody = pick(
      this.registerOrderData.filledData,
      'howYouFound',
      'incomeRange',
      'discountCode',
      'enemHumanas',
      'enemLinguagens',
      'enemMatematica',
      'enemNatureza',
      'enemNumber',
      'enemRedacao',
      'enemYear'
    );
  },
  methods: {
    ...mapActions('Order', [
      'submitRegisterOrderClicked',
      'getRegisterOrderData',
      'registerOrderFinished',
    ]),
    notEnrolledClick() {
      this.enrolled = false;
    },
    enrolledClick() {
      this.notEnrolled = false;
    },
    async registerOrder() {
      const shouldSendBill = !this.sendBillBody.blank;

      const params = parseRegisterOrderParms(
        this.registerUserBody,
        this.registerOrderBody,
        this.registerOrderRG,
        this.registerOrderData
      );

      this.submitting = true;
      const order = await this.submitRegisterOrderClicked(params);

      if (order && shouldSendBill) await this.$refs.sendBillBody.sendBill();
      if (order) this.registerOrderFinished(order);

      this.submitting = false;
    },
  },
};

function parseRegisterOrderParms(
  registerUserBody,
  registerOrderBody,
  registerOrderRG,
  registerOrderData
) {
  const registerUserBodyFormData = cleanData(registerUserBody.formData);
  ['phone', 'email'].map(singularKey => {
    const pluralKey = singularKey + 's';
    registerUserBodyFormData[singularKey] =
      registerUserBodyFormData[pluralKey][0];
    unset(registerUserBodyFormData, pluralKey);
  });

  const extra = {
    needsRG: registerOrderData.needsRG,
  };

  return merge(
    registerUserBodyFormData,
    registerOrderBody,
    registerOrderRG,
    extra
  );
}
</script>

<style lang="sass">
.register-order
  display: flex
  flex-direction: column
  height: 100vh
  background-color: $space-gray

.register-order__loading
  margin-top: $space-xl

.register-order__scroller
  overflow: auto

.register-order__body
  padding: $space-m

.register-order__title
  font-size: rem(20)
  font-weight: bold
  margin-bottom: $space-l

.register-order__subtitle
  font-size: rem(18)
  font-weight: bold
  margin-bottom: $space-s

.register-order__register-user-body
  margin-bottom: $space-l

.register-order__register-order-body
  margin-bottom: $space-xl

.register-order__btn
  margin-top: $space-xxl
  flex-shrink: 0
  width: 100%

.register-order__enrolled-checkbox-list
  display: flex
  flex-wrap: wrap
  margin-bottom: $space-xs
  margin-top: $space-s

.register-order__enrolled-checkbox
  margin-right: $space-m
  white-space: nowrap

.enrolled-question--title
  font-size: rem(14)
</style>
