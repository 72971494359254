import { Socket } from 'phoenix';

import TokenService from '@/assets/javascripts/services/TokenService';

const PUSH_TIMEOUT = 10000;

class SocketService {
  constructor() {
    this.socket = null;
    this.channel = null;
  }

  join(adminId) {
    this.socket = new Socket('/socket', {
      params: {
        token: TokenService.getToken(),
        version: process.env.OPA_CLIENT_VERSION,
      },
    });

    this.channel = this.socket.channel(`opa:admin_user:${adminId}`);

    // TODO rever a experiência do log, o que é mais viável ser feito
    // this.socket.onError(error => {
    //   Rollbar.info('SocketService socket error', { error });
    // });

    // TODO rever a experiência do log, o que é mais viável ser feito
    // this.channel.onError(error => {
    //   Rollbar.info('SocketService channel error', { error });
    // });

    return new Promise((resolve, reject) => {
      this.socket.connect();
      this.channel
        .join()
        .receive('ok', admin => {
          resolve(admin);
        })
        .receive('error', () => {
          reject();
        });
      this.channel.onError(() => {
        reject();
      });
    });
  }

  setListener(eventName, listener) {
    this.channel.on(eventName, listener);
  }

  removeListener(eventName) {
    this.channel.off(eventName);
  }

  sendRequest(
    requestName,
    params,
    returnPropertyName,
    pushTimeout = PUSH_TIMEOUT
  ) {
    if (!this.channel) {
      console.log('SocketError - channel is undefined');
      return;
    }

    return new Promise((resolve, reject) => {
      this.channel
        .push(requestName, params, pushTimeout)
        .receive('ok', payload => {
          if (Object.keys(payload).includes('results')) {
            resolve(payload.results);
            return;
          }

          if (returnPropertyName) {
            resolve(payload[returnPropertyName]);
            return;
          }

          resolve(payload);
        })
        .receive('error', payload => {
          const errors = payload.errors;

          if (Array.isArray(errors)) {
            for (let error of errors) {
              console.log('SocketError: ' + error.toString());
            }
            reject(errors);
          } else {
            reject(payload);
            console.log('SocketError ', payload);
          }
        })
        .receive('timeout', () => {
          const errorMessage = `Timeout ocurred for ${requestName} request`;

          reject(new Error(errorMessage));
        });
    });
  }
}

const SocketServiceInstance = new SocketService();

SocketServiceInstance.sendRequest = SocketServiceInstance.sendRequest.bind(
  SocketServiceInstance
);

SocketServiceInstance.setListener = SocketServiceInstance.setListener.bind(
  SocketServiceInstance
);

SocketServiceInstance.removeListener = SocketServiceInstance.removeListener.bind(
  SocketServiceInstance
);

export default SocketServiceInstance;
