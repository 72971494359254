import Vue from 'vue';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

Vue.filter('momentformat', (value, type) => moment(value).format(type));
Vue.filter('momentcalendar', value => moment(value).calendar());

// Documentation: https://momentjs.com/
