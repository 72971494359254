import { includes } from 'lodash';

export default () => {
  const self = {
    activationKeys: ["'"],
    shortcuts: {},
    shortcuting: false,
    shortcutTriggered: false,
    inputData: null,
    trigger: '',
    //
    setProp(key, value) {
      self[key] = value;
    },
    startShortcuting() {
      self.shortcuting = true;
    },
    resetShortcuting() {
      self.shortcuting = false;
      self.shortcutTriggered = false;
      self.inputData = null;
      self.trigger = '';
    },
    addShortcut(trigger, fun) {
      self.shortcuts[trigger.toLowerCase()] = fun;
      self.shortcuts[trigger.toUpperCase()] = fun;
    },
    removeShortcut(trigger) {
      self.shortcuts[trigger.toLowerCase()] = null;
      self.shortcuts[trigger.toUpperCase()] = null;
    },
    addToTrigger(key) {
      self.trigger = self.trigger.concat(key);
    },
    removeFromTrigger(key) {
      self.trigger = self.trigger.replace(key, '');
    },
    //
    shootTrigger: () => self.shortcuts[self.trigger],
    isActivationKey: ev => includes(self.activationKeys, ev.key),
    doesTriggerContains: key => includes(self.trigger, key),
  };

  return self;
};
