export default {
  financialDataTabClicked({ commit, dispatch }, billing) {
    commit('setCurrentBilling', billing);

    dispatch(
      'RightPanel/tabChange',
      {
        key: 'financial-data',
      },
      { root: true }
    );
  },

  billingsTabClicked({ dispatch }) {
    dispatch(
      'RightPanel/tabChange',
      {
        key: 'billings',
      },
      { root: true }
    );
  },

  setFinancialData({ commit }, financialData) {
    commit('setFinancialData', financialData);
  },
};
