<template lang="pug">
  .register-order-body
    .register-order-body__subtitle Referência
    .register-order-body__label Como conheceu o Quero Bolsa?
    Select.register-order-body__select(
      ref="howYouFound"
      placeholder="Escolha uma opção"
      :list="options.howYouFound"
      v-model="value.howYouFound"
      label-property="name"
    )
    template(v-if="canShowHowYouFoundOther")
      .register-order-body__label Qual?
      input.register-order-body__input(
        type="text"
        name="howYouFoundOther"
        v-model="value.howYouFoundOther"
        placeholder="Ex: Estande do Quero Bolsa"
      )

    .register-order-body__subtitle Informações adicionais
    .register-order-body__label Renda mensal por pessoa na casa
    Select.register-order-body__select(
      ref="incomeRange"
      placeholder="Escolha uma opção"
      :list="options.incomeRange"
      v-model="value.incomeRange"
      label-property="name"
    )
    .register-order-body__label Desconto da pré-matrícula
    input.register-order-body__input(
      ref="discountCode"
      type="text"
      name="discountCode"
      v-model="value.discountCode"
      placeholder="Ex: 01282399"
      autocomplete="off"
    )
    .register-order-body__label Ano do ENEM
    Select.register-order-body__select(
      placeholder="Escolha uma opção"
      :list="options.enemYear"
      v-model="value.enemYear"
      label-property="name"
    )
    template(v-if="canShowEnemForm")
      .register-order-body__title Dados do ENEM
      .register-user-body__group
        .register-user-body__label Número de inscrição do ENEM *
        Cleave.register-order-body__input(
            name="enemNumber"
            v-model="value.enemNumber"
            placeholder="Ex: 000.000.000.000"
            autocomplete="off"
            :options="{delimiters: ['.', '.', '.'], blocks: [3, 3, 3, 3], numericOnly: true}"
            :raw="false"
          )

        Alert.register-user-body__alert(
          v-if="errors.enemNumber"
          message="obrigatório, formato 000.000.000.000"
          type="error"
          :dismissable="false"
        )

      .register-order-body__subtitle Notas do ENEM
      .register-order-body__label Ciências da natureza e suas tecnologias *
      Cleave.register-order-body__input(
        name="enemNatureza"
        id="enemNatureza"
        v-model.number="value.enemNatureza"
        placeholder="Ex: 657"
        autocomplete="off"
        :options="{numericOnly: true, blocks: [4]}"
        :raw="true"
      )

      Alert.register-user-body__alert(
        v-if="errors.enemNatureza"
        message="obrigatório, número entre 0 e 1000"
        type="error"
        :dismissable="false"
      )

      .register-order-body__label Ciências humanas e suas tecnologias *
      Cleave.register-order-body__input(
        name="enemHumanas"
        id="enemHumanas"
        v-model.number="value.enemHumanas"
        placeholder="Ex: 657"
        autocomplete="off"
        :options="{numericOnly: true, blocks: [4]}"
        :raw="true"
      )

      Alert.register-user-body__alert(
        v-if="errors.enemHumanas"
        message="obrigatório, número entre 0 e 1000"
        type="error"
        :dismissable="false"
      )

      .register-order-body__label Linguagens, códigos e suas tecnologias *
      Cleave.register-order-body__input(
        name="enemLinguagens"
        id="enemLinguagens"
        v-model.number="value.enemLinguagens"
        placeholder="Ex: 657"
        autocomplete="off"
        :options="{numericOnly: true, blocks: [4]}"
        :raw="true"
      )

      Alert.register-user-body__alert(
        v-if="errors.enemLinguagens"
        message="obrigatório, número entre 0 e 1000"
        type="error"
        :dismissable="false"
      )

      .register-order-body__label Matemática e suas tecnologias *
      Cleave.register-order-body__input(
        name="enemMatematica"
        id="enemMatematica"
        v-model.number="value.enemMatematica"
        placeholder="Ex: 657"
        autocomplete="off"
        :options="{numericOnly: true, blocks: [4]}"
        :raw="true"
      )

      Alert.register-user-body__alert(
        v-if="errors.enemMatematica"
        message="obrigatório, número entre 0 e 1000"
        type="error"
        :dismissable="false"
      )

      .register-order-body__label Redação *
      Cleave.register-order-body__input(
        name="enemRedacao"
        id="enemRedacao"
        v-model.number="value.enemRedacao"
        placeholder="Ex: 657"
        autocomplete="off"
        :options="{numericOnly: true, blocks: [4]}"
        :raw="true"
      )

      Alert.register-user-body__alert(
        v-if="errors.enemRedacao"
        message="obrigatório, número entre 0 e 1000"
        type="error"
        :dismissable="false"
      )
</template>

<script>
import Alert from '@/components/common/Alert';
import Checkbox from '@/components/common/Checkbox';
import Select from '@/components/common/Select';
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, without } from 'lodash';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Alert,
    Checkbox,
    Cleave,
    Select,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      valueKeys: [
        'enemYear',
        'enemNumber',
        'enemNatureza',
        'enemHumanas',
        'enemLinguagens',
        'enemMatematica',
        'enemRedacao',
      ],
    };
  },
  computed: {
    ...mapGetters('Order', ['offerNames']),
    canShowHowYouFoundOther() {
      return this.value.howYouFound && this.value.howYouFound.name === 'Outro';
    },
    hasErrors() {
      if (isEmpty(this.value)) return;
      if (this.value.enemYear === 0) return false;
      return this.checkAllValues();
    },
    canShowEnemForm() {
      return !!(
        this.value.enemYear && this.value.enemYear.name !== 'Não fez ENEM'
      );
    },
  },
  watch: {
    hasErrors() {
      this.updateParent();
    },
    value() {
      this.checkAllValues();
    },
  },
  methods: {
    ...mapActions('Order', ['getRegisterOrderData']),
    updateParent() {
      this.$emit('input', {
        ...this.value,
        hasErrors: this.hasErrors,
      });
    },

    checkAllValues() {
      let enemGrades = without(this.valueKeys, 'enemYear', 'enemNumber');

      const checkedFields = enemGrades.map(arg => {
        return this.checkValue(arg);
      });

      const enemNumberOk = this.checkEnemNumber();

      return (
        !enemNumberOk ||
        checkedFields.includes(false) ||
        checkedFields.includes(null)
      );
    },
    checkValue(arg) {
      let filled = !!this.value[arg];
      let valid = !!(
        typeof this.value[arg] === 'number' &&
        this.value[arg] >= 0 &&
        this.value[arg] <= 1000
      );
      if (filled && valid) delete this.errors[arg];
      else if (filled && !valid) this.errors[arg] = true;

      return valid && filled;
    },
    checkEnemNumber() {
      if (this.value.enemNumber && this.value.enemNumber.trim().length < 15)
        this.errors.enemNumber = true;
      else delete this.errors.enemNumber;

      return this.value.enemNumber && this.value.enemNumber.trim().length == 15;
    },
  },
};
</script>

<style lang="sass">
.register-order-body__subtitle
  font-size: rem(19)
  font-weight: bold
  margin-bottom: $space-s

.register-order-body__title
  font-size: rem(21)
  font-weight: bold
  margin-top: $space-st
  margin-bottom: $space-s

.register-order-body__label
  margin-bottom: $space-s

.register-order-body__input
  @extend %opa-input
  width: 100%
  margin-bottom: $space-m

.register-order-body__select
  margin-bottom: $space-m
</style>
