import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  async updateSemester({ couponId, semester }) {
    const { data } = await HttpService.post(
      `api/coupon/${couponId}/update_semester`,
      {
        semester: semester,
      }
    );
    return data;
  },
};
