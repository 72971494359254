<template lang="pug">
  .choose-user
    TabHeader(:title="`${this.users.length} contas encontradas`")
    .choose-user__container
      Accordion(:list="users" :initialize-open="0")
        template(slot="head" slot-scope="{ item, open }")
          .choose-user__header(:class="{'choose-user__header--open': open}")
            Avatar.choose-user__avatar(
              :hasLogin="item.userInfo.hasLogin"
            )
            .choose-user__header-info
              .choose-user__name {{ item.userInfo.name }}
              span {{ item.userInfo.phoneNumber }}
            Icon(svg-class="choose-user__icon" svg-name="i-chevron-right")
        template(slot="body"  slot-scope="{ item }")
          .choose-user__body
            OrderCard.choose-user__card(v-if="item.order" :order="item.order")
            CourseCard.choose-user__card(v-if="item.course" :course="item.course" :offer="item.offer")
            Card.choose-user__card
              template(slot="header")
                .choose-user__card-header Contatos e Endereço
              .choose-user__card-body
                template(v-for="info in getUserInfoList(item.userInfo)")
                  .choose-user__card-space(v-if="info === 'space'")
                  .choose-user__card-row(v-else)
                    span.choose-user__card-caption {{ info[0] }}:
                    span.choose-user__card-text {{ info[1] }}
            Button(
              v-if="canChangeBaseUser && !isChoosingProfile"
              text="Selecionar esta conta"
              @click="submit(item)"
            )
</template>

<script>
import Accordion from '@/components/common/Accordion';
import TabHeader from '@/components/common/TabHeader';
import Avatar from '@/components/common/Avatar';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import OrderCard from '@/components/Profile/Scholarship/OrderCard';
import CourseCard from '@/components/Profile/Scholarship/CourseCard';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    Accordion,
    Avatar,
    Icon,
    OrderCard,
    CourseCard,
    Card,
    Button,
  },
  props: {
    params: {
      //params = { searchBy,  value }
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      users: [],
    };
  },
  computed: {
    ...mapGetters('Profile', ['canChangeBaseUser', 'isChoosingProfile']),
  },
  async created() {
    this.users = await this.searchUserFull(this.params);
  },
  methods: {
    ...mapActions('Profile', ['searchUserFull', 'chooseUser']),
    getUserInfoList(info) {
      return [
        ['CPF', info.cpf],
        ['E-mail', info.email],
        ['Celular', info.phoneNumber],
        'space',
        ['Nome completo', info.name],
        ['Data de nascimento', info.birthday],
        ['CEP', info.postalCode],
        ['Estado', info.state],
        ['Cidade', info.city],
        ['Bairro', info.neighborhood],
        ['Rua', info.address],
        ['Número', info.addressNumber],
        ['Complemento', info.addressComplement],
      ];
    },
    submit(item) {
      this.chooseUser(item.userInfo.id);
    },
  },
};
</script>
