const admissionExamKindMap = {
  ENEM: {
    title: 'Enem',
    description: '(A partir de %cutYear%)',
    subs: ['cutYear'],
  },
  ESSAY: {
    title: 'Redação',
    description: '',
    subs: [],
  },
  DEGREE: {
    title: 'Diploma',
    description: '',
    subs: [],
  },
};

const admissionExamTitle = ({ kind }) => admissionExamKindMap[kind].title;

const admissionExamDescription = admissionExam => {
  const { description, subs } = admissionExamKindMap[admissionExam.kind];
  const subFun = (acc, sub) => acc.replace(`%${sub}%`, admissionExam[sub]);

  return subs.reduce(subFun, description);
};

export { admissionExamTitle, admissionExamDescription };
