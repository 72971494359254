export const actions = {
  selectedUser({ dispatch }, { baseUserId }) {
    dispatch('Profile/showProfileHome', { baseUserId }, { root: true });
    dispatch('OfferSearch/onChatSelectedChange', null, { root: true });

    dispatch('Omni/ticketSelected', null, {
      root: true,
    });

    dispatch('Omni/clearLastContactClicked', null, {
      root: true,
    });
  },
  closedUserSearch({ dispatch }) {
    dispatch('Tickets/reselectTicket', null, { root: true });
    dispatch('OfferSearch/hideSearch', null, {
      root: true,
    });
  },
  openedUserSearch({ dispatch }) {
    dispatch('OfferSearch/onChatSelectedChange', null, { root: true });
  },
  registerNewUserClicked({ dispatch }) {
    dispatch('Omni/ticketClosed', null, {
      root: true,
    });

    dispatch('StoreManager/resetRightPanel', null, {
      root: true,
    });

    dispatch(
      'RightPanel/tabChange',
      { key: 'register-user', params: { registerType: 'create' } },
      {
        root: true,
      }
    );
  },
};
