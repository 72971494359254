<template lang="pug">
  .sidebar
    .sidebar__logo
      .sidebar__logo__icon
    .sidebar__menu
      TenantList

    .sidebar__footer
      template(v-for="item in availableItems")
        Popover(open-on="hover" transitionName="fade")
          component.sidebar__footer__item.sidebar__footer__item--menu(
            :is="item.isClient? 'nuxt-link' : 'a'"
            :to="item.route"
            :href="item.route"
            :class="{ 'sidebar__footer__item--active': isCurrentItem(item) }"
          )
            icon(svg-class="sidebar__icon", :svg-name="item.icon")
          template(slot="popover")
            Tooltip(specialPosition="right")
              .sidebar__tooltip {{ item.text }}

      SidebarUser
</template>

<script>
import Icon from '@/components/common/Icon';
import SidebarUser from '@/components/sidebar/SidebarUser';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';
import TenantList from '@/components/sidebar/TenantList';

import { mapGetters } from 'vuex';

const SALES = 'sales';
const POST_SALES = 'post-sales';

export default {
  components: {
    Icon,
    SidebarUser,
    Popover,
    Tooltip,
    TenantList,
  },

  computed: {
    ...mapGetters('Sidebar', ['currentPath', 'items']),
    ...mapGetters('Permissions', ['isPostSales', 'canUseOnlyBasicTools']),

    availableItems() {
      const self = this;
      return this.items.filter(item => {
        return (
          (item.kind === SALES || self.isPostSales) && self.canShowItem(item)
        );
      });
    },

    kind() {
      return this.isPostSales ? POST_SALES : SALES;
    },
  },

  methods: {
    isCurrentItem({ route }) {
      return this.currentPath == route;
    },
    canShowItem(item) {
      return this.canUseOnlyBasicTools ? item.basicTool : true;
    },
  },
};
</script>
