const followUpTranslate = {
  initiated: { root: 'Processo Seletivo', step: 'Pendente', color: '#FDB913' },
  registered: { root: 'Processo Seletivo', step: 'Agendado', color: '#118DCB' },
  approved: {
    root: 'Processo Seletivo',
    step: 'Aluno Aprovado',
    color: '#11CBAD',
  },
  failed: {
    root: 'Processo Seletivo',
    step: 'Aluno Reprovado',
    color: '#E03F50',
  },
  pending_docs: { root: 'Matrícula', step: 'Docs Pendentes', color: '#FDB913' },
  submitted_docs: {
    root: 'Matrícula',
    step: 'Docs em Análise',
    color: '#F16B28',
  },
  approved_docs: {
    root: 'Matrícula',
    step: 'Docs Aprovados',
    color: '#11CBAD',
  },
  rejected_enrollment: {
    root: 'Matrícula',
    step: 'Matrícula Rejeitada',
    color: '#CB1183',
  },
  dropping_out: { root: 'Matrícula', step: 'Desistindo', color: '#CB1183' },
  dropped_out: { root: 'Pós-Matricula', step: 'Desistiu', color: '#CB1183' },
  enrolled: { root: 'Pós-Matricula', step: 'Matriculado', color: '#11CBAD' },
};

export { followUpTranslate };
