const autocompleteCache = {};

let sessionToken;
const getSessionToken = () => {
  if (sessionToken) {
    return sessionToken;
  }
  sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  return sessionToken;
};

export function resolveAddress({ place_id: placeId, description }) {
  const { GeocoderStatus } = window.google.maps;
  const geocoder = new window.google.maps.Geocoder();
  const placeIdMode = !!placeId;
  const params = {
    placeId: placeId,
    address: placeIdMode ? null : description,
    region: 'BR',
  };

  const geocoding = new Promise(resolve =>
    geocoder.geocode(params, (...args) => resolve(args))
  );

  return geocoding.then(([results, status]) => {
    if (status === GeocoderStatus.ZERO_RESULTS && placeIdMode) {
      return resolveAddress({ description });
    }

    if (status !== GeocoderStatus.OK) {
      return new Error(`Google geocode ERROR: ${status}`);
    }

    const payload = {};
    payload.lat = results[0].geometry.location.lat();
    payload.lng = results[0].geometry.location.lng();

    return payload;
  });
}

export function cachedAutocompleteAddress(searchText) {
  const textToSearch = searchText.trim();
  const cached = autocompleteCache[textToSearch];

  if (cached) {
    return cached;
  }

  const result = autocompleteAddress(textToSearch);

  result.catch(err => {
    autocompleteCache[textToSearch] = null;
    throw err;
  });

  autocompleteCache[textToSearch] = result;

  return result;
}

export function autocompleteAddress(searchText) {
  const { GeocoderStatus } = window.google.maps;
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  const params = {
    input: searchText,
    componentRestrictions: { country: 'BR' },
    types: ['geocode'],
    sessionToken: getSessionToken(),
  };

  const autocompleting = new Promise(resolve =>
    autocompleteService.getPlacePredictions(params, (...args) => resolve(args))
  );

  return autocompleting.then(([result, status]) => {
    if (status === GeocoderStatus.ZERO_RESULTS) {
      return [];
    }
    if (status !== GeocoderStatus.OK) {
      return [];
    }

    return result;
  });
}

export function computeDistance(coordinate1, coordinate2) {
  if (
    !coordinate1.lng ||
    !coordinate1.lat ||
    !coordinate2.lng ||
    !coordinate2.lat
  ) {
    return null;
  }
  const latLong1 = new window.google.maps.LatLng(coordinate1);
  const latLong2 = new window.google.maps.LatLng(coordinate2);
  return window.google.maps.geometry.spherical.computeDistanceBetween(
    latLong1,
    latLong2
  );
}
