<template lang="pug">
  .card-details
    Card.queropago-card(
      v-bind:showPrevious="shouldShowPreviousButton"
      v-bind:showNext="shouldShowNextButton"
      @clicked="changeCard"
    )
      template(slot="header")
        .queropago-card__header
          span Cartão
          Badge(v-if="shouldShowBadge" :type="'success'").queropago-card__header-badge Padrão
      .queropago-card__body
        .queropago-card__item
          strong Nome:
          .queropago-card__item-content {{this.studentCard.name}}
        .queropago-card__item
          strong Tipo:
          .queropago-card__item-content {{this.studentCard.type}}
        .queropago-card__item
          strong Bandeira:
          .queropago-card__item-content {{this.studentCard.brand}}
        .queropago-card__item
          strong Número:
          .queropago-card__item-content {{this.studentCard.formattedCardNumber}}
        .queropago-card__item
          strong Vencimento:
          .queropago-card__item-content {{this.studentCard.formattedExpirationDate}}
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';

export default {
  components: {
    Card,
    Badge,
  },
  data: () => {
    return {
      studentCards: [],
      cardIndex: 0,
    };
  },
  computed: {
    ...mapState('Queropago', ['financialData', 'currentBilling']),
    shouldShowPreviousButton() {
      return this.currentCardIndex > 0;
    },
    shouldShowNextButton() {
      return this.currentCardIndex < this.studentCards.length - 1;
    },
    studentCard() {
      return this.studentCards[this.cardIndex] || {};
    },
    shouldShowBadge() {
      return this.currentCardIndex === 0;
    },
    currentCardIndex() {
      return this.studentCards.findIndex(
        card => card.id === this.studentCard.id
      );
    },
  },
  beforeMount() {
    this.parseStudentCards();
  },
  methods: {
    changeCard(value) {
      if (value === 'next') {
        this.cardIndex += 1;
      } else if (value === 'previous') {
        this.cardIndex -= 1;
      }
    },
    parseStudentCards() {
      if (this.currentBilling.currentCard !== {}) {
        this.studentCards.push(this.currentBilling.studentCard);

        for (let card of this.financialData.orderedCards) {
          if (card.id !== this.studentCards[0].id) {
            this.studentCards.push({
              ...card,
            });
          }
        }
      }
    },
  },
};
</script>
