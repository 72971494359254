<template lang="pug">
  transition(name="modal-fade")
    .base-modal__backdrop
      .base-modal(
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      )
        header.base-modal__header
          slot(name="header")
            h2 Exemplo de header
          .base-modal__header-right
            ClickableIcon.base-modal__head-icon(
              svg-name="i-close"
              svg-alt="Fechar Modal"
              @click="$emit('hide')"
            )
        section.base-modal__body
          slot(name="body") Exemplo de body
</template>

<script>
import ClickableIcon from '@/components/common/ClickableIcon';

export default {
  components: {
    ClickableIcon,
  },
};
</script>

<style lang="sass" scoped>
.base-modal__backdrop
  align-items: center
  background-color: $black-gray-50
  bottom: 0
  display: flex
  justify-content: center
  left: 0
  position: fixed
  right: 0
  top: 0

.base-modal
  background: $star-gray
  box-shadow: 2px 2px 20px 1px $dark-black
  display: flex
  flex-direction: column
  overflow-x: auto
  padding: $space-m
  width: 528px
  word-break: break-word

.base-modal__header
  color: $light-steel
  display: flex
  justify-content: space-between
  padding: $space-m

.base-modal__header-right
  margin-right: 0

.base-modal__body
  color: $light-steel
  padding: $space-m
  position: relative

.modal-fade-enter,
.modal-fade-leave-active
  opacity: 0

.modal-fade-leave-active
  transition: opacity .4s ease

.base-modal__head-icon
  color: $topaz
  &:hover
    color: $silver-gray
  &:active
    color: $galaxy-gray
</style>
