<template lang="pug">
  .admission(v-if="showDigitalAdmissionComponent()")
    .admission-title.spacer-bottom-m
      | Admissão Digital
    Info.spacer-bottom-st(:admission="admission")
    Step.spacer-bottom-st(:admission="admission")
    Flow(:admission="admission")
</template>

<script>
import Rollbar from '@/common_modules/tools/Rollbar';

import Step from '@/opa-modules/admissions/components/Step';
import Info from '@/opa-modules/admissions/components/Info';
import Flow from '@/opa-modules/admissions/components/Flow';

import AdmissionFetchQuery from '@/plugins/apollo/querobolsa/queries/admissionFetch';

export default {
  components: {
    Step,
    Info,
    Flow,
  },

  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },

  data: function() {
    return { admission: null };
  },

  methods: {
    showDigitalAdmissionComponent() {
      return this.admission && this.admission.kind == 'digital';
    },
  },

  apollo: {
    AdmissionFetch: {
      manual: true, // Descobrir o porque disso
      prefetch: true, // Descobrir o porque disso
      addTypename: true, // Descobrir o porque disso
      query: AdmissionFetchQuery,
      client: 'querobolsa',
      fetchPolicy: 'network-only',
      variables() {
        return {
          orderId: this.orderId,
        };
      },
      result(response) {
        this.admission = response.data.admissionFetch;
      },
      update(response) {
        this.admission = response.data.admissionFetch;
      },
      error(error) {
        Rollbar.error('Admission | GraphAPI error', { error });
      },
    },
  },
};
</script>
