import { isNil } from 'lodash';

const { QUERO_BOLSA_SHARE_HOST } = process.env;

export default {
  isOutdated(offer) {
    return !offer.visible || !offer.enabled;
  },
  isFreePreEnrollment(offer) {
    return offer.freePreEnrollment;
  },
  hasSeats(offer) {
    return !(
      isNil(offer.remainingSeats) ||
      ['N/D', 'Indisponível', '0 (Todas as bolsas reservadas)'].includes(
        offer.remainingSeats
      )
    );
  },
  acceptsBoletoPayment(offer) {
    return offer.acceptsBoletoPayment;
  },
  couponUrl(offer) {
    if (!offer.coupon) {
      return '';
    }

    return `${QUERO_BOLSA_SHARE_HOST}/coupons/${offer.coupon.id}.pdf`;
  },
};
