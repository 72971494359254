<template lang="pug">
  button(style="height: 0; width: 0; position: absolute;" ref="button" type="button")
</template>

<script>
export default {
  mounted() {
    this.$refs.button.focus();
  },
};
</script>
