<template lang="pug">
.activate-admission
  TabHeader(title="Ativar Admissão Digital")
  form.activate-admission__body(@submit.prevent="submit")
    .opa__card-body
      .activate-admission__text Essa ação permitirá que o aluno siga com o fluxo de matrícula diretamente pelo nosso site.
      .activate-admission__info A ativação será realizada apenas se as configurações dessa bolsa possuírem o benefício da Admissão Digital.

      Button.activate-admission__button(
        type="positive"
        :text="'Ativar Admissão'"
        :submit="true"
      )
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapState, mapActions } from 'vuex';
import FollowUpAPI from '@/api/followUpAPI';

export default {
  components: {
    TabHeader,
    Button,
  },
  computed: {
    ...mapState('Order', ['followUp']),
    followUpData() {
      return this.followUp;
    },
  },
  methods: {
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),
    submit() {
      FollowUpAPI.activateDigitalAdmission({
        followUpId: this.followUp.id,
      })
        .then(() => {
          this.notifySuccess('Admissão digital ativada com sucesso.');
        })
        .catch(error => {
          const message =
            error?.response?.data?.message ||
            'Ocorreu um erro ao ativar a admissão digital. Por favor, contate o suporte.';
          this.notifyError(message);
        })
        .finally(() => {
          this.tabGoBack();
        });
    },
  },
};
</script>

<style>
.activate-admission__body {
  padding: 6%;
}
.activate-admission__text {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.activate-admission__info {
  padding-top: 5%;
  display: flex;
  font-size: rem(18);
  color: white;
  padding-bottom: 5%;
}
.activate-admission__button {
  width: 100%;
}
</style>
