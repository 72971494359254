<template lang="pug">
  .tooltip__box(:class="boxClass" ref="box")
    .tooltip__container(:class="overflowClass")
      slot
</template>

<script>
export default {
  props: {
    vposition: {
      type: String,
      required: false,
      validator: value => ['up', 'down'].includes(value),
      default: 'up',
    },
    hposition: {
      type: String,
      required: false,
      validator: value => ['right', 'left'].includes(value),
      default: 'right',
    },
    specialPosition: {
      type: String,
      required: false,
      validator: value => ['right', 'down', 'top-right'].includes(value),
      default: null,
    },
    overflow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    overflowClass() {
      return {
        'tooltip__container--overflow': this.overflow,
      };
    },
    boxClass() {
      if (this.specialPosition) {
        return {
          'tooltip__box--special-right': this.specialPosition === 'right',
          'tooltip__box--special-down': this.specialPosition === 'down',
          'tooltip__box--special-top-right':
            this.specialPosition === 'top-right',
        };
      } else {
        return {
          'tooltip__box--up': this.vposition === 'up',
          'tooltip__box--down': this.vposition === 'down',
          'tooltip__box--attach-right': this.hposition === 'right',
          'tooltip__box--attach-left': this.hposition === 'left',
        };
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.tooltip__box
  user-select: none
  position: absolute
  will-change: transform
  z-index: 10

.tooltip__container
  position: relative
  background: $black-gray
  border: 1px solid rgba($white, 0.07)
  border-radius: $border-radius

  &::before,
  &::after
    content: ""
    display: block
    border-left: 9px solid transparent
    border-right: 9px solid transparent
    position: absolute
  &::before
    z-index: 2
  &::after
    z-index: 1

.tooltip__container--overflow
  border-radius: 10px
  max-height: 300px
  overflow-y: auto

.tooltip__box--up
  bottom: 100%
  padding-bottom: 10px

  .tooltip__container
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.50)

    &::before,
    &::after
      border-top: 9px solid
    &::before
      bottom: -9px
      border-top-color: $black-gray
    &::after
      bottom: -10px
      border-top-color: rgba($white, 0.07)

.tooltip__box--down
  top: 100%
  padding-top: 10px

  .tooltip__container
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50)

    &::before,
    &::after
      border-bottom: 9px solid
    &::before
      top: -9px
      border-bottom-color: $black-gray
    &::after
      top: -10px
      border-bottom-color: rgba($white, 0.07)

.tooltip__box--special-right
  left: 100%
  padding-left: 10px
  top: calc(50% - 15px)

  .tooltip__container
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50)

.tooltip__box--special-down
  left: 125%
  padding-left: 10px
  bottom: 7px

  .tooltip__container
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50)

.tooltip__box--special-top-right
  left: 100%
  padding-left: 10px
  top: -$space-st

  .tooltip__container
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50)

.tooltip__box--attach-right
  right: calc(50% - 20px)

  .tooltip__container
    &::before,
    &::after
      right: 10px

.tooltip__box--attach-left
  left: calc(50% - 30px)

  .tooltip__container
    &::before,
    &::after
      left: 20px


.tooltip-enter-active
  transition: opacity 0.3s

.tooltip-enter
  opacity: 0
</style>
