<template lang="pug">
  Card.subscription-card(v-if="showSubscription || isLoading || error")
    template(slot="header")
      .subscription-card__header
        .subscription-card__header-item
          span.subscription-card__big-text Taxa de manutenção

    Loading(v-if="isLoading")

    div(v-else-if="error").subscription-card__body
      Badge(type="error") Falha ao carregar informação
      .subscription-card__line
        span Canal:
        span #squad-retencao-help
      .subscription-card__line
        span Mensagem de erro:
      span {{ error }}

    .subscription-card__body(v-else-if="showSubscription")
      .subscription-card__line
        span.subscription-card__body-title Situação
        Badge(:type="badgeType").order-card__status-badge {{ subscriptionStatusLabel }}
      .subscription-card__line
        span Forma de pagamento
        span {{ paymentMethod }}
      .subscription-card__line
        span Valor
        span {{ amount }}
      .subscription-card__line
        span Quantidade paga
        span {{ charges }}
      .subscription-card__line
        span Parcelas mínimas
        span {{ subscription.minInstallments }}
      .subscription-card__line
        span Próximo vencimento
        span {{ subscription.currentPeriodEnd | momentformat('L') }}
      template(v-if="subscription.cancellationFeeFormatted")
        .subscription-card__separator
        .subscription-card__line.subscription-card__important
          span Taxa de cancelamento
          span {{ subscription.cancellationFeeFormatted }}
</template>

<script>
import Rollbar from '@/common_modules/tools/Rollbar';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import SubscriptionFetchQuery from '@/plugins/apollo/querobolsa/queries/subscriptionFetch';
import Loading from '@/components/common/Loading';

function apolloErrorHandler(error) {
  this.error = error.message;

  Rollbar.error('[SubscriptionDanger] SubscriptionFetch GraphAPI error', {
    error,
  });
}

export default {
  components: {
    Card,
    Badge,
    Loading,
  },
  props: {
    order: {
      type: Object,
      required: false,
      default: null,
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    subscription: null,
    error: null,
  }),
  computed: {
    showSubscription() {
      return this.subscription;
    },
    isLoading() {
      return this.$apollo.queries.SubscriptionFetch.loading;
    },
    paymentMethod() {
      const translatedPaymentMethod = {
        credit_card: 'Cartão de crédito',
        boleto: 'Boleto',
        pix: 'Pix',
      };

      return translatedPaymentMethod[this.subscription.paymentMethod];
    },
    version() {
      const translatedVersion = {
        monthly: 'mês',
        semiannual: 'semestre',
        annual: 'ano',
      };

      return translatedVersion[this.subscription.version];
    },
    amount() {
      return `${this.subscription.amountFormatted}/${this.version}`;
    },
    charges() {
      return `${this.subscription.paidCount} de
        ${this.subscription.installments}`;
    },
    subscriptionStatusLabel() {
      return {
        active: 'Ativo',
        canceled: 'Cancelado',
        defaulter: 'Inadimplente',
        dropped_out: 'Evadido',
        finished: 'Finalizado',
        trial: 'Ativo',
      }[this.subscription.status];
    },
    badgeType() {
      switch (this.subscription.status) {
        case 'active':
        case 'trial':
          return 'success';
        case 'canceled':
        case 'defaulter':
        case 'dropped_out':
          return 'error';
        case 'finished':
          return 'primary';
        default:
          return 'gray';
      }
    },
  },
  apollo: {
    SubscriptionFetch: {
      manual: true,
      prefetch: true,
      addTypename: true,
      query: SubscriptionFetchQuery,
      client: 'querobolsa',
      fetchPolicy: 'network-only',
      skip() {
        return !this.order;
      },
      variables() {
        return { orderId: this.order.id };
      },
      result(response) {
        this.subscription = response.data.subscriptionFetch;
      },
      error: apolloErrorHandler,
    },
  },
};
</script>

<style lang="sass" scoped>
.subscription-card
  color: $silver
  margin-bottom: $space-st

.subscription-card__header
  align-items: flex-start
  display: flex
  font-size: rem(18)
  font-weight: bold
  justify-content: space-between
  position: relative
  width: 100%

.subscription-card__body
  color: $silver-gray
  padding: $space-s 0

.subscription-card__body-title
  color: $white
  font-size: rem(16)

.subscription-card__line
  align-items: center
  display: flex
  justify-content: space-between
  margin: $space-s 0

.subscription-card__important
  color: $white
  font-weight: bold

.subscription-card__separator
  background-color: $star-gray
  height: 2px

.subscription-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold

.subscription-card__payment_method_badge
  margin-left: 0
</style>
