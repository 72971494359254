const HIDDEN = 'hidden';
const IN = 'in';
const OUT = 'out';

// Notification will be visible for 5 seconds
const NOTIFICATION_CLOSE_TIMEOUT = 5000;
// Notification will be removed after half second
const NOTIFICATION_REMOVE_TIMEOUT = 500;

export const state = () => ({
  notifications: [],
});

export const actions = {
  _newNotification({ commit, state, dispatch }, notification) {
    notification = {
      ...notification,
      id: state.notifications.length,
      status: IN,
    };

    notification.timeout = setTimeout(
      () => dispatch('hideNotification', { id: notification.id }),
      NOTIFICATION_CLOSE_TIMEOUT
    );

    commit('newNotification', notification);
  },

  hideNotification({ state, commit }, { id }) {
    const notification = { ...state.notifications.find(n => n.id === id) };

    commit('hideNotification', notification);

    notification.timeout = setTimeout(
      () => commit('removeNotification', notification),
      NOTIFICATION_REMOVE_TIMEOUT
    );
  },

  notifyError({ dispatch }, message) {
    const notification = {
      icon: 'i-error',
      title: 'Erro',
      message: message || 'Opa, tivemos um erro inesperado no OPA! :(',
    };
    dispatch('_newNotification', notification);
  },

  notifySuccess({ dispatch }, message) {
    const notification = {
      icon: 'i-success',
      message: message,
    };
    dispatch('_newNotification', notification);
  },

  notify({ dispatch }, { message, type = 'error' }) {
    const notification = {
      message,
      icon: type === 'error' ? 'i-error' : 'i-warning',
      title: type === 'error' ? 'Erro' : '',
    };
    dispatch('_newNotification', notification);
  },
};

export const mutations = {
  newNotification(state, notification) {
    state.notifications.push(notification);
  },

  hideNotification(state, { id }) {
    const notification = state.notifications.find(n => n.id === id);

    clearTimeout(notification.timeout);
    notification.status = OUT;
  },

  removeNotification(state, { id }) {
    const notification = state.notifications.find(n => n.id === id);
    notification.status = HIDDEN;
  },
};

export const getters = {
  emptyActiveNotifications(state, { activeNotifications }) {
    return activeNotifications.length === 0;
  },

  activeNotifications({ notifications }) {
    return notifications.filter(n => n.status !== HIDDEN);
  },
};
