<template lang="pug">
  div(@click="$emit('click')")
    TabHeader(title="Cobranças")
    .queropago-card__no-content(v-if="$apollo.queries.financialDataFetch.loading && !hasLoadError") Carregando...
    .queropago-card__no-content(v-else-if="hasLoadError") Erro ao conectar no Quero Pago
    div(v-else)
      .queropago-card__no-content(v-if="emptyBillingsList") Nenhuma cobrança encontrada
      .list(v-else)
        .list__item(
          v-for="(billing, index) in financialData.orderedBillings"
          v-bind:key="index"
          @click="showFinancialDataTab(billing)"
        )
          div
            .formatted-text {{ billing.course.name }}
            .formatted-text {{ billing.course.institution.name }}
            Badge(:type="statusBadgeType(billing)") {{ billing.translatedStatus }}
          Icon(svg-name="i-chevron-right" svg-class="list__icon")
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Badge from '@/components/common/Badge';
import Icon from '@/components/common/Icon';
import TabHeader from '@/components/common/TabHeader';
import QueropagoHelper from '@/helpers/QueropagoHelper';
import FinancialDataFetchQuery from '@/plugins/apollo/queropago/queries/financialDataFetch';
import Rollbar from '@/common_modules/tools/Rollbar';

export default {
  components: {
    TabHeader,
    Badge,
    Icon,
  },
  data() {
    return {
      hasLoadError: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profile']),
    ...mapState('Queropago', ['financialData']),
    emptyBillingsList() {
      return !this.financialData.orderedBillings.length;
    },
  },
  methods: {
    ...mapActions('Queropago', ['setFinancialData', 'financialDataTabClicked']),
    statusBadgeType(billing) {
      return QueropagoHelper.billingStatusBadgeType(billing);
    },
    showFinancialDataTab(billing) {
      this.financialDataTabClicked(billing);
    },
  },
  apollo: {
    financialDataFetch: {
      manual: true,
      prefetch: true,
      addTypename: true,
      query: FinancialDataFetchQuery,
      client: 'queropago',
      variables() {
        return {
          cpf: this.profile.cpf,
        };
      },
      result(response) {
        this.setFinancialData(response.data.student);
      },
      update(response) {
        this.setFinancialData(response.data.student);
      },
      error(error) {
        this.hasLoadError = true;
        Rollbar.error('Billings | GraphqlAPI error', { error });
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.formatted-text
  font-weight: bold
  margin-bottom: $space-s
</style>
