import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  getVouchers(baseUserId) {
    return HttpService.get('api/discount-code', { baseUserId });
  },
  reactivateVoucher(params) {
    return HttpService.post('api/discount-code/reactivate-voucher', params);
  },
};
