// This middleware is responsible for check
// current token and redirect to login if
// user is not authenticated.
// This middleware also join channel and load
// admin permissions.

import SocketService from '@/assets/javascripts/services/SocketService';
import axios from 'axios';
import { get } from 'lodash';

export default ({ app, store }) => {
  if (!store.getters['Admin/isAuthenticated']) {
    app.router.push('/login');
    return;
  }

  if (get(SocketService, 'channel.joinedOnce')) return;

  SocketService.join(store.getters['Admin/getId'])
    .then(admin => {
      store.commit('Admin/setAdmin', admin);
      store.dispatch('Permissions/getPermissions');
    })
    .catch(() => {
      store.dispatch('Admin/updateToken', { token: null });
      app.router.push('/login');
    });

  axios.defaults.baseURL = process.env.OPA_BACKEND_HOST;
  axios.defaults.headers.common['opa-token'] = generate_token();
  axios.defaults.headers.common['authorization'] =
    'Bearer ' + store.getters['Admin/getToken'].replace(/"/g, '');
  axios.defaults.headers.post['Content-Type'] = 'application/json';
};

function generate_token() {
  return simple_token() + simple_token() + simple_token();
}

function simple_token() {
  return Math.random()
    .toString(36)
    .substr(2);
}
