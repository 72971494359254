<template lang="pug">
  .offer-details
    TabHeader.offer-details__header(title="Detalhes e regras")
    .offer-details__body(ref="scroller")
      Card.opa__card(showFooterTemplate)
        template(slot="header")
          .opa__card-header Detalhes, observações e regras
        .offer-details__details-body(
          :class="detailsCardBodyClass"
          v-html="extraInfo.observations"
          ref="detailsCardBody"
        )
        template(slot="footer")
          ShowMore(v-model="showMore")

      Card.opa__card(v-if="extraInfo.universityTweets.length !== 0")
        template(slot="header")
          .offer-details__university-header
            h2 {{ params.data.course.campus.university.name }}
        .offer-details__university-body(v-for="tweet in extraInfo.universityTweets")
          .offer-details__university-tweet(v-html="tweet.content")
          i Atualizado em: {{ tweet.updatedAt | momentformat('L - LT') }}
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Card from '@/components/common/Card';
import ShowMore from '@/components/common/ShowMore';

export default {
  components: {
    TabHeader,
    Card,
    ShowMore,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    detailsCardBodyClass() {
      return this.showMore ? 'offer-details__details-body--more' : '';
    },
    extraInfo() {
      return this.params.data.extraInfo;
    },
  },
  watch: {
    showMore(value) {
      const detailsCardBody = this.$refs.detailsCardBody;
      if (value) {
        detailsCardBody.style.height = detailsCardBody.scrollHeight + 'px';
      } else {
        this.$refs.scroller.scrollTop = 0;
        detailsCardBody.style.height = null;
      }
    },
  },
};
</script>
