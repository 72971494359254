<template lang="pug">
  .expires-boleto(ref="form")
    TabHeader(:title="offerNames.course || '.'" :subTitle="offerNames.university || '.'")
    .expires-boleto__body
      .expires-boleto__title Expirar boleto

      .expires-boleto__message Você tem certeza que deseja expirar este boleto?
      .expires-boleto__message Atenção, essa ação é irreversível.

      .expires-boleto__label Observações (opcional)
      textarea.expires-boleto___textarea(
        v-model="reason"
        placeholder="Insira a observação aqui..."
      )

      Button.expires-boleto__button(
        type="positive"
        @click="expiresBoleto"
        :disabled="submited"
        :text="submitting ? 'Aguarde...' : 'Expirar boleto'"
        :submit="true"
        :loading="submitting")
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
  },
  data: () => ({
    reason: '',
    submitting: false,
    submited: false,
  }),
  computed: {
    ...mapGetters('Order', ['offerNames']),
  },
  methods: {
    ...mapActions('Order', ['submitExpiresBoletoClicked']),
    async expiresBoleto() {
      this.submitting = true;
      await this.submitExpiresBoletoClicked({ reason: this.reason });
      this.submitting = false;
    },
  },
};
</script>

<style lang="sass">
.expires-boleto
  display: flex
  flex-direction: column
  height: 100vh
  background-color: $space-gray

.expires-boleto__body
  overflow: auto
  padding: $space-m

.expires-boleto__title
  font-size: rem(18)
  font-weight: bold
  margin-bottom: $space-s

.expires-boleto___textarea
  @extend %opa-input
  margin-top: $space-s
  width: 100%
  height: 89px
  resize: none

.expires-boleto__label
  margin-top: $space-m

.expires-boleto__button
  margin-top: $space-m
  width: 100%
</style>
