<template lang="pug">
  BaseModal(v-if="showModal" @hide="hide")
    template(slot="header")
      .application-modal__header
        .application-modal__header-title Vestibular
        Badge(type="error") REPROVADO NO VESTIBULAR
    template(slot="body")
      .application-modal__body-title Dicas para realizar uma boa redação
      ul.application-modal__tips-list
        li(v-for="tip in tips") {{ tip }}
</template>

<script>
import BaseModal from '@/components/common/BaseModal';
import Badge from '@/components/common/Badge';

export default {
  components: {
    BaseModal,
    Badge,
  },

  props: {
    tips: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    showModal: false,
  }),

  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.application-modal__header
  align-items: center
  display: flex

.application-modal__header-title
  font-size: rem(16)
  font-weight: bold
  margin-right: $space-m

.application-modal__tips-list
  line-height: 1.8
  list-style-position: inside

.application-modal__body-title
  font-size: rem(14)
  font-weight: bold
  padding-bottom: $space-s
</style>
