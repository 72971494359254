<template lang="pug">
  .resend-digital-contract-tab
    TabHeader(title="Reenviar Contrato Digital")
    template
      form.resend-digital-contract__body(@submit.prevent="submit")
        template(slot="header")
        .opa__card-body
          .resend-digital-contract__label Selecione o e-mail para enviar o contrato
          Select.resend-digital-contract__select(
            placeholder="Escolha o e-mail"
            :list="emailsList"
            v-model="selectedEmail"
            label-property="value"
          )

          Button.resend-digital-contract__button(
            type="positive"
            :disabled="hasErrors"
            :text="submitting ? 'Aguarde...' : 'Enviar'"
            :submit="true"
            :loading="submitting"
          )
</template>

<script>
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapState, mapActions } from 'vuex';
import FollowUpAPI from '@/api/followUpAPI';

export default {
  components: {
    TabHeader,
    Select,
    Button,
  },

  data: () => {
    return {
      selectedEmail: null,
      submitting: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profile']),
    ...mapState('Order', ['followUp']),

    emailsList() {
      return this.profile.emails.map(email => {
        return { value: email };
      });
    },
    hasErrors() {
      return !this.selectedEmail;
    },
  },
  methods: {
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),

    submit() {
      this.submitting = true;

      FollowUpAPI.resendDigitalContract({
        followUpId: this.followUp.id,
        email: this.selectedEmail.value,
      })
        .then(() => {
          this.notifySuccess('Contrato digital reenviado!');
        })
        .catch(() => {
          this.notifyError(
            'O reenvio do contrato falhou. Por favor, contate o suporte.'
          );
        })
        .finally(() => {
          this.submitting = false;
          this.tabGoBack();
        });
    },
  },
};
</script>

<style lang="sass">
.resend-digital-contract__body
  padding: $space-st

.resend-digital-contract__label
  margin-bottom: $space-s
  font-weight: bold

.resend-digital-contract__select
  margin-bottom: $space-m

.resend-digital-contract__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
