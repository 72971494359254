<template lang="pug">
  div
    TabHeader(title="LGPD - Exclusão de Cadastro")
    .lgpd-delete-register__body
      span.lgpd-delete-register__label.opa-bold LGPD - Exclusão de Cadastro
      span Ao deletar o perfil, o aluno perderá acesso a plataforma e todos os dados serão apagados.

      .lgpd-delete-register__checkbox
        Checkbox(
          v-model="fields.deleteRegister"
          label="Confirmo que desejo excluir esse cadastro."
        )

      .lgpd-delete-register__label Observações
        textarea.lgpd-delete-register__textarea(
          v-model="fields.observations"
          placeholder="Qual o motivo da exclusão do cadastro?"
        )

      Alert.lgpd-delete-register-alert(
        v-if="!fields.deleteRegister"
        :dismissable="false"
        type="error"
        message="Essa ação é irreversível. Por favor, confirme que deseja excluir o cadastro."
      )

      Button.lgpd-delete-register__button(
        type="positive"
        :disabled="!fields.deleteRegister"
        @click="submit"
        text="Excluir Cadastro"
      )
</template>

<script>
import Alert from '@/components/common/Alert';
import TabHeader from '@/components/common/TabHeader';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
import ProfileService from '@/assets/javascripts/services/ProfileService';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    Checkbox,
    Button,
    Alert,
  },
  data() {
    return {
      fields: {
        deleteRegister: false,
        observations: null,
      },
    };
  },
  computed: {
    ...mapState('Profile', ['user']),
    ...mapGetters('UserSearch', ['inputValue']),
  },
  methods: {
    ...mapActions('Notifications', ['notifySuccess', 'notifyError']),
    ...mapActions('RightPanel', ['hidePanel']),
    ...mapActions('Omni', ['ticketClosed']),
    ...mapActions('UserSearch', ['searchInputed']),

    async submit() {
      const baseUserId = this.user.base_user;
      const observations = this.fields.observations;

      try {
        await ProfileService.lgpdDeleteRegister({
          baseUserId,
          observations,
        });
        this.notifySuccess('Cadastro excluído com sucesso');
        this.hidePanel();
        this.ticketClosed();
        this.searchInputed(this.inputValue);
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao excluir o cadastro.';
        this.notifyError(message);
      }
    },
  },
};
</script>

<style lang="sass">
.lgpd-delete-register__label
  margin-bottom: $space-s
  font-weight: bold

.lgpd-delete-register__body
  display: flex
  flex-direction: column
  margin-top: $space-m
  padding: $space-m $space-st

.lgpd-delete-register__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-m

.lgpd-delete-register__checkbox
  padding-top: $space-st
  padding-bottom: $space-m

.lgpd-delete-register__textarea
  @extend %opa-input
  width: 100%
  margin-bottom: $space-s
  height: 89px
  resize: none
</style>
