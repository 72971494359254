class QueropagoHelper {
  billingStatusBadgeType(billing) {
    switch (billing.translatedStatus) {
      case 'Ativa':
        return 'green';
      case 'Cancelada':
        return 'yellow';
      case 'Interrompida':
        return 'red';
      case 'Admissão':
        return 'blue';
      default:
        return 'gray';
    }
  }

  billStatusBadgeType(bill) {
    switch (bill.translatedStatus) {
      case 'Paga':
        return 'green';
      case 'Em aberto':
        return 'blue';
      case 'Atrasada':
        return 'red';
      case 'Ignorada':
        return 'orange';
      default:
        return 'gray';
    }
  }
}

export default new QueropagoHelper();
