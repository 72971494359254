import { levelIsPostgraduate } from '@/assets/javascripts/data/levels';

const installmentsType = level => {
  return levelIsPostgraduate(level) ? 'Parcelamento' : 'Mensalidade';
};

const installmentsUnit = level => {
  return levelIsPostgraduate(level) ? 'Parcela' : 'Mensalidade';
};

const installmentsPrefix = (level, maxPayments) => {
  return levelIsPostgraduate(level) ? `${maxPayments}x de` : '';
};

export { installmentsPrefix, installmentsType, installmentsUnit };
