<template lang="pug">
  .course-details
    Card.queropago-card
      template(slot="header")
        .queropago-card__header
          span Curso
          CopyIcon(:clipboard-text="clipboardCourseText")
      .queropago-card__body
        .queropago-card__item
          strong Nome:
          .queropago-card__item-content.long-text {{currentBilling.course.name}}
        .queropago-card__item
          strong Modalidade:
          .queropago-card__item-content {{currentBilling.course.kind.name}}
        .queropago-card__item
          strong Turno:
          .queropago-card__item-content {{currentBilling.course.shift.name}}
        .queropago-card__item
          strong Nível:
          .queropago-card__item-content {{currentBilling.course.level.name}}
        .queropago-card__item
          strong Instituição:
          .queropago-card__item-content.long-text {{currentBilling.course.institution.name}}
        .queropago-card__item
          strong Campus:
          .queropago-card__item-content.long-text {{currentBilling.course.campus.name}}
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import CopyIcon from '@/components/Profile/Scholarship/CopyIcon';

export default {
  components: {
    Card,
    CopyIcon,
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
    clipboardCourseText() {
      const courseData = this.currentBilling.course;
      const courseName = `Nome: ${courseData.name}\n`;
      const kindName = `Modalidade: ${courseData.kind.name}\n`;
      const shiftName = `Turno: ${courseData.shift.name}\n`;
      const levelName = `Level: ${courseData.level.name}\n`;
      const institutionName = `Instituição: ${courseData.institution.name}\n`;
      const campusName = `Campus: ${courseData.campus.name}`;
      const result =
        courseName +
        kindName +
        shiftName +
        levelName +
        institutionName +
        campusName;

      return result;
    },
  },
};
</script>

<style lang="sass" scoped>
.long-text
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
