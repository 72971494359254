const kongUrl = process.env.KONG_URL;

let url = svg => `${kongUrl}/${svg}`;

const qbOrigins = ['Quero Bolsa', 'OPA', 'APP', 'Guia do Estudante Bolsas'];
const orderOrigins = {
  EAD: {
    name: 'EAD',
    logo: url('logo-ead-white.svg'),
  },
  'Mundo Vestibular': {
    name: 'Mundo Vestibular',
    logo: url('logo-mundovestibular-white.svg'),
  },
  'Guia da Carreira': {
    name: 'Guia da Carreira',
    logo: url('logo-guiadacarreira-white.svg'),
  },
  'Quero Bolsa': {
    name: 'Quero Bolsa',
    logo: url('logo-querobolsa-dark.svg'),
  },
  Skola: {
    name: 'Skola',
    logo: url('logo-skola.svg'),
  },
};

function getOrderOriginDetails(orderOrigin) {
  const NULL_ORIGIN_FALLBACK = 'Quero Bolsa';
  const UNMAPPED_ORIGIN_FALLBACK = 'Skola';

  let origin = orderOrigin ? orderOrigin.origin : NULL_ORIGIN_FALLBACK;
  const isQBOrigin = qbOrigins.includes(origin);
  origin = isQBOrigin ? 'Quero Bolsa' : origin;

  return orderOrigins[origin] || orderOrigins[UNMAPPED_ORIGIN_FALLBACK];
}

const orderOriginDetails = orderOrigin => getOrderOriginDetails(orderOrigin);

export { orderOriginDetails };
