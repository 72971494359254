import { without, sortBy } from 'lodash';
import ticketAPI from '@/api/ticketAPI';
import ConversationInfoModel from '@/models/Tickets/ConversationInfoModel';
import { canFetchNewTicket } from '@/opa-modules/Tickets/business';
import * as WhatsappUsername from '@/assets/javascripts/data/whatsapp/whatsappUsername';

export const state = () => {
  return {
    data: {
      tickets: {},
    },
    ticketsCount: null,
    currentPage: 0,
    loadingTickets: false,
    loadedAllTickets: false,
    customerIdList: null,
    customerIdSelected: null,
    currentStatusFilter: 'draft',
  };
};

export const getters = {
  ticketSelected: ({ data, customerIdSelected }) =>
    data.tickets[customerIdSelected],

  ticketList: ({ data, customerIdList }) => {
    if (!customerIdList) return;

    const list = customerIdList.map(customerId => data.tickets[customerId]);

    return sortBy(list, ticket => {
      const hasUnresolvedConversation = ConversationInfoModel.hasUnresolvedConversation(
        ticket.conversation_info
      );

      if (hasUnresolvedConversation) return 0;
      if (ticket.draft) return 1;
      return 2;
    });
  },
  formatWhatsappChats: () => chats => {
    return chats.map(chat => ({
      id: chat.id,
      whatsappUsername: chat.whatsapp_username,
      host: WhatsappUsername.getWhatsappHost(chat.whatsapp_username),
      whatsappUsernameTranslated: WhatsappUsername.translate(
        chat.whatsapp_username
      ),
    }));
  },
};

export const mutations = {
  setCustomerIdSelected(state, id) {
    state.customerIdSelected = id;
  },

  setCurrentStatusFilter(state, status) {
    state.currentStatusFilter = status;
  },

  setCurrentPage(state, newPage) {
    state.currentPage = newPage;
  },

  setCustomerIdList(state, customerIdList) {
    state.customerIdList = customerIdList;
  },

  setTicketData(state, ticketData) {
    state.data.tickets = ticketData;
  },

  updateTicketInfo(state, { customerId, info }) {
    Object.assign(state.data.tickets[customerId], info);
  },

  setConversationInfo(state, { customerId, conversationInfo }) {
    state.data.tickets[customerId].conversation_info = conversationInfo;
  },

  setTicketsCount(state, ticketsCount) {
    state.ticketsCount = ticketsCount;
  },

  updateTicketChat(state, { customerId, whatsappUsername, chat }) {
    const ticket = state.data.tickets[customerId];
    const conversation = ticket.conversation_info.find(
      ({ key }) => key === whatsappUsername
    );

    if (!conversation) return;

    Object.assign(conversation.chat, chat);
  },

  setLoadingTickets(state, loadingTickets) {
    state.loadingTickets = loadingTickets;
  },

  setLoadedAllTickets(state, loadedAllTickets) {
    state.loadedAllTickets = loadedAllTickets;
  },
};

export const actions = {
  ticketClicked({ dispatch }, { customer_id }) {
    dispatch('selectTicket', customer_id);
  },

  statusFilterSelected({ commit, dispatch }, status) {
    dispatch('reset');
    commit('setCurrentStatusFilter', status);
    dispatch('loadTickets');
  },

  async fetchTicketClicked({ dispatch, getters }, params) {
    const { ticketList } = getters;
    let ticket, error;

    if (!canFetchNewTicket(ticketList)) {
      const message =
        'Você ultrapassou o limite de atendimentos pendentes de resposta.';

      return dispatch(
        'StoreManager/notify',
        { message, type: 'alert' },
        { root: true }
      );
    }

    try {
      const response = await ticketAPI.fetchTicket(params);
      ticket = response.data.ticket;

      if (!ticket) {
        error = 'Fila vazia!';
      }
    } catch (request_error) {
      error = request_error;
    }

    if (error) {
      return dispatch(
        'StoreManager/notify',
        { message: error, type: 'alert' },
        { root: true }
      );
    }

    dispatch('addNewTicket', ticket);
    dispatch('selectTicket', ticket.customer_id);
  },

  addNewTicket({ state, commit }, ticket) {
    const { customer_id } = ticket;
    const customerIdList = state.customerIdList || [];

    const ticketAlreadyInList = customerIdList.includes(customer_id);

    if (!ticketAlreadyInList) {
      commit('setCustomerIdList', [customer_id, ...customerIdList]);
    }

    commit('setTicketData', {
      ...state.data.tickets,
      [customer_id]: ticket,
    });
  },

  messageReceived({ commit, state }, { customerId, message }) {
    const ticket = state.data.tickets[customerId];
    if (!ticket) return;

    const conversationInfo = ConversationInfoModel.updateByMessage(
      ticket.conversation_info,
      message
    );

    commit('setConversationInfo', { customerId, conversationInfo });
  },

  reset({ commit }) {
    commit('setCustomerIdList', null);
    commit('setTicketData', {});
    commit('setCurrentPage', 0);
    commit('setLoadedAllTickets', false);
    commit('setLoadingTickets', false);
  },

  async initialize({ dispatch, commit, state }) {
    dispatch('reset');
    dispatch('StoreManager/Tickets/pageEnter', null, { root: true });

    await dispatch('loadTickets');

    ticketAPI.listenForNewTickets(ticket => {
      dispatch('addNewTicket', ticket);

      dispatch('StoreManager/Tickets/ticketUpdate', ticket, {
        root: true,
      });
    });

    ticketAPI.listenForUpdateTicket(ticket => {
      commit('setTicketData', {
        ...state.data.tickets,
        [ticket.customer_id]: ticket,
      });

      dispatch('StoreManager/Tickets/ticketUpdate', ticket, {
        root: true,
      });
    });

    ticketAPI.listenForCloseTicket(({ customer_id }) => {
      dispatch('removeTicket', customer_id);
    });
  },

  terminate({ state, dispatch }) {
    dispatch('deselectedTicket');
    ticketAPI.stopListening(state.type);
  },

  async loadTickets({ commit, state }) {
    if (state.loadingTickets) return;
    commit('setLoadingTickets', true);

    const nextPage = state.currentPage + 1;
    const {
      data: { tickets, loaded_all_tickets, total_entries },
    } = await ticketAPI.getTickets(state.currentStatusFilter, nextPage);

    const oldCustomerIdList = state.customerIdList || [];
    const oldTicketData = state.data.tickets;

    const { newCustomerIdList, newTicketData } = processNewTickets(
      oldCustomerIdList,
      tickets
    );

    commit('setCurrentPage', nextPage);
    commit('setCustomerIdList', [...oldCustomerIdList, ...newCustomerIdList]);
    commit('setTicketData', { ...oldTicketData, ...newTicketData });
    commit('setTicketsCount', total_entries);
    commit('setLoadingTickets', false);

    if (loaded_all_tickets) {
      commit('setLoadedAllTickets', true);
    }
  },

  async getDraftCustomerContactsAndChats({ getters }, customerId) {
    customerId = customerId || getters.ticketSelected.customer_id;
    const { data } = await ticketAPI.getDraftCustomerContactsAndChats(
      customerId
    );

    return data;
  },

  async registerContactsSubmited(
    { dispatch },
    {
      allContacts,
      contactReasonId,
      contactTypes,
      customerContactIds,
      customerId,
      observations,
      whatsappUsername,
      attachments,
    }
  ) {
    await ticketAPI.registerContacts(
      customerContactIds,
      contactReasonId,
      observations,
      whatsappUsername,
      attachments
    );

    dispatch('contactClosed', {
      allContacts,
      contactTypes,
      customerId,
      whatsappUsername,
      emitter: 'register',
    });
  },

  async startActiveTicket({ dispatch }, whatsappUsername) {
    let ticket = null;

    try {
      const {
        data: { result },
      } = await ticketAPI.getActiveTicketForWhatsapp(whatsappUsername);

      ticket = result;
    } catch (error) {
      dispatch(
        'StoreManager/notify',
        {
          message: error,
          type: 'warning',
        },
        { root: true }
      );

      return;
    }

    dispatch('addNewTicket', ticket);
    dispatch('selectTicket', ticket.customer_id);
  },

  async startCustomerContact({ dispatch }, { customerId, contactType }) {
    const ticket = await ticketAPI.startCustomerContact(
      customerId,
      contactType
    );

    dispatch('addNewTicket', ticket);
  },

  removeTicket({ state, commit, dispatch }, customerId) {
    const customerIdList = without(state.customerIdList, customerId);
    const onCustomerSelected = customerId === state.customerIdSelected;

    commit('setCustomerIdList', customerIdList);

    if (onCustomerSelected) {
      dispatch('deselectedTicket');
    }
  },

  selectFirstTicket({ getters, dispatch }) {
    const firstTicket = getters.ticketList[0];

    if (firstTicket) {
      dispatch('selectTicket', firstTicket.customer_id);
    } else {
      dispatch('deselectedTicket');
    }
  },

  selectTicket({ commit, dispatch }, customerId) {
    commit('setCustomerIdSelected', customerId);
    dispatch('storeManagerSelectedTicket');
  },

  reselectTicket({ dispatch, state }) {
    if (state.customerIdSelected) {
      dispatch('storeManagerSelectedTicket');
    } else {
      dispatch('deselectedTicket');
    }
  },

  deselectedTicket({ dispatch, commit }) {
    commit('setCustomerIdSelected', null);
    dispatch('StoreManager/Tickets/deselectedTicket', null, { root: true });
  },

  storeManagerSelectedTicket({ dispatch, getters }) {
    dispatch(
      'StoreManager/Tickets/selectedTicket',
      {
        activeTicket: getters.ticketSelected.active,
        draftTicket: getters.ticketSelected.draft,
        baseUserId: getters.ticketSelected.base_user_id,
        conversationInfo: getters.ticketSelected.conversation_info,
        tenantId: getters.ticketSelected.tenant_id,
      },
      { root: true }
    );
  },

  async updatedInfo({ commit, state, dispatch }, params) {
    const changedBaseUser = !!params.newBaseUserId;

    if (changedBaseUser) {
      await dispatch('changedBaseUser', params);
      return;
    }

    const customerId = state.customerIdSelected;
    commit('updateTicketInfo', { customerId, info: { name: params.name } });
  },

  async changedBaseUser({ commit, state, dispatch }, params) {
    const { customerIdSelected } = state;
    const paramsCustomerId = params.customerId;
    const changedCustomer =
      !!paramsCustomerId && customerIdSelected !== paramsCustomerId;

    await ticketAPI.changeBaseUser(customerIdSelected, params.newBaseUserId);

    if (changedCustomer) {
      const {
        data: { result: newTicket },
      } = await ticketAPI.getTicketFor(paramsCustomerId);

      dispatch('replaceTicket', { customerId: customerIdSelected, newTicket });
      dispatch('selectTicket', newTicket.customer_id);
    } else {
      const newInfo = {
        name: params.name,
        base_user_id: params.newBaseUserId,
      };
      commit('updateTicketInfo', {
        customerId: customerIdSelected,
        info: newInfo,
      });
    }
  },

  async replaceTicket({ commit, state }, { customerId, newTicket }) {
    const { customerIdList, data } = state;
    const newCustomerIdList = replace(
      customerIdList,
      customerId,
      newTicket.customer_id
    );

    commit('setCustomerIdList', newCustomerIdList);
    commit('setTicketData', {
      ...data.tickets,
      [newTicket.customer_id]: newTicket,
    });
  },

  async contactClosed(
    { dispatch, state },
    { allContacts, contactTypes, customerId, whatsappUsername, emitter }
  ) {
    const ticket = state.data.tickets[customerId];
    if (!ticket) return;

    await dispatch('updateStateForContactClosed', {
      allContacts,
      customerId,
      whatsappUsername,
      emitter,
    });

    dispatch(
      'StoreManager/Tickets/contactClosed',
      { allContacts, contactTypes, whatsappUsername, emitter, ticket },
      { root: true }
    );
  },

  updateStateForContactClosed(
    { dispatch },
    { allContacts, customerId, whatsappUsername, emitter }
  ) {
    const onCustomerSelected = customerId === state.customerIdSelected;

    if (allContacts) {
      dispatch('removeTicket', customerId);

      if (onCustomerSelected) dispatch('selectFirstTicket');
    } else {
      dispatch('updateTicketForContactClosed', {
        customerId,
        whatsappUsername,
        emitter,
      });
    }
  },

  updateTicketForContactClosed(
    { state, commit },
    { customerId, whatsappUsername, emitter }
  ) {
    if (emitter === 'transfer') {
      const chat = { admin_user_id: null };
      commit('updateTicketChat', { customerId, whatsappUsername, chat });
      return;
    }

    const newInfo = {};
    const { conversation_info } = state.data.tickets[customerId];

    newInfo.conversation_info = conversation_info.filter(
      ({ key }) => whatsappUsername !== key
    );

    commit('updateTicketInfo', {
      customerId: customerId,
      info: newInfo,
    });
  },
};

function replace(list, oldItem, newItem) {
  list = [...list];
  const index = list.indexOf(oldItem);
  if (index == -1) return list;

  list.splice(index, 1, newItem);
  return list;
}

function processNewTickets(customerIdList, tickets) {
  const ticketNotLoaded = ticket => {
    return !customerIdList.includes(ticket.customer_id);
  };
  const filteredTickets = tickets.filter(ticketNotLoaded);

  const newCustomerIdList = filteredTickets.map(
    ({ customer_id }) => customer_id
  );
  const newTicketData = filteredTickets.reduce(
    (acc, ticket) => ({ ...acc, [ticket.customer_id]: ticket }),
    {}
  );

  return { newCustomerIdList, newTicketData };
}
