import OfferSearchModel from '@/models/OfferSearch';

import { last, isEmpty, mapValues, omit, pick, find, flatMap } from 'lodash';
import { resolveAddress } from '@/assets/javascripts/modules/gMaps.js';
import Rollbar from '@/common_modules/tools/Rollbar';

const USER_GIVEN_INFO_KIND_MAP = {
  ead: 'A distância (EaD)',
  ['a-distancia-ead']: 'A distância (EaD)',
  semipresencial: null,
  presencial: 'Presencial',
  Qualquer: null,
  NA: null,
};
const USER_GIVEN_INFO_SHIFT_MAP = {
  matutino: 'Manhã',
  noturno: 'Noite',
  NA: null,
  Manhã: 'Manhã',
  Noite: 'Noite',
  Tarde: 'Tarde',
  Qualquer: null,
};
const cachedPosition = {};

const OfferSearchConverter = {
  getDefaultInputValues() {
    return {
      magicInput: {
        selecteds: [],
      },
      shifts: [],
      kinds: [],
      semesters: [],
      price: 10000,
      distance: 10,
    };
  },
  async inputValuesToSelectedParams(inputValues) {
    if (isEmpty(inputValues)) return;

    let params = {
      ...omit(inputValues, ['magicInput']),
      ...parseMagicInputValuesForSelecteParams(inputValues.magicInput),
    };

    params.location = await parsePlaceForSelectedParams(params.location);

    return params;
  },
  selectedParamsToSearchParams(params) {
    return mapValues(params, getDataFromArray);
  },
  async offerSearchCacheToInputValues({ cacheData }, filterOptions) {
    const { userGivenInfo } = cacheData;

    if (userGivenInfo) {
      return userGivenInfoToInputValues(userGivenInfo, filterOptions);
    } else {
      return cacheDataToInputValues(cacheData);
    }
  },
};

async function userGivenInfoToInputValues(userGivenInfo, filterOptions) {
  checkUserGivenInfoValue(userGivenInfo);

  const placeAndCourseParams = [
    ['places', userGivenInfo.location],
    ['courses', userGivenInfo.course],
  ];
  const placeAndCoursePromises = placeAndCourseParams.map(
    ([searchKey, searchValue]) => {
      if (!searchValue) return;
      return OfferSearchModel.searchFor(searchKey, searchValue).then(
        results => results[0]
      );
    }
  );

  const [place, course] = await Promise.all(placeAndCoursePromises);
  const kind = find(filterOptions.kinds, {
    title: USER_GIVEN_INFO_KIND_MAP[userGivenInfo.kind],
  });
  const shift = find(filterOptions.shifts, {
    title: USER_GIVEN_INFO_SHIFT_MAP[userGivenInfo.shift],
  });

  const magicInputValue = getMagicInputValue({
    location: place,
    courses: course ? [course] : null,
  });
  const inputValues = OfferSearchConverter.getDefaultInputValues();

  return {
    ...inputValues,
    magicInput: magicInputValue,
    kinds: kind ? [kind] : [],
    shifts: shift ? [shift] : [],
  };
}

function cacheDataToInputValues(cacheData) {
  const magicInputRelatedKeys = [
    'courses',
    'durationRanges',
    'levels',
    'location',
    'universities',
  ];
  const magicInputRelatedValues = pick(cacheData, magicInputRelatedKeys);
  const magicInputValue = getMagicInputValue(magicInputRelatedValues);

  return {
    ...omit(cacheData, magicInputRelatedKeys),
    magicInput: magicInputValue,
  };
}

function getMagicInputValue({
  courses,
  universities,
  levels,
  durationRanges,
  location,
}) {
  const magicInputSelectedsBase = {
    course: courses || [],
    university: universities || [],
    level: levels || [],
    durationRange: durationRanges || [],
  };

  if (!isEmpty(location)) {
    magicInputSelectedsBase.place = [location];
  }

  const magicInputSelecteds = flatMap(magicInputSelectedsBase, (values, key) =>
    values.map(value => [key, value])
  );

  return { selecteds: magicInputSelecteds, selecting: [] };
}

function parseMagicInputValuesForSelecteParams({ selecteds = [] }) {
  selecteds = selecteds.reduce(
    (acc, [type, value]) => ({
      ...acc,
      [type]: [...(acc[type] || []), value],
    }),
    {}
  );

  return {
    courses: selecteds.course || [],
    location: last(selecteds.place),
    universities: selecteds.university || [],
    levels: selecteds.level || [],
    durationRanges: selecteds.durationRange || [],
    uuid: last(selecteds.uuid),
  };
}

async function parsePlaceForSelectedParams(place) {
  if (isEmpty(place)) return {};
  const { title, data } = place;
  const coordinateDataIsValid = data && data.lat && data.lng;

  let position;
  if (cachedPosition[title]) {
    position = cachedPosition[title];
  } else if (coordinateDataIsValid) {
    cachedPosition[title] = position = data;
  } else {
    const result = await resolveAddress(data);
    if (result && result.constructor !== Error) {
      position = result;
      cachedPosition[title] = position;
    } else {
      position = {};
    }
  }
  return { ...place, data: position };
}

function getDataFromArray(value) {
  if (Array.isArray(value)) {
    return flatMap(value, getDataFromObject);
  } else {
    return getDataFromObject(value);
  }
}

function getDataFromObject(value) {
  if (typeof value == 'object' && value.data) return value.data;
  return value;
}

function checkUserGivenInfoValue(userGivenInfo) {
  const isKindInvalid = !Object.keys(USER_GIVEN_INFO_KIND_MAP).includes(
    userGivenInfo.kind
  );
  const isShiftInvalid = !Object.keys(USER_GIVEN_INFO_SHIFT_MAP).includes(
    userGivenInfo.shift
  );

  if (!isKindInvalid && !isShiftInvalid) return;

  Rollbar.error(
    'OfferSearchConverter.offerSearchCacheToInputValues | received invalid data on offer_search_cache.user_given_info',
    {
      userGivenInfo,
      isKindInvalid,
      isShiftInvalid,
    }
  );
}

export default OfferSearchConverter;
