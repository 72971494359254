export const getters = {
  userEmails: (x, y, z, rootGetters) => rootGetters['Profile/userEmails'],
  userPhones: (x, y, z, rootGetters) => rootGetters['Profile/userPhones'],
  customerId: (x, y, z, rootGetters) => rootGetters['Profile/customerId'],
};

export const actions = {
  registeredOrder({ dispatch }) {
    dispatch('Profile/refreshProfile', null, { root: true });
    dispatch('OfferSearch/hideSearch', null, {
      root: true,
    });
  },
  startedOrder({ dispatch }) {
    dispatch('OfferSearch/hideSearch', null, {
      root: true,
    });
  },
};
