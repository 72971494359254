<template lang="pug">
  Card.price-card(:showFooterDetails="!hasParcelamento")
    template(slot="header")
      .price-card__header
        .price-card__header-item
          span.price-card__big-text {{ headerText }}
        Badge(v-if="hasRegressiveDiscount && !hasParcelamento" type="warning") Desconto Regressivo
        Badge(v-if="hasParcelamento && !hasRegressiveDiscount" type="blue") Parcelamento

    .price-card__body(v-if="hasParcelamento")
      .price-card__line.price-card__line--spaced
        span Valor sem Parcelamento
        span {{ offer.offeredPrice | brlcurrency }}
      .price-card__separator
      .price-card__line.price-card__line--spaced
        span Valor durante o curso (50%)
        span {{ offer.offeredPrice / 2 | brlcurrency }}
      .price-card__line.price-card__line--spaced
        span Valor após o curso (50%)
        span {{ offer.offeredPrice / 2 | brlcurrency }}

    .price-card__body(v-else)
      .price-card__line.price-card__line--spaced
        span Valor sem desconto
        span {{ installmentsPrefix }}{{ offer.fullPrice | brlcurrency }}
      .price-card__line.price-card__line--spaced
        span Desconto Quero Bolsa ({{ offer.discountPercentage }}%)
        span {{ offer.fullPrice - offer.offeredPrice | brlcurrency }}
      .price-card__separator
      .price-card__line.price-card__important.price-card__line--spaced
        span Valor com desconto
        span {{ installmentsPrefix }}{{ offer.offeredPrice | brlcurrency }}

    template(slot="infoFooter" v-if="offer.totalAbsoluteDiscount && !hasParcelamento")
      .price-card__line.price-card__line--spaced
        span Economia Total
        span {{ offer.totalAbsoluteDiscount | brlcurrency }}
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import {
  installmentsPrefix,
  installmentsType,
} from '@/assets/javascripts/formatters/Installments';

export default {
  components: {
    Card,
    Badge,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasRegressiveDiscount() {
      return this.offer.regressiveCommercialDiscount > 0;
    },
    hasParcelamento() {
      return this.offer.hasParcelamento;
    },
    headerText() {
      return installmentsType(this.course.level);
    },
    installmentsPrefix() {
      const {
        course: { level },
        offer: { maxPayments },
      } = this;

      const installmentsText = installmentsPrefix(level, maxPayments);

      return installmentsText ? `${installmentsText} ` : '';
    },
  },
};
</script>

<style lang="sass" scoped>
.price-card
  margin-bottom: $space-st
  color: $silver

.price-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.price-card__body
  color: $silver-gray
  padding: $space-s 0

.price-card__line
  margin: $space-s 0
  display: flex
  align-items: center

.price-card__line--spaced
  justify-content: space-between

.price-card__important
  font-weight: bold
  color: $white

.price-card__separator
  background-color: $star-gray
  height: 2px

.price-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold
</style>
