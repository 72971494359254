<template lang="pug">
  ClickableIcon(
    svg-name="i-file-copy"
    v-clipboard:copy="clipboardText"
  )
</template>

<script>
import ClickableIcon from '@/components/common/ClickableIcon';

export default {
  components: {
    ClickableIcon,
  },
  props: {
    clipboardText: {
      type: String,
      required: true,
    },
  },
};
</script>
