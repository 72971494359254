<template lang="pug">
  Card.offer-card(:showFooterDetails="hasUser && !isFreePreEnrollment")
    template(slot="header")
      .offer-card__header
        .offer-card__header-item
          span.offer-card__big-text Pré-matrícula
          Badge.offer-card__badge(v-if="remainingSeatsText" type="error") {{ remainingSeatsText }}

    template(v-if="isFreePreEnrollment")
      .offer-card__body.offer-card__body--only-text
        | Não há pré-matrícula (Matrícula Direta)

    template(v-else-if="!hasUser")
      .offer-card__body.offer-card__body--only-text
        | Para visualizar o valor da pré-matrícula, faça uma busca com o cadastro de um aluno.

    template(v-else-if="!hasPreEnrollmentFee")
      .offer-card__body.offer-card__body--only-text
        | Para este aluno, não existe registro de pré matrícula

    template(v-else)
      .offer-card__body
        .offer-card__line.offer-card__line--spaced
          span(v-if="offer.saleCampaign") Valor sem desconto
          span.offer-card__important(v-else="offer.saleCampaign") Valor
          span.offer-card__important {{ offer.preEnrollmentFee.value | brlcurrency }}
        div(v-if="offer.saleCampaign")
          .offer-card__line.offer-card__line--spaced
            span Desconto {{ offer.campaign }}
            span {{ saleCampaignDiscount | brlcurrency }}
          .offer-card__separator
          .offer-card__line.offer-card__line--spaced
            span.offer-card__important Valor com desconto {{ offer.campaign }}
            span.offer-card__important {{ feeWithSaleCampaignValue | brlcurrency }}

      template(slot="infoFooter")
        .offer-card__line.offer-card__line--spaced
          span Receita Ensino Superior
          span {{ offer.receitaIes | brlcurrency }}
        template(v-if="showCanibalizationWarning")
          .offer-card__separator
          .offer-card__line.offer-card__line--spaced
            span Comissão reduzida
            span {{ canibalizationRevenue | brlcurrency }}
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import FollowUpModel from '@/models/FollowUp';

export default {
  components: {
    Card,
    Badge,
    FollowUpModel,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    followUp: {
      type: Object,
      default: null,
    },
    hasUser: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isFreePreEnrollment() {
      if (this.followUp) {
        return FollowUpModel.isFreePreEnrollment(this.followUp);
      }
      return this.offer.freePreEnrollment;
    },
    hasPreEnrollmentFee() {
      return this.offer && this.offer.preEnrollmentFee;
    },
    canibalizationRevenue() {
      return this.offer.receitaIes * 0.1;
    },
    showCanibalizationWarning() {
      return !this.offer.showOnMainSearch;
    },
    saleCampaignDiscount() {
      return this.offer.preEnrollmentFee.discount_value;
    },
    remainingSeatsText() {
      const remainingSeats = this.offer.remainingSeats;
      if (typeof remainingSeats === 'string') return remainingSeats;
      if (typeof remainingSeats === 'number') {
        if (remainingSeats == 1) return '1 VAGA RESTANTE';
        if (remainingSeats <= 5) return `${remainingSeats} VAGAS RESTANTES`;
      }
      return null;
    },
    feeWithSaleCampaignValue() {
      return this.offer.preEnrollmentFee.value - this.saleCampaignDiscount;
    },
  },
};
</script>

<style lang="sass" scoped>
.offer-card
  margin-bottom: $space-st
  color: $silver

.offer-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.offer-card__badge
  position: absolute
  right: 0

.offer-card__body
  color: $silver-gray
  padding: $space-s 0

.offer-card__body--only-text
  padding: $space-m 0

.offer-card__line
  margin: $space-s 0
  align-items: center
  display: flex

.offer-card__line--spaced
  justify-content: space-between

.offer-card__important
  font-weight: bold
  color: $white

.offer-card__separator
  background-color: $star-gray
  height: 2px

.offer-card__header
  padding: $space-s 0
  position: relative

.offer-card__header-item
  display: flex
  justify-content: space-between

  &:not(:last-child)
    margin-bottom: $space-s

.offer-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold
</style>
