import Vue from 'vue';
import keycuts from '@/assets/javascripts/modules/keycuts';
import Amplitude from '@/common_modules/tools/Amplitude';

keycuts.initialize({ activationKey: '\\' });

Vue.directive('keycut', {
  inserted(el, { arg: shortcutKey, modifiers, value: bindFunction }) {
    const amplitudeLog = () => {
      Amplitude.logEvent('Atalho: ' + shortcutKey + ' utilizado.');
    };

    if (modifiers.click) {
      keycuts.add(shortcutKey, () => {
        amplitudeLog();
        el.click();
      });
      return;
    }

    if (modifiers.focus) {
      keycuts.add(shortcutKey, () => {
        amplitudeLog();
        el.focus();
      });
      return;
    }

    if (typeof bindFunction !== 'function') {
      throw new Error(
        'v-keycut directive: Received an invalid value, not a function!'
      );
    }

    keycuts.add(shortcutKey, () => {
      amplitudeLog();
      bindFunction();
    });
  },
  unbind(el, { arg: shortcutKey }) {
    keycuts.remove(shortcutKey);
  },
});
