<template lang="pug">
  .tab-header
    ClickableIcon.tab-header__icon(
      :svg-name="iconName"
      svg-alt="Retornar (\+V)"
      @click="tabGoBack"
      v-keycut:v="tabGoBack"
    )
    .tab-header__title-box
      h3.tab-header__title(:title="title") {{ title }}
      .tab-header__sub-title(v-if="subTitle" :title="subTitle") {{ subTitle }}
    slot(v-if="$slots.default")
    .tab-header__spacer(v-else)
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ClickableIcon from '@/components/common/ClickableIcon';

export default {
  components: {
    ClickableIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('RightPanel', ['hasStackedTabs']),
    iconName() {
      return this.hasStackedTabs ? 'i-arrow-back' : 'i-close';
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabGoBack']),
  },
};
</script>
