export const actions = {
  pageEnter({ dispatch }) {
    dispatch('StoreManager/resetRightPanel', null, { root: true });
  },
};

export const getters = {
  pageMode: (x, y, z, rootGetters) =>
    rootGetters['StoreManager/currentPage'] === 'offersearch',
};
