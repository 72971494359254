<template lang="pug">
  div
    .register-order-rg__label RG *
    input.register-order-rg__input(
      ref="document"
      type="text"
      name="document"
      v-model="value.document"
      placeholder="Ex: 108.032.123"
      autocomplete="off"
    )
    .register-order-rg__label Orgão expedidor *
    input.register-order-rg__input(
      ref="documentExpeditor"
      type="text"
      name="documentExpeditor"
      v-model="value.documentExpeditor"
      placeholder="Ex: SSP"
      autocomplete="off"
    )
</template>

<script>
import Checkbox from '@/components/common/Checkbox';
import Select from '@/components/common/Select';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Checkbox,
    Cleave,
    Select,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasErrors() {
      return !this.value.document || !this.value.documentExpeditor;
    },
  },
  watch: {
    hasErrors() {
      this.$emit('input', {
        ...this.value,
        hasErrors: this.hasErrors,
      });
    },
  },
};
</script>

<style lang="sass">
.register-order-rg__label
  margin-bottom: $space-s

.register-order-rg__input
  @extend %opa-input
  width: 100%
  margin-bottom: $space-st
</style>
