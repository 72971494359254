import _ from 'lodash';
import newState from './state';

export default {
  reset: state => {
    Object.assign(state, {
      ...newState(),
      setedListeners: state.setedListeners,
    });
  },
  setQueueOptions: (state, queueOptions) => (state.queueOptions = queueOptions),
  setQueueOptionSelected: (state, queueOption) => {
    if (queueOption) {
      state.queueOptionSelected = { ...queueOption };
    } else {
      state.queueOptionSelected = null;
    }
  },
  mayUpdateSizeOfQueues: (state, { key, size }) => {
    const queues = state.queueOptions;
    const queueSelected = state.queueOptionSelected;

    if (queueSelected && queueSelected.key === key) {
      state.queueOptionSelected.size = size;
    }

    const updatedQueues = queues.map(queue => {
      if (queue.key === key) {
        return { ...queue, size };
      }

      return queue;
    });

    state.queueOptions = [...updatedQueues];
  },
  updateChatWithMessage(state, { message }) {
    const { chatSelected } = state;

    chatSelected.history.push({ ...message, type: 'message' });
    chatSelected.has_unread_messages = true;
    if (message.admin_user_id === null) {
      chatSelected.last_user_message_in_24_hours = true;
    }
  },
  updateMessageStatus(state, updatedStatus) {
    const { chat_id: chatId, message_id: messageId, status } = updatedStatus;
    const chatSelectedId = _.get(state, 'chatSelected.id');
    const chatSelectedHistory = _.get(state, 'chatSelected.history');

    if (chatSelectedId !== chatId || !chatSelectedHistory) return;

    const message = state.chatSelected.history.find(
      message => message.id === messageId
    );

    if (!message) return;

    message.status = status;
  },
  updateAttachment: (state, updatedAttachment) => {
    const { chat_id: chatId, attachment } = updatedAttachment;
    const { whatsapp_chat_message_id: messageId } = attachment;

    if (_.get(state, 'chatSelected.id') !== chatId) return;

    const message = state.chatSelected.history.find(
      message => message.id === messageId
    );

    if (!message) return;

    const alreadyHasAttachment = message.whatsapp_attachments.find(
      attach => attach.id == updatedAttachment.id
    );

    if (alreadyHasAttachment) return;

    message.whatsapp_attachments.push(attachment);
    message.has_attachments = true;
  },
  readWhatsappChat: state => {
    state.chatSelected.has_unread_messages = false;
  },
  loadChatMessagesStart: state => {
    state.chatSelectedLoading = true;
  },
  loadAllChatMessagesStart: state => {
    state.chatSelectedLoadingAll = true;
  },
  loadChatMessagesSuccess: (state, chatFull) => {
    state.chatSelected = { ...chatFull, has_unread_messages: false };

    if (state.chatSelectedLoading) {
      state.chatSelectedLoading = false;
    }

    if (state.chatSelectedLoadingAll) {
      state.chatSelectedLoadingAll = false;
    }
  },
  chatDeselect: state => {
    state.chatSelected = null;
  },
  setShortcuts: (state, shortcuts) => {
    state.shortcuts = shortcuts;
  },
  setAttendants(state, attendants) {
    state.attendants = attendants;
  },
  setedListeners(state) {
    state.setedListeners = true;
  },
};
