<template lang="pug">
  .order-tip(@click="$emit('click')")
    .order-tip__left
      .order-tip__left-top
        h4.order-tip__university {{ order.universityName }}
      .order-tip__left-top
        h4.order-tip__title {{ order.courseName }}
        h4.order-tip__discount {{ order.discount }}%
      .order-tip__left-bot
        Badge.order-tip__badge(:type="orderStatus.color") {{ orderStatus.title }}
        b.order-tip__origin-name {{ originDetails.name }}
        img.order-tip__origin-logo(:src="originDetails.logo")
</template>

<script>
import ClickableIcon from '@/components/common/ClickableIcon';
import Badge from '@/components/common/Badge';
import getOrderStatusFormatting from '@/assets/javascripts/data/orderStatus.js';
import { orderOriginDetails } from '@/assets/javascripts/data/orderOrigins/orderOrigins';
import { mapGetters } from 'vuex';

export default {
  components: {
    ClickableIcon,
    Badge,
  },
  filters: {
    orderOriginDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      originDetails: {},
    };
  },
  computed: {
    ...mapGetters('Order', ['orderOrigin']),
    orderStatus() {
      return getOrderStatusFormatting(this.order.lastStatus);
    },
  },
  mounted() {
    this.originDetails = orderOriginDetails(this.order.orderOrigin);
  },
};
</script>
