import * as WhatsappHost from '@/assets/javascripts/data/whatsapp/hosts';

const create = (whatsappHost, whatsappPhone) => {
  return whatsappHost + '|' + whatsappPhone;
};

const split = whatsappUsername => {
  const [host, phone] = whatsappUsername.split('|');

  if (!phone) {
    return [undefined, whatsappUsername];
  }

  return [host, phone];
};

const normalize = whatsappUsername => {
  let [host, phone] = split(whatsappUsername);
  const hostNormalized = WhatsappHost.normalize(host);

  return hostNormalized + '|' + phone;
};

const translate = whatsappUsername => {
  let [host, phone] = split(whatsappUsername);
  const hostTranslated = WhatsappHost.translate(host);

  return (
    hostTranslated +
    ' | ' +
    '(' +
    phone.slice(2, 4) +
    ') ' +
    phone.slice(4, -4) +
    '-' +
    phone.slice(-4)
  );
};

const getWhatsappHost = whatsappUsername => {
  let host = split(whatsappUsername)[0];
  const hostNormalized = WhatsappHost.normalize(host);

  return hostNormalized;
};

const getWhatsappPhone = whatsappUsername => split(whatsappUsername)[1];

export { create, normalize, translate, getWhatsappHost, getWhatsappPhone };
