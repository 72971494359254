import HttpService from '@/assets/javascripts/services/HttpService.js';

export default {
  fetch(customerId, orderId) {
    return HttpService.get('api/audit', { customerId, orderId });
  },

  request(orderId, customerId, auditComment, auditReason) {
    return HttpService.post('api/audit', {
      orderId,
      customerId,
      comment: auditComment,
      reasonKey: auditReason,
    });
  },
};
