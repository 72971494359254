import VoucherAPI from '@/api/voucherAPI';

export const state = () => ({
  vouchers: null,
});

export const mutations = {
  setVouchers(state, vouchers) {
    state.vouchers = vouchers;
  },
};

export const actions = {
  async loadVouchers({ commit }, baseUserId) {
    const { data } = await VoucherAPI.getVouchers(baseUserId);

    commit('setVouchers', data);
  },
};

export const getters = {
  getVouchers: state => state.vouchers,
};
