<template lang="pug">
  div
    .email-selector__body-label Informe os e-mails
    InputList.email-selector__input-list(v-model="formData.email")
      template(slot-scope="{ item, index }")
        input.email-selector__input(
          type="text"
          name="email"
          id="email"
          :data-index="index"
          v-model="item.value"
          placeholder="nome@email.com.br"
          autocomplete="off"
        )
      template(slot="bottom" slot-scope="{ item }")
        Alert.email-selector__body-alert(
          v-if="item.alert"
          :message="item.alert.message"
          :type="item.alert.type"
          :dismissable="false"
        )
</template>

<script>
import Button from '@/components/common/Button';
import InputList from '@/components/common/InputList';
import TabHeader from '@/components/common/TabHeader';
import Alert from '@/components/common/Alert';
import formLib, { areFieldsOk, cleanData } from '@/common_modules/formLib';

import { mapActions, mapGetters } from 'vuex';
import { trim } from 'lodash';

export default {
  components: {
    Button,
    InputList,
    TabHeader,
    Alert,
  },
  data() {
    return {
      formData: {
        email: [{ value: null }],
      },
    };
  },
  computed: {
    ...mapGetters('Order', ['userEmails']),
    value() {
      const fieldsToCheck = [
        {
          key: 'email',
          required: true,
        },
      ];
      const formCorrect = areFieldsOk(this.formData, fieldsToCheck);
      const { email: emails } = cleanData(this.formData);

      return {
        emails,
        hasErrors: !formCorrect,
      };
    },
  },
  watch: {
    value: {
      handler() {
        this.updateParent();
      },
      deep: true,
    },
  },
  mounted() {
    formLib(
      this.$el,
      () => this.formData,
      getFormEventsHandler(this.$set, this.validateEmail)
    );
    this.populateForm();
    this.updateParent();
  },
  methods: {
    ...mapActions('Order', ['validateEmail']),
    updateParent() {
      this.$emit('input', this.value);
    },
    populateForm() {
      this.formData.email = [...this.userEmails, null].map(value => ({
        value,
      }));
    },
  },
};

function getFormEventsHandler(set, validateEmail) {
  function resetData(data) {
    set(data, 'isOk', false);
    set(data, 'alert', undefined);
  }

  function setNewData(data, email) {
    set(data, 'value', email);
    set(data, 'isOk', false);
    set(data, 'alert', undefined);
  }

  function setOkData(data) {
    set(data, 'isOk', true);
  }

  return {
    onInput: {
      email: resetData,
    },
    onChange: {
      async email(data) {
        const email = trim(data.value);
        setNewData(data, email);

        if (!email) return;

        try {
          await validateEmail({ email });
        } catch (error) {
          set(data, 'alert', {
            message: 'Email inválido',
            type: 'error',
          });
          return;
        }

        setOkData(data);
      },
    },
  };
}
</script>

<style lang="sass">
.email-selector__body
  flex-grow: 1
  overflow: auto
  padding: $space-st

.email-selector__body-label
  padding: $space-s 0
  padding-top: $space-s

.email-selector__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.email-selector__input
  @extend %opa-input
  flex-grow: 1
  margin-bottom: $space-s

.email-selector__input-list
  margin-bottom: $space-m

.email-selector__input:disabled
  cursor: not-allowed
  opacity: 0.3

.email-selector__input::placeholder
  color: $chrome-gray

.email-selector__body-alert
  width: 100%
  margin-bottom: $space-s

.email-selector__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
