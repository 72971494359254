import HttpService from '@/assets/javascripts/services/HttpService.js';
import { map, get } from 'lodash';

export default {
  fetchOrders(userId) {
    return HttpService.get(`api/order/user/${userId}`);
  },
  fetchOrderDetails(orderId, tenantSlug) {
    const params = { tenant_slug: tenantSlug };

    return HttpService.get(`api/order/${orderId}`, params);
  },
  async initiateOrder(user_id, offer_id, pre_enrollment_fee_id, origin) {
    const { data } = await HttpService.post('api/order', {
      user_id,
      offer_id,
      pre_enrollment_fee_id,
      origin,
    });

    return data;
  },
  async sendBill(params) {
    const { data } = await HttpService.post(
      `/api/order/${params.orderId}/send_payment_options`,
      {
        methods: params.channels,
        payment_types: params.paymentMethods,
        emails: params.emails || [],
        phones: params.smss || [],
        customer_id: params.customerId,
        base_user_id: params.baseUserId,
      }
    );

    return data;
  },
  startDirectEnrollment({ orderId }) {
    return HttpService.get(`/api/order/${orderId}/start_direct_enrollment`);
  },
  activateCoupon({ orderId }) {
    return HttpService.get(`/api/order/${orderId}/activate_coupon`);
  },
  async sendPurchaseConfirmation(params) {
    const { data } = await HttpService.post(
      `/api/order/${params.orderId}/send_purchase_confirmation`,
      {
        to: params.email,
        customer_id: params.customerId,
      }
    );

    return data;
  },
  async generateBoleto({ orderId, customerId }) {
    const { data } = await HttpService.post(
      `/api/order/${orderId}/generate_boleto`,
      {
        customer_id: customerId,
      }
    );

    return data;
  },
  async expiresBoleto({ orderId, reason }) {
    const { data } = await HttpService.post(
      `api/order/${orderId}/expire_payment`,
      {
        reason: reason,
      }
    );

    return data;
  },
  async getRegisterOrderData({ offerId, baseUserId }) {
    const { data } = await HttpService.get('api/order/register_data', {
      offer_id: offerId,
      base_user_id: baseUserId,
    });

    return parseRegisterOrderData(data);
  },
  async registerOrder(params) {
    const { orderId } = params;
    const parsedParams = {
      name: params.name,
      cpf: params.cpf,
      birthday: params.birthday,
      email: params.email,
      phone: params.phone,
      postal_code: params.postalCode,
      state: params.state,
      city: params.city,
      address: params.address,
      address_number: params.addressNumber,
      address_complement: params.addressComplement,
      neighborhood: params.neighborhood,
      income_range: parseInt(get(params, 'incomeRange.key')) || null,
      how_you_found: get(params, 'howYouFound.key') || null,
      how_you_found_other: params.howYouFoundOther,
      discount_code: params.discountCode || null,
      enem_year: params.enemYear.key,
      document: params.document,
      document_expeditor: params.documentExpeditor,
      integration_fast_track: params.needsRG,
      enem_number: params.enemNumber,
      enem_natureza: params.enemNatureza,
      enem_humanas: params.enemHumanas,
      enem_linguagens: params.enemLinguagens,
      enem_matematica: params.enemMatematica,
      enem_redacao: params.enemRedacao,
      tenant_id: params.tenantId,
    };

    return HttpService.post(`/api/order/${orderId}/register`, parsedParams)
      .then(response => response.data)
      .catch(error => handleRegisterOrderError(error));
  },
  authorizeBoletoManually(filedata, observations, orderId, customerId) {
    return HttpService.post('/api/manual_authorization', {
      filedata,
      observations,
      order_id: orderId,
      customer_id: customerId,
    });
  },
  async sendRefundEmail({ emails, orderId }) {
    const { data } = await HttpService.post(
      `/api/order/${orderId}/refund_email`,
      {
        to: emails,
      }
    );

    return data;
  },
  updateTicket(params) {
    return HttpService.patch(`api/order/${params.id}`);
  },
};

function parseRegisterOrderData({ register_data: data }) {
  const incomeRange = map(data.income_ranges, parseIncomeRange);

  const howYouFound = map(data.how_you_found_options, (value, key) => ({
    name: value,
    key,
  }));

  const enemYear = map(data.enem_years, item => ({
    key: item,
    name: item.toString(),
  }));
  enemYear.push({
    key: 0,
    name: 'Não fez ENEM',
  });

  const filledData = {
    document: data.document,
    documentExpeditor: data.document_expeditor,
    enemHumanas: parseInt(data.enem_humanas),
    enemLinguagens: parseInt(data.enem_linguagens),
    enemMatematica: parseInt(data.enem_matematica),
    enemNatureza: parseInt(data.enem_natureza),
    enemNumber: data.enem_number,
    enemRedacao: parseInt(data.enem_redacao),
    enemYear: getSelectedOption(enemYear, data.enem_year),
    howYouFound: getSelectedOption(howYouFound, data.how_you_found),
    incomeRange: getSelectedOption(incomeRange, data.income_range),
  };

  return {
    options: { incomeRange, howYouFound, enemYear },
    filledData,
    needsRG: data.integration_fast_track,
  };
}

function translate(key) {
  return {
    name: 'nome completo',
    cpf: 'cpf',
    birthday: 'data de nascimento',
    email: 'e-mail',
    phone: 'celular',
    postal_code: 'CEP',
    state: 'estado',
    city: 'cidade',
    address: 'endereço',
    address_number: 'número',
    address_complement: 'complemento',
    document: 'RG',
    document_expeditor: 'orgão expedidor',
    neighborhood: 'bairro',
    income_range: 'renda',
    how_you_found: 'como conheceu',
    discount_code: 'código de desconto',
    enem_year: 'Ano do ENEM',
    how_you_found_other: 'Qual?',
    enem_number: 'número de inscrição ENEM',
    enem_natureza: 'nota ENEM natureza',
    enem_humanas: 'nota ENEM humanas',
    enem_linguagens: 'nota ENEM linguagens',
    enem_matematica: 'nota ENEM matemática',
    enem_redacao: 'nota ENEM redação',
  }[key];
}

function parseIncomeRange(value, key) {
  let name;
  if (!value.above) {
    name = 'Até R$ ' + value.below;
  } else if (!value.below) {
    name = 'Acima de R$ ' + value.above;
  } else {
    name = 'De R$ ' + value.above + ' até R$ ' + value.below;
  }
  return { name, key };
}

function getSelectedOption(options, key) {
  return options.find(value => value.key == key);
}

function handleRegisterOrderError({ response }) {
  let { data } = response;

  if (response.status == 422) {
    throw 'Não é possível registar uma ordem não existente';
  }

  if (get(data, 'message')) {
    throw Object.keys(data.message).map(
      key => `Erro no campo "${translate(key)}" : ${data.message[key][0]}`
    );
  } else {
    throw 'A solicitação falhou. Por favor, contate o suporte.';
  }
}
