<template lang="pug">
  div(ref="form")
    .register-user-body__group
      label.register-user-body__label(for="cpf") {{ 'CPF' | required(fullRequired) }}
      Cleave.register-user-body__input(
        type="text"
        name="cpf"
        id="cpf"
        v-model="formData.cpf.value"
        placeholder="Ex: 123.456.789-00"
        autocomplete="off"
        :options="{delimiters: ['.', '.', '-'], blocks: [3, 3, 3, 2], numericOnly: true}"
        :raw="false"
        :disabled="!canShowUpdateCpf"
      )
      Alert.register-user-body__alert(
        v-if="formData.cpf.alert"
        :message="formData.cpf.alert.message"
        :type="formData.cpf.alert.type"
        :dismissable="false"
      )
        button.register-user-body__alert-link(
          v-if="formData.cpf.alert.action"
          @click="formData.cpf.alert.action"
          type="button")
          span {{ formData.cpf.alert.actionMessage }}
    .register-user-body__group(v-if="isCreateType")
      label.register-user-body__label(for="emailLogin") {{ 'Email (login)*' }}
      input.register-user-body__input(
        type="text"
        name="emailLogin"
        id="emailLogin"
        v-model="formData.emailLogin.value"
        placeholder="Ex: jose.roberto@gmail.com"
        autocomplete="off"
      )
      Alert.register-user-body__alert(
        v-if="formData.emailLogin.alert"
        :message="formData.emailLogin.alert.message"
        :type="formData.emailLogin.alert.type"
        :dismissable="false"
      )
        button.register-user-body__alert-link(
          v-if="formData.emailLogin.alert.action"
          @click="formData.emailLogin.alert.action"
          type="button")
          span {{ formData.emailLogin.alert.actionMessage }}
    .register-user-body__group(v-if="!isCreateType")
      label.register-user-body__label(for="emails") {{ 'Emails' | required(fullRequired) }}
      component(:is="uniques? 'BlankInputList': 'InputList'" v-model="formData.emails")
        template(slot-scope="{ item, index }")
          input.register-user-body__input(
            type="text"
            name="emails"
            :data-index="index"
            id="emails"
            v-model="item.value"
            placeholder="Ex: jose.roberto@gmail.com"
            autocomplete="off"
          )
        template(slot="bottom" slot-scope="{ item }")
          Alert.register-user-body__alert(
            v-if="item.alert"
            :message="item.alert.message"
            :type="item.alert.type"
            :dismissable="false"
          )
            button.register-user-body__alert-link(
              v-if="item.alert.action"
              @click="item.alert.action"
              type="button")
              span {{ item.alert.actionMessage }}

    .register-user-body__group
      label.register-user-body__label(for="phones") {{ 'Celular' | required(fullRequired) }}
      component(:is="uniques? 'BlankInputList': 'InputList'" v-model="formData.phones")
        template(slot-scope="{ item, index }")
          Cleave.register-user-body__input(
            type="text"
            name="phones"
            id="phones"
            v-model="item.value"
            :data-index="index"
            placeholder="Ex: (61) 9823-9900"
            autocomplete="off"
            :options="phoneMaskOptions()"
            :raw="false"
          )
        template(slot="bottom" slot-scope="{ item }")
          Alert.register-user-body__alert(
            v-if="item.alert"
            :message="item.alert.message"
            :type="item.alert.type"
            :dismissable="false"
          )
            button.register-user-body__alert-link(
              v-if="item.alert.action"
              @click="item.alert.action"
              type="button"
            )
              span {{ item.alert.actionMessage }}

    .register-user-body__group
      label.register-user-body__label(for="name") Nome completo *
      input.register-user-body__input(
        type="text"
        name="name"
        id="name"
        v-model="formData.name.value"
        placeholder="Ex: José Roberto Rodrigues"
        autocomplete="off"
      )

      Alert.register-user-body__alert(
        v-if="formData.name.alert"
        :message="formData.name.alert.message"
        :type="formData.name.alert.type"
        :dismissable="false"
      )

    .register-user-body__group
      label.register-user-body__label(for="birthday") {{ 'Data de nascimento' | required(fullRequired) }}
      Cleave.register-user-body__input(
        type="text"
        name="birthday"
        id="birthday"
        v-model="formData.birthday.value"
        placeholder="Ex: 10/02/1990"
        autocomplete="off"
        :options="{date: true, datePattern: ['d', 'm', 'Y']}"
        :raw="false"
      )

    .register-user-body__group
      label.register-user-body__label(for="postalCode") {{ 'CEP' | required(fullRequired) }}
      Cleave.register-user-body__input(
        type="text"
        name="postalCode"
        id="postalCode"
        v-model="formData.postalCode.value"
        placeholder="Ex: 72930-200"
        autocomplete="off"
        :options="{delimiter: '-', blocks: [5, 3], numericOnly: true}"
      )
    .register-user-body__address
      Icon(svg-name="i-engine" svg-class="register-user-body__loading-icon" v-if="loadingAddress")
      .register-user-body__address-inputs(:class="{'register-user-body__address-inputs--loading': loadingAddress}")
        .register-user-body__group
          label.register-user-body__label(for="state") {{ 'Estado' | required(fullRequired) }}
          Select.register-user-body__select(
            placeholder="Selecione o estado"
            id="state"
            name="state"
            :list="states"
            label-property="name"
            v-model="selectedState"
            @input="stanteInputEvent"
          )
        .register-user-body__group
          label.register-user-body__label(for="city") {{ 'Cidade' | required(fullRequired) }}
          input.register-user-body__input(
            type="text"
            name="city"
            id="city"
            v-model="formData.city.value"
            placeholder="Ex: São José dos Campos"
            autocomplete="off"
          )

        .register-user-body__group
          label.register-user-body__label(for="neighborhood") {{ 'Bairro' | required(fullRequired) }}
          input.register-user-body__input(
            type="text"
            name="neighborhood"
            id="neighborhood"
            v-model="formData.neighborhood.value"
            placeholder="Ex: Jardim Apollo"
            autocomplete="off"
          )

        .register-user-body__group
          label.register-user-body__label(for="address") {{ 'Endereço' | required(fullRequired) }}
          input.register-user-body__input(
            type="text"
            name="address"
            id="address"
            v-model="formData.address.value"
            placeholder="Ex: Avenida São João"
            autocomplete="off"
          )

    .register-user-body__group
      label.register-user-body__label(for="addressNumber") {{ 'Número' | required(fullRequired) }}
      Cleave.register-user-body__input(
        type="text"
        name="addressNumber"
        id="addressNumber"
        v-model="formData.addressNumber.value"
        placeholder="Ex: 1701"
        autocomplete="off"
        :options="{numericOnly: true, blocks: [10]}"
      )

    .register-user-body__group
      label.register-user-body__label(for="addressComplement") Complemento
      input.register-user-body__input(
        type="text"
        name="addressComplement"
        id="addressComplement"
        v-model="formData.addressComplement.value"
        placeholder="Ex: Apto 44"
        autocomplete="off"
      )
</template>

<script>
import Icon from '@/components/common/Icon';
import ClickableIcon from '@/components/common/ClickableIcon';
import Alert from '@/components/common/Alert';
import InputList from '@/components/common/InputList';
import BlankInputList from '@/components/Profile/RegisterUserBody/BlankInputList';
import { stateCodeMap } from '@/assets/javascripts/data/stateCode';
import Select from '@/components/common/Select';

import formLib, {
  areFieldsOk,
  phoneMaskOptions,
} from '@/common_modules/formLib';
import getEventHandlers from '@/components/Profile/RegisterUserBody/eventHandlers';
import { isEmpty, without } from 'lodash';

import { mapActions, mapState, mapGetters } from 'vuex';
import Cleave from 'vue-cleave-component';

const states = Object.keys(stateCodeMap).map(key => {
  return { value: key, name: stateCodeMap[key] };
});

export default {
  components: {
    Icon,
    Cleave,
    Alert,
    ClickableIcon,
    BlankInputList,
    InputList,
    Select,
  },
  filters: {
    required: (value, apply) => value + (apply ? ' *' : ''),
  },
  props: {
    fullRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    uniques: {
      type: Boolean,
      required: false,
      default: false,
    },
    registerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      loadingAddress: false,
      selectedState: null,
      formDataKeys: [
        'cpf',
        'emails',
        'emailLogin',
        'phones',
        'name',
        'birthday',
        'postalCode',
        'state',
        'city',
        'neighborhood',
        'address',
        'addressNumber',
        'addressComplement',
      ],
      emptyProfile: {
        cpf: null,
        emails: [],
        emailLogin: null,
        phones: [],
        name: null,
        birthday: null,
        postalCode: null,
        state: null,
        city: null,
        neighborhood: null,
        address: null,
        addressNumber: null,
        addressComplement: null,
      },
      states,
    };
  },
  computed: {
    ...mapState('Profile', { storeProfile: 'profile' }),
    ...mapGetters('Permissions', ['canUpdateCpf']),
    profile() {
      return this.registerType == 'create'
        ? this.emptyProfile
        : this.storeProfile;
    },
    hasErrors() {
      if (isEmpty(this.formData)) return;

      const fieldsToCheck = [
        { key: 'emailLogin', required: this.isCreateType },
      ];

      if (this.fullRequired) {
        const fields = without(
          this.formDataKeys,
          'addressComplement',
          'emailLogin'
        );

        fieldsToCheck.push(...fields);
      } else {
        const fields = [
          { key: 'cpf', required: false },
          { key: 'name', required: true },
          { key: 'phones', required: false },
          { key: 'emails', required: !this.isCreateType },
        ];

        fieldsToCheck.push(...fields);
      }

      return !areFieldsOk(this.formData, fieldsToCheck);
    },
    cpfRegistered() {
      return !!this.profile.cpf;
    },
    hasDuplicatedEmail() {
      return this.formData.emails.findIndex(email => email.duplicated) != -1;
    },
    hasDuplicatedCPF() {
      return this.formData.cpf.duplicated;
    },
    isCreateType() {
      return this.registerType === 'create';
    },
    canShowUpdateCpf() {
      if (!this.cpfRegistered) {
        return true;
      }
      return this.canUpdateCpf;
    },
  },

  watch: {
    profile() {
      this.initializeForm();
    },
    formData: {
      handler() {
        this.updateParent();
      },
      deep: true,
    },
    hasErrors() {
      this.updateParent();
    },
    'formData.state.value'(formState) {
      this.selectedState = this.states.find(state => state.value === formState);
    },
  },

  created() {
    this.initializeForm();
  },

  mounted() {
    formLib(this.$refs.form, () => this.formData, getEventHandlers(this));
    this.$refs.form.querySelector('input:not([disabled])').focus();
  },

  methods: {
    ...mapActions('Profile', ['getAddress', 'searchUser', 'validateEmail']),
    ...mapActions('RightPanel', ['tabChange']),

    phoneMaskOptions: phoneMaskOptions,

    stanteInputEvent(state) {
      this.formData.state.value = state.value;
    },

    initializeForm() {
      const populatedFormData = this.formDataKeys.reduce((acc, key) => {
        let value = this.profile[key];
        const isArray = Array.isArray(value);
        const forgeObject = val => ({ value: val });

        if (isArray) {
          value = value.map(forgeObject);
          if (value.length === 0) {
            value.push(forgeObject(undefined));
          }
        } else {
          value = forgeObject(value);
        }

        acc[key] = value;
        return acc;
      }, {});

      if (populatedFormData.name.value === 'Anônimo') {
        populatedFormData.name.value = null;
      }

      this.$set(this, 'formData', populatedFormData);
    },

    updateParent() {
      this.$emit('input', {
        formData: this.formData,
        hasErrors: this.hasErrors,
        hasDuplicatedEmail: this.hasDuplicatedEmail,
        hasDuplicatedCPF: this.hasDuplicatedCPF,
      });
    },
  },
};
</script>
