const WhatsappFormatter = {
  whatsToHtml(msg) {
    if (!msg) return;

    return msg
      .replace(/~(~*[^~\n]+~*)~/g, '<del>$1</del>')
      .replace(/_(_*[^_\n]+_*)_/g, '<em>$1</em>')
      .replace(/\*(\**[^*\n]+\**)\*/g, '<strong>$1</strong>');
  },

  htmlToWhats(msg) {
    if (!msg) return;

    return msg
      .replace(/\n/g, '')
      .replace(/<\/?ul>/g, '')
      .replace(/<li>/g, '\n-')
      .replace(/&bull;/g, '')
      .replace(/<\/li>/g, '')
      .replace(/<strong>([^<>]*)<\/strong>/g, '*$1*')
      .replace(/<del>([^<>]*)<\/del>/g, '~$1~')
      .replace(/<em>([^<>]*)<\/em>/g, '_$1_')
      .replace(/<a href=['"]([^\s'"]*)['"][^<>]*>[^<>]*<\/a>/g, '$1')
      .replace(/<\/?[^<>]+>/g, ''); // other tags
  },
};

export default WhatsappFormatter;
