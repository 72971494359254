import axios from 'axios';
import {
  cachedAutocompleteAddress,
  computeDistance,
} from '@/assets/javascripts/modules/gMaps.js';
import SemesterFormatter from '@/assets/javascripts/formatters/SemesterFormatter';
import Amplitude from '@/common_modules/tools/Amplitude';

import { isEmpty, flatMap, orderBy } from 'lodash';
import { map, flow, flatten, values } from 'lodash/fp';
import moment from 'moment';
import { convertToCamelCase } from '@/assets/javascripts/modules/utils';

const semesterOptions = [
  ...getSemesters(),
  {
    title: 'Início imediato',
    data: 'Início imediato',
    id: 3,
  },
];

export default {
  async search({
    courses,
    location = {},
    shifts,
    levels,
    durationRanges,
    kinds,
    universities,
    price,
    distance,
    orderBy,
    semesters,
    benefits,
    uuid,
    cacheData,
    baseUserId,
    tenantCode,
  }) {
    const params = {
      selected_courses: [courses.join(' ')],
      selected_universities: universities,
      selected_shift: shifts,
      selected_kind: kinds,
      selected_level: levels,
      selected_benefits: benefits || [],
      selected_duration_ranges: durationRanges,
      location_search: !isEmpty(location),
      position_lat: location.lat || 0,
      position_lng: location.lng || 0,
      distance: distance || 0,
      price: price || 10000,
      current_page: 0,
      page_size: 45,
      order_type: orderBy || 'relevance',
      semester: semesters[0] || 'all',
      city_search: false,
      selected_city: {},
      uuid: uuid || null,
      cache_data: cacheData,
      base_user_id: baseUserId,
      tenant_code: tenantCode,
    };

    Amplitude.logEvent('searched_course', parseForAmplitude(params));

    const result = await axios.post('/search/offers', params);

    const resultCourse = result.data.courses.map(course => {
      return putDistanceFromSearchLocationOnCourse(course, location);
    });
    return resultCourse;
  },
  async searchFor(what, search_string) {
    // what = 'courses' || 'universities' || 'places'
    // payload = [{ title, data, id }]
    if (what === 'places') {
      const result = await cachedAutocompleteAddress(
        search_string || 'São José dos Campos'
      );

      return result.map(x => ({ title: x.description, id: x.id, data: x }));
    }

    const requestParamsKeyMap = {
      courses: 'course_string',
      universities: 'university_string',
    };
    const requestParams = { [requestParamsKeyMap[what]]: search_string };

    const result = await axios.post('/search/' + what, requestParams);

    return flow(
      values,
      flatten,
      map(({ name, id }) => {
        const dataMap = { universities: id, courses: name };

        return { title: name, id, data: dataMap[what] };
      })
    )(result.data);
  },
  async getFilterOptions() {
    // payload = {
    //   shifts:    [{ title, data, id }],
    //   levels:    [{ title, data, id }],
    //   kinds:     [{ title, data, id }],
    //   semesters: [{ title, data, id }],
    // }

    const [
      shiftsRaw,
      levelsRaw,
      kindsRaw,
      durationRangesRaw,
      benefitsRaw,
    ] = await Promise.all([
      axios.get('/search/options/shift'),
      axios.get('/search/options/level'),
      axios.get('/search/options/kind'),
      axios.get('/search/options/duration-range'),
      axios.get('/search/options/benefit'),
    ]);

    return {
      shifts: normalizeFilterOptions(shiftsRaw),
      levels: normalizeLevelFilterOptions(levelsRaw.data),
      kinds: normalizeFilterOptions(kindsRaw),
      durationRanges: normalizeFilterOptions(durationRangesRaw),
      benefits: normalizeFilterOptions(benefitsRaw),
      semesters: semesterOptions,
    };
  },
  async getOrderByOptions() {
    // payload = [
    //  { key: 'relevance', name: 'Relevância' },
    //  { key: 'price_asc', name: 'Menor Preço' },
    //  { key: 'distance', name: 'Distância' },
    //]
    const result = await axios.get('/search/options/order-by');
    return result.data;
  },
  async fetchOfferSearchCache(base_user_id) {
    const { data } = await axios.get('/search/offer_search_cache', {
      params: {
        base_user_id: base_user_id,
      },
    });

    return convertToCamelCase(data);
  },
};

function getSemesters() {
  const currentMonth = moment().month();
  const semesters = [1, 2].map(i => {
    const item = SemesterFormatter.getFormattedSemester(
      moment().year(),
      currentMonth,
      i
    );
    return {
      title: item,
      data: item,
      id: i,
    };
  });

  return orderBy(
    semesters,
    ['id'],
    SemesterFormatter.getCurrentSemester(currentMonth) === '1'
      ? ['asc']
      : ['desc']
  );
}

function normalizeFilterOptions(result) {
  return result.data.map(({ title, data, id }) => ({
    title,
    data: flatMap(data, x => x.option),
    id,
  }));
}

function normalizeLevelFilterOptions(arr, acc = []) {
  const parseLevelOption = str => str.replace(' (graduação)', '');
  const [head, ...tail] = arr;

  const isParent = !!head.data;

  let item;
  if (isParent) {
    item = {
      title: 'Todos ' + head.title,
      id: acc.length,
      data: head.data.map(x => x.option),
    };
  } else {
    item = {
      title: parseLevelOption(head.option),
      id: acc.length,
      data: [head.option],
    };
  }

  const newAcc = [...acc, item];

  const nextArr = [...(head.data || []), ...(tail || [])];

  if (!isEmpty(nextArr)) {
    return normalizeLevelFilterOptions(nextArr, newAcc);
  }

  return newAcc;
}

function parseForAmplitude(params) {
  let { selected_courses, selected_universities } = params;
  selected_courses = selected_courses.map(x => x.name);
  selected_universities = selected_universities.map(x => x.name);
  return { ...params, selected_universities, selected_courses };
}

function putDistanceFromSearchLocationOnCourse(course, searchLocation) {
  let resultDistance = computeDistance(searchLocation, course.geoloc);

  if (resultDistance == null) {
    resultDistance = 'N/D';
  } else {
    resultDistance =
      Number.parseFloat(resultDistance / 1000).toFixed(1) + ' km';
  }

  course.distance = resultDistance;
  return course;
}
