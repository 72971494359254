export default value => {
  if (value == null) return;
  if (typeof value === 'string') value = Number(value);

  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });
};
