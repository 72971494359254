export const offerSearchBenefits = [
  {
    name: 'Parcelamento',
    svgName: 'i-parcelamento',
    isBenefit: true,
  },
  {
    name: 'Proteção Quero',
    svgName: 'i-protecao-quero',
    isBenefit: true,
  },
  {
    name: 'Matrícula Digital',
    svgName: 'i-matricula-digital',
    isBenefit: true,
  },
  {
    name: 'Matrícula Direta',
    svgName: 'i-direct-admission',
    isBenefit: false,
    attribute: 'free_pre_enrollment',
    expectedValue: true,
  },
  {
    name: 'Nota Quero',
    svgName: 'i-nota-quro',
    isBenefit: true,
  },
  {
    name: 'Matrícula Antecipada',
    svgName: 'i-matricula-antecipada',
    isBenefit: true,
  },
  {
    name: 'QB Turbo',
    svgName: 'i-qb-turbo',
    isBenefit: false,
    attribute: 'qb_turbo',
    expectedValue: true,
  },
  {
    name: 'IES canibalizando',
    svgName: 'i-pacman',
    isBenefit: false,
    attribute: 'show_on_main_search',
    expectedValue: false,
  },
  {
    name: 'Polos Quero',
    svgName: 'i-polos-quero',
    isBenefit: false,
    attribute: 'campus_is_multi_brand',
    expectedValue: true,
  },
];

export const courseOfferBenefits = [
  {
    name: 'Quero Pago',
    svgName: 'i-queropago',
    attibute: 'billingIntegration',
  },
  {
    name: 'Admissão Digital',
    svgName: 'i-digital-admission',
    attibute: 'digitalAdmission',
  },
  {
    name: 'Matrícula Direta',
    svgName: 'i-direct-admission',
    attibute: 'freePreEnrollment',
  },
  {
    name: 'Parcelamento',
    svgName: 'i-parcelamento',
    attibute: 'hasParcelamento',
  },
  {
    name: 'Polos Quero',
    svgName: 'i-polos-quero',
    attibute: 'campusIsMultiBrand',
  },
];
