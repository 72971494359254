import introspectionQueryResultData from '@/plugins/apollo/querobolsa/fragmentTypes.json';

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default new InMemoryCache({ fragmentMatcher });
